import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './profileForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ShiftPreferenceDay } from 'site/app/models/shiftPreferenceDay.model';
import { NotificationTask } from 'site/app/models/notificationTask.model';
import { NotificationAllocationRequestType } from 'site/app/models/notificationAllocationRequestType.model';
import { ExcludedAgendaAllocationRequestType } from 'site/app/models/excludedAgendaAllocationRequestType.model';
import { ExcludedAgendaTaskType } from 'site/app/models/excludedAgendaTaskType.model';
import { NotificationTaskType } from 'site/app/models/notificationTaskType.model';
import { NotificationTaskVariant } from 'site/app/models/notificationTaskVariant.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ProfileFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public schedulePeriodId;
	public originalExtendedUser: any;

	public extendedUser: any;
	public shiftPreferenceDayIndex: any;
	public nonShiftPreferenceDayIndex: any;
	public notificationTaskIndex: any;
	public notificationTaskTypeIndex: any;
	public notificationTaskVariantIndex: any;
	public notificationAllocationRequestTypeIndex: any;
	public submitted: boolean;
	public daysOfWeek: { name: string; }[];
	public tasks: any;
	public taskTypes: any;
	public isLoading: boolean;
	public allocationRequestTypesForNotificationSelection: any;
	public allocationRequestTypesForExcludedAgenda: any;
	public excludedAgendaAllocationRequestTypeIndex: any;
	public taskTypesForExcludedAgenda: any;
	public excludedAgendaTaskTypeIndex: any;
	public taskVariantGroups: any;
	public taskVariantGroupsForTaskVariants: any;
	public candidateTypes: any;
	public taskDisplayGroups: any;
	public allTaskDisplayGroupsAllowedForAdmin: any;
	public taskTypesForAllowed: any;
	public allTaskTypesAllowedForAdmin: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}
	
	deleteShiftPreferenceDay(index) {
		var matchIndex = -1;
		this.extendedUser.shift_preference_days.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.extendedUser.shift_preference_days.splice(matchIndex, 1);;
	}
	
	addShiftPreferenceDay() {
		this.extendedUser.shift_preference_days.push(new ShiftPreferenceDay({ index: this.shiftPreferenceDayIndex++, day_of_week: null }));
	}
	
	deleteNonShiftPreferenceDay(index) {
		var matchIndex = -1;
		this.extendedUser.non_shift_preference_days.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.extendedUser.non_shift_preference_days.splice(matchIndex, 1);;
	}
	
	addNonShiftPreferenceDay() {
		this.extendedUser.non_shift_preference_days.push(new ShiftPreferenceDay({ index: this.nonShiftPreferenceDayIndex++, day_of_week: null }));
	}

	deleteNotificationTask(index) {
		var matchIndex = -1;
		this.extendedUser.notification_tasks_for_schedule_period.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.extendedUser.notification_tasks_for_schedule_period.splice(matchIndex, 1);;
	}
	
	addNotificationTask() {
		this.extendedUser.notification_tasks_for_schedule_period.push(new NotificationTask({ index: this.notificationTaskIndex++ }));
	}

	deleteAllNotificationTasks() {
		this.extendedUser.notification_tasks_for_schedule_period = [];
	}

	deleteNotificationTaskType(index) {
		var matchIndex = -1;
		this.extendedUser.notification_task_types.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});

		this.extendedUser.notification_task_types.splice(matchIndex, 1);;
	}
	
	addNotificationTaskType() {
		this.extendedUser.notification_task_types.push(new NotificationTaskType({ index: this.notificationTaskTypeIndex++ }));
	}

	deleteAllNotificationTaskTypes() {
		this.extendedUser.notification_task_types = [];
	}

	deleteNotificationTaskVariant(index) {
		var matchIndex = -1;
		this.extendedUser.notification_task_variants.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});

		this.extendedUser.notification_task_variants.splice(matchIndex, 1);;
		this.taskVariantGroupsForTaskVariants.splice(index, 1);
	}
	
	addNotificationTaskVariant() {
		this.extendedUser.notification_task_variants.push(new NotificationTaskVariant({ index: this.notificationTaskVariantIndex++ }));
		this.taskVariantGroupsForTaskVariants.push(null);
	}

	deleteAllNotificationTaskVariants() {
		this.extendedUser.notification_task_variants = [];
		this.taskVariantGroupsForTaskVariants = []
	}

	setNotifyOfSelectionOfTaskChanges() {
		if (!this.extendedUser.is_admin) {
			this.extendedUser.notify_of_selection_of_task_changes = true;
		}

		this.deleteAllNotificationTasks();
		this.deleteAllNotificationTaskTypes();
		this.deleteAllNotificationTaskVariants();
	}

	deleteNotificationAllocationRequestType(index) {
		var matchIndex = -1;
		this.extendedUser.notification_allocation_request_types_for_schedule_period.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.extendedUser.notification_allocation_request_types_for_schedule_period.splice(matchIndex, 1);;
	}
	
	addNotificationAllocationRequestType() {
		this.extendedUser.notification_allocation_request_types_for_schedule_period.push(new NotificationAllocationRequestType({ index: this.notificationAllocationRequestTypeIndex++ }));
	}

	deleteAllAllocationRequestTypes() {
		this.extendedUser.notification_allocation_request_types_for_schedule_period = [];
	}

	setNotifyOfSelectionOfAllocationRequestChanges() {
		if (!this.extendedUser.is_admin) {
			this.extendedUser.notify_of_selection_of_allocation_request_changes = true;
		}

		this.deleteAllAllocationRequestTypes();
	}

	deleteExcludedAgendaTaskType(index) {
		var matchIndex = -1;
		this.extendedUser.excluded_agenda_task_types.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.extendedUser.excluded_agenda_task_types.splice(matchIndex, 1);;
	}
	
	addExcludedAgendaTaskType() {
		this.extendedUser.excluded_agenda_task_types.push(new ExcludedAgendaTaskType({ index: this.excludedAgendaTaskTypeIndex++ }));
	}

	deleteExcludedAgendaAllocationRequestType(index) {
		console.log(index);
		var matchIndex = -1;
		this.extendedUser.excluded_agenda_allocation_request_types_for_schedule_period.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.extendedUser.excluded_agenda_allocation_request_types_for_schedule_period.splice(matchIndex, 1);;
	}
	
	addExcludedAgendaAllocationRequestType() {
		this.extendedUser.excluded_agenda_allocation_request_types_for_schedule_period.push(new ExcludedAgendaAllocationRequestType({ index: this.excludedAgendaAllocationRequestTypeIndex++ }));
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			this.http.post("other_users/update_profile",
				{
					user: this.extendedUser, 
					schedule_period_id: this.schedulePeriodId,
					candidate_types: this.candidateTypes,
					task_display_groups: this.taskDisplayGroups,
					task_types: this.taskTypesForAllowed
				}
			).subscribe(() => {
				this.action.emit(true);
				this.bsModalRef.hide();
			});
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
	}

	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		var self = this;

		this.extendedUser = JSON.parse(JSON.stringify(this.originalExtendedUser));

		this.extendedUser.shift_preference_days = [];
		this.extendedUser.non_shift_preference_days = [];
		this.shiftPreferenceDayIndex = 0;
		this.nonShiftPreferenceDayIndex = 0;
	
		this.daysOfWeek = [
			{ name: "Maandag" },
			{ name: "Dinsdag" },
			{ name: "Woensdag" },
			{ name: "Donderdag" },
			{ name: "Vrijdag" }
		];
	
		this.extendedUser.notification_tasks_for_schedule_period.forEach(function(notificationTask, index) {
			notificationTask.index = index;
		});

		this.extendedUser.notification_task_types.forEach(function(notificationTaskType, index) {
			notificationTaskType.index = index;
		});

		this.extendedUser.notification_task_variants.forEach(function(notificationTaskVariant, index) {
			notificationTaskVariant.index = index;
		});

		this.extendedUser.notification_allocation_request_types_for_schedule_period.forEach(function(notificationAllocationRequestType, index) {
			notificationAllocationRequestType.index = index;
		});

		this.extendedUser.excluded_agenda_task_types.forEach(function(excludedAgendaTaskType, index) {
			excludedAgendaTaskType.index = index;
		});

		this.extendedUser.excluded_agenda_allocation_request_types_for_schedule_period.forEach(function(excludedAgendaAllocationRequestType, index) {
			excludedAgendaAllocationRequestType.index = index;
		});
			
		this.notificationTaskIndex = this.extendedUser.notification_tasks_for_schedule_period.length;
		this.notificationTaskTypeIndex = this.extendedUser.notification_task_types.length;
		this.notificationTaskVariantIndex = this.extendedUser.notification_task_variants.length;
		this.notificationAllocationRequestTypeIndex = this.extendedUser.notification_allocation_request_types_for_schedule_period.length;
		this.excludedAgendaTaskTypeIndex = this.extendedUser.excluded_agenda_task_types.length;
		this.excludedAgendaAllocationRequestTypeIndex = this.extendedUser.excluded_agenda_allocation_request_types_for_schedule_period.length;
		
		this.http.get<any>("tasks", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(tasks => {
			this.tasks = tasks;
		
			tasks.forEach(function(task) {
				task.nameWithEquivalencePosition = task.name + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
			});
	
			this.isLoading = false;
		});

		this.http.get("allocation_request_types",
			{ 
				params: {
					schedule_period_id: this.schedulePeriodId,
					for_notification_selection: 'true'
				}
			}
		).subscribe(x => {
			this.allocationRequestTypesForNotificationSelection = x;
		});

		this.http.get("task_types",
			{ 
				params: {
					schedule_period_id: this.schedulePeriodId
				}
			}
		).subscribe(x => {
			this.taskTypesForExcludedAgenda = x;
			this.taskTypes = x;
		});

		this.http.get("task_variant_groups").subscribe(x => {
			this.taskVariantGroups = x;

			this.taskVariantGroupsForTaskVariants = this.extendedUser.notification_task_variants.map(function(y) {
				return self.taskVariantGroups.filter(function(z) { return z.id == y.task_variant.task_variant_group_id })[0];
			});
		});

		this.http.get("allocation_request_types",
			{ 
				params: {
					schedule_period_id: this.schedulePeriodId
				}
			}
		).subscribe(x => {
			this.allocationRequestTypesForExcludedAgenda = x;
		});

		this.http.get("candidate_types",
			{ 
				params: {
					for_user_id: this.extendedUser.id	
				}
			}
		).subscribe(x => {
			this.candidateTypes = x;
		});

		this.http.get("task_display_groups/index_for_user_for_schedule_period", 
			{ 
				params: {
					user_id: this.extendedUser.id,
					schedule_period_id: this.schedulePeriodId	
				}
			}
		).subscribe(x => {
			this.taskDisplayGroups = x;

			this.allTaskDisplayGroupsAllowedForAdmin = this.taskDisplayGroups.filter(function(x) { return !x.is_allowed_for_admin; }).length == 0;
		});

		this.http.get("task_types/index_for_user", 
			{ 
				params: {
					user_id: this.extendedUser.id
				}
			}
		).subscribe(x => {
			this.taskTypesForAllowed = x;

			this.allTaskTypesAllowedForAdmin = this.taskTypesForAllowed.filter(function(x) { return !x.is_allowed_for_admin; }).length == 0;
		});
    }
}
