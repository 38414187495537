export class Candidate {
	public id: number;
	public firstname: string;
	public infix: string;
	public lastname: string;
	public prefix: string;
	public periodic_candidates: any[];
	public task_accumulation_targets;
	public training_period_accumulation_type_targets;
	public training_period_accumulation_type_balances;
	public candidate_unavailability_periods;
	public regional_candidate_id;
	public user_task_display_group_memberships_for_schedule_period;

	constructor(obj) {
		this.id = obj.id;
		this.firstname = obj.firstname;
		this.infix = obj.infix;
		this.lastname = obj.lastname;
		this.prefix = obj.prefix;
		this.periodic_candidates = obj.periodic_candidates;
		this.task_accumulation_targets = obj.task_accumulation_targets;
		this.training_period_accumulation_type_targets = obj.training_period_accumulation_type_targets;
		this.training_period_accumulation_type_balances = obj.training_period_accumulation_type_balances;
		this.candidate_unavailability_periods = obj.candidate_unavailability_periods;
		this.regional_candidate_id = obj.regional_candidate_id;
		this.user_task_display_group_memberships_for_schedule_period = obj.user_task_display_group_memberships_for_schedule_period;
	}
  
  fullName() {
  	return (this.firstname != null ? this.firstname + ' ' : '') + (this.infix != null && this.infix != '' ? this.infix + ' ' : '') + this.lastname;
  };

  lastnameWithInfix() {
    return this.lastname +
			((this.prefix != null && this.prefix != '') || (this.infix != null && this.infix != '') ? ',' : '') +
			(this.prefix != null && this.prefix != '' ? ' ' + this.prefix : '') +
			(this.infix != null && this.infix != '' ? ' ' + this.infix : '');
  };
	
  infixWithLastname() {
		return (this.prefix != null && this.prefix != '' ? this.prefix + ' ' : '') + (this.infix != null && this.infix != '' ? this.infix + ' ' : '') + this.lastname;
  };

  firstnameWithInitial() {
		return (this.firstname != null && this.firstname != '' ? this.firstname + ' ' : '') + (this.lastname != null && this.lastname != '' ? this.lastname.substring(0, 1) : '')
  }
}