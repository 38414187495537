import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './customerForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'site/app/data.service';
import { Customer } from 'site/app/models/customer.model';
import * as moment from 'moment';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class CustomerFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public customerId: any;
	public newId: any;

	public customer: any;
	public submitted: boolean;	
	public actionTitle: string;
	public startDate: any;
	public datePickerConfig: any;
	public customerTypes: any;
	public invoiceFrequencies: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private toastr: ToastrService,
		private dataService: DataService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.startDate) {
				var startDate = moment(this.startDate);
				startDate.locale('en');
				this.customer.start_date = startDate.format("ddd D MMM YYYY");
			} else {
				this.customer.start_date = null;
			}

			if (this.customerId === null) {
				this.http.post("customers", this.customer).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			} else {
				this.http.post("customers/update_from_shard",
					{
						id: this.customer.id,
						customer: this.customer,
					}
				).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}

    ngOnInit() {
		if (this.customerId === null) {
			this.actionTitle = "toevoegen";
			this.customer = new Customer({ id: this.newId });
		} else {
			this.actionTitle = "wijzigen";
			this.http.get("customers/show_from_shard", { params: { id: this.customerId } }).subscribe(x => {
				this.customer = x;

				if (this.customer.start_date != null) {
					this.startDate = moment(this.customer.start_date);
				}
			});
		}

		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};

		this.customerTypes = [
			{ id: 1, title: "1. Met opleidingsrooster" }, 
			{ id: 2, title: "2. Regionale planning" },
			{ id: 3, title: "3. Regulier" },
			{ id: 4, title: "4. Met individueel gedeelte" }
		];

		this.invoiceFrequencies = [
			{ id: 1, title: "Maandelijks" }, 
			{ id: 2, title: "Jaarlijks" },
		];
    }
}
