import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './taskDisplayGroupForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TaskDisplayGroup } from 'site/app/models/taskDisplayGroup.model';
import { Task } from 'site/app/models/task.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskDisplayGroupFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public taskDisplayGroupId: any;
	public schedulePeriodId: any;

	public taskDisplayGroup: any;
	public submitted: boolean;	
	public actionTitle: string;
	public tasks: any;
	public isLoading: boolean;
	public candidateTypes: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteTaskId(index) {
		this.taskDisplayGroup.task_ids.splice(index, 1);;
	}
	
	addTaskId() {
		this.taskDisplayGroup.task_ids.push(null);
	}

	deleteCandidateTypeId(index) {
		this.taskDisplayGroup.candidate_type_ids.splice(index, 1);;
	}
	
	addCandidateTypeId() {
		this.taskDisplayGroup.candidate_type_ids.push(null);
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.taskDisplayGroupId === null) {
				this.taskDisplayGroup.schedule_period_id = this.schedulePeriodId;

				this.http.post("task_display_groups", this.taskDisplayGroup).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			} else {
				this.http.put("task_display_groups/" + this.taskDisplayGroup.id, this.taskDisplayGroup).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}
	
	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		if (this.taskDisplayGroupId === null) {
			this.actionTitle = "toevoegen";
			this.taskDisplayGroup = new TaskDisplayGroup();
			this.taskDisplayGroup.task_ids = [];
			this.taskDisplayGroup.candidate_type_ids = [];
		} else {
			this.actionTitle = "wijzigen";
			this.http.get("task_display_groups/" + this.taskDisplayGroupId).subscribe(x => {
				this.taskDisplayGroup = x;
				this.taskDisplayGroup.task_ids = this.taskDisplayGroup.tasks.map(function(y) { return y.id; });

				this.taskDisplayGroup.candidate_type_ids = this.taskDisplayGroup.candidate_types.map(function(y) { return y.id; });
			});
		}
		
		this.http.get<any>("tasks", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(tasks => {
			this.tasks = tasks;
	
			tasks.forEach(function(task) {
				task.computedNameWithEquivalencePosition = (new Task(task)).getComputedName() + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
			});
	
			this.isLoading = false;
		});

		this.http.get("candidate_types").subscribe(candidateTypes => {
			this.candidateTypes = candidateTypes;
		});
    }
}
