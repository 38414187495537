import { Component, OnInit } from '@angular/core';
import templateString from './taskAccumulationTypes.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TaskAccumulationTypeFormComponent } from '../taskAccumulationTypeForm';
import { TaskAccumulationTypeChangeOrderComponent } from '../taskAccumulationTypeChangeOrder';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskAccumulationTypesComponent implements OnInit {
    
    public taskAccumulationTypes: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService
	) { }

    refresh() {
        this.http.get("task_accumulation_types").subscribe(x => {
            this.taskAccumulationTypes = x;
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(TaskAccumulationTypeFormComponent, {
            initialState: {
                taskAccumulationTypeId: null
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(taskAccumulationTypeId) {
        var bsModalRef = this.bsModalService.show(TaskAccumulationTypeFormComponent, {
            initialState: {
                taskAccumulationTypeId: taskAccumulationTypeId
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(taskAccumulationTypeId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("task_accumulation_types/" + taskAccumulationTypeId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(TaskAccumulationTypeChangeOrderComponent, {
            initialState: {
                originalTaskAccumulationTypes: this.taskAccumulationTypes
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
		this.refresh();
	}
	
    ngOnInit() {
        this.initialize();
    }
}
