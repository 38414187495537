import { Component, OnInit } from '@angular/core';
import templateString from './allocationRequestAccumulationTypes.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AllocationRequestAccumulationTypeFormComponent } from '../allocationRequestAccumulationTypeForm';
import { AllocationRequestAccumulationTypeChangeOrderComponent } from '../allocationRequestAccumulationTypeChangeOrder';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class AllocationRequestAccumulationTypesComponent implements OnInit {
    
    public allocationRequestAccumulationTypes: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService
	) { }

    refresh() {
        this.http.get("allocation_request_accumulation_types").subscribe(x => {
            this.allocationRequestAccumulationTypes = x;
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(AllocationRequestAccumulationTypeFormComponent, {
            initialState: {
                allocationRequestAccumulationTypeId: null
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(allocationRequestAccumulationTypeId) {
        var bsModalRef = this.bsModalService.show(AllocationRequestAccumulationTypeFormComponent, {
            initialState: {
                allocationRequestAccumulationTypeId: allocationRequestAccumulationTypeId
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(allocationRequestAccumulationTypeId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("allocation_request_accumulation_types/" + allocationRequestAccumulationTypeId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(AllocationRequestAccumulationTypeChangeOrderComponent, {
            initialState: {
                originalAllocationRequestAccumulationTypes: this.allocationRequestAccumulationTypes
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
		this.refresh();
	}
	
    ngOnInit() {
        this.initialize();
    }
}
