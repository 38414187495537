import { Component, OnInit, Renderer2, Output, EventEmitter, ElementRef } from '@angular/core';
import templateString from './trainingCandidateForm.component.html';
import { EnvironmentService } from 'site/app/environment.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TrainingPeriod } from 'site/app/models/trainingPeriod.model';
import * as moment from 'moment';
import { parse } from 'path';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TrainingCandidateFormComponent implements OnInit {
    @Output() action = new EventEmitter();

	public selectedCandidate;
	public trainingPeriodAccumulationTypes;
	public originalTrainingPeriods;
	public subTypes;
	public tabIndex;

	public trainingPeriods: any;
	public isLoading: boolean;
	public user: any;
	public selectedTab: any;
	public overallTrainingPeriodAccumulationTypes: any;
	public totalTargetValues: any;
	public totalBalances: any;
	public candidateUnavailabilityPeriodIndex: any;
	public datepickerPopupStartEmployeeTerminationDate: any;
	public datepickerPopupStartTrainingStartDate: any;
	public submitted: boolean;
	public trainingStartDate: any;
	public employmentTerminationDate: any;
	public actionTitle: string;
	public weekdays: string[];
	public workWeekdays: { id: number; name: string; }[];
	public candidateTypes: any;
	public trainingLocations: any = {};
	public locations: any;
	public datePickerConfig;
	public location_id: number;
	public unavailabilityTypes;
	public errorLines: any[];
	public lengthAmounts: any;
	public preferredOrderAmounts: any;
    
    constructor(
        private environmentService: EnvironmentService,
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService,
		private host: ElementRef
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeTab(tabNumber) {
		this.selectedTab = tabNumber;
	}

	// addTaskAccumulationTargets() {
	// 	var self = this;
	// 	this.http.get.all("task_accumulation_periods/index_for_shift_count_overview",
	// 		{
	//			params: {
	// 				schedule_period_id: this.schedulePeriodId
	//			}
	// 		}
	// 	).subscribe(taskAccumulationPeriods => {
	// 		taskAccumulationPeriods.forEach(function(x) {
	// 			if (self.periodicCandidate.candidate.task_accumulation_targets.filter(function(y) { return y.task_accumulation_period_id == x.id }).length == 0) {
	// 				self.periodicCandidate.candidate.task_accumulation_targets.push(new TaskAccumulationTarget({ task_accumulation_period_id: x.id, task_accumulation_period: x, is_absolute: false, relative_value: 1 }));
	// 			}
	// 		});
			
	// 		this.periodicCandidate.candidate.task_accumulation_targets.filter(function(x) { return !x.is_absolute; }).forEach(function(x) {
	// 			x.percentage = x.relative_value * 100
	// 		});
	// 	});
	// }
	
	// addTrainingPeriodAccumulationTypeTargetsAndBalances() {
	// 	var self = this;
	// 	this.http.get("training_period_accumulation_types").subscribe(trainingPeriodAccumulationTypes => {
	// 		this.trainingPeriodAccumulationTypes = trainingPeriodAccumulationTypes;
			
	// 		this.periodicCandidate.trainingPeriodAccumulationTypes = {};
			
	// 		this.trainingPeriodAccumulationTypes.forEach(function(x) {
	// 			var trainingPeriodAccumulationTypeTargets = self.periodicCandidate.candidate.training_period_accumulation_type_targets.filter(function(y) { return y.training_period_accumulation_type_id == x.id });
	// 			var trainingPeriodAccumulationTypeTarget;
				
	// 			if (trainingPeriodAccumulationTypeTargets.length == 0) {
	// 				trainingPeriodAccumulationTypeTarget = new TrainingPeriodAccumulationTypeTarget({ 
	// 					training_period_accumulation_type_id: x.id, candidate_id: self.periodicCandidate.candidate_id, value: null });
	// 				self.periodicCandidate.candidate.training_period_accumulation_type_targets.push(trainingPeriodAccumulationTypeTarget);
	// 			} else {
	// 				trainingPeriodAccumulationTypeTarget = trainingPeriodAccumulationTypeTargets[0];
	// 			}
				
	// 			var trainingPeriodAccumulationTypeBalances = self.periodicCandidate.candidate.training_period_accumulation_type_balances.filter(function(y) { return y.training_period_accumulation_type_id == x.id });
	// 			var trainingPeriodAccumulationTypeBalance;
				
	// 			if (trainingPeriodAccumulationTypeBalances.length == 0) {
	// 				trainingPeriodAccumulationTypeBalance = new TrainingPeriodAccumulationTypeBalance({ 
	// 					training_period_accumulation_type_id: x.id, candidate_id: self.periodicCandidate.candidate_id, value: null });
	// 				self.periodicCandidate.candidate.training_period_accumulation_type_balances.push(trainingPeriodAccumulationTypeBalance);
	// 			} else {
	// 				trainingPeriodAccumulationTypeBalance = trainingPeriodAccumulationTypeBalances[0];
	// 			}
				
	// 			self.periodicCandidate.trainingPeriodAccumulationTypes[x.id] = {  
	// 				targetValue: trainingPeriodAccumulationTypeTarget.value,
	// 				balance: trainingPeriodAccumulationTypeBalance.value
	// 			};
	// 		});

	// 		this.http.get("overall_training_period_accumulation_types").subscribe(overallTrainingPeriodAccumulationTypes => {
	// 			this.overallTrainingPeriodAccumulationTypes = overallTrainingPeriodAccumulationTypes;
				
	// 			this.refreshOverallTrainingPeriodAccumulationTypeCountsAndTotals();
	// 		});
	// 	});
	// }
	
	// refreshOverallTrainingPeriodAccumulationTypeCountsAndTotals() {
	// 	var self = this;
	// 	this.overallTrainingPeriodAccumulationTypes.forEach(function(x) {
	// 		x.total = 0;
	// 	});
		
	// 	this.trainingPeriodAccumulationTypes.forEach(function(x) {

	// 		var overallTrainingPeriodAccumulationType = 
	// 			self.overallTrainingPeriodAccumulationTypes.filter(function(y) { return x.overall_training_period_accumulation_type_id == y.id; })[0];
			
	// 		if (overallTrainingPeriodAccumulationType != null && overallTrainingPeriodAccumulationType != undefined && 
	// 				self.periodicCandidate.trainingPeriodAccumulationTypes[x.id].targetValue != null && self.periodicCandidate.trainingPeriodAccumulationTypes[x.id].targetValue != "") {
	// 			overallTrainingPeriodAccumulationType.total += parseFloat(self.periodicCandidate.trainingPeriodAccumulationTypes[x.id].targetValue);
	// 		}
	// 	});
		
	// 	this.refreshOverallTrainingPeriodAccumulationTypeTotals();
	// }
	
	// refreshOverallTrainingPeriodAccumulationTypeTotals() {
	// 	var self = this;
	// 	this.totalTargetValues = Object.keys(this.periodicCandidate.trainingPeriodAccumulationTypes).filter(
	// 		function(x) { return self.periodicCandidate.trainingPeriodAccumulationTypes[x].targetValue != null && self.periodicCandidate.trainingPeriodAccumulationTypes[x].targetValue != "" }).map(
	// 		function(x) { return self.periodicCandidate.trainingPeriodAccumulationTypes[x].targetValue }).reduce(
	// 		function(acc, val) { return acc + parseFloat(val); }, 0);

	// 	this.totalBalances = Object.keys(this.periodicCandidate.trainingPeriodAccumulationTypes).filter(
	// 		function(x) { return self.periodicCandidate.trainingPeriodAccumulationTypes[x].balance != null && self.periodicCandidate.trainingPeriodAccumulationTypes[x].balance != "" }).map(
	// 		function(x) { return self.periodicCandidate.trainingPeriodAccumulationTypes[x].balance }).reduce(
	// 		function(acc, val) { return acc + parseFloat(val); }, 0);
	// }

	// deleteCandidateUnavailabilityPeriod(index) {
	// 	var matchIndex = -1;
	// 	this.periodicCandidate.candidate.candidate_unavailability_periods.some(function(el, i) {
	// 	    if (el.index == index) {
	// 	        matchIndex = i;
	// 	        return true;
	// 	    }
	// 	});
		
	// 	this.periodicCandidate.candidate.candidate_unavailability_periods.splice(matchIndex, 1);;
	// }
	
	addCandidateUnavailabilityPeriod() {
		var candidateUnavailabilityPeriodTrainingPeriod = {
			is_candidate_unavailability_period: true,
			candidate_id: this.selectedCandidate.id,
			start_date: null,
			end_date: null,
			id: null,
			is_locked: true,
			location_id: null,
			preferred_location_id: null,
			preferred_order: null,
			length: null,
			ratio: null,
			training_period_accumulation_type: null,
			training_period_accumulation_type_id: null,
			training_change_part_id: null,
			is_sub_type: false,
			sub_type_id: null
		};

		this.trainingPeriods.push(candidateUnavailabilityPeriodTrainingPeriod);
	}

	addSubTypeTrainingPeriod() {
		var newTrainingPeriod = new TrainingPeriod({
			candidate_id: this.selectedCandidate.id,
			is_sub_type: true,
			start_date: null,
			end_date: null,
			id: null,
			is_locked: true,
			location_id: null,
			preferred_location_id: null,
			preferred_order: null,
			length: null,
			ratio: this.selectedCandidate.ratio,
			training_period_accumulation_type: this.trainingPeriodAccumulationTypes.filter(function(x) { return x.id == 22 })[0],
			training_period_accumulation_type_id: 22,
			training_change_part_id: null,
			sub_type_id: null
		});

		this.trainingPeriods.push(newTrainingPeriod);
	}

	split(trainingPeriod, index) {
		var newTrainingPeriod = new TrainingPeriod({
			candidate_id: trainingPeriod.candidate_id,
			new_start_date: trainingPeriod.start_date,
			new_end_date: trainingPeriod.end_date,
			id: null,
			is_locked: false,
			location_id: null,
			preferred_location_id: trainingPeriod.preferred_location_id,
			is_binding_location_preference: trainingPeriod.is_binding_location_preference,
			preferred_order: trainingPeriod.preferred_order,
			ratio: trainingPeriod.ratio,
			training_period_accumulation_type: trainingPeriod.training_period_accumulation_type,
			training_period_accumulation_type_id: trainingPeriod.training_period_accumulation_type_id,
		});

		this.trainingPeriods.splice(index+1, 0, newTrainingPeriod);
	}

	addTrainingPeriod() {
		var newTrainingPeriod = new TrainingPeriod({
			candidate_id: this.trainingPeriods[this.trainingPeriods.length-1].candidate_id,
			new_start_date: null,
			new_end_date: null,
			id: null,
			is_locked: false,
			location_id: null,
			preferred_location_id: null,
			is_binding_location_preference: false,
			preferred_order: null,
			ratio: null,
			training_period_accumulation_type: null,
			training_period_accumulation_type_id: null,
			startDateNotMonday: false
		});

		this.trainingPeriods.push(newTrainingPeriod);
	}

	remove(trainingPeriod, index) {
		if (trainingPeriod.id) {
			trainingPeriod.is_removed = true;
		} else {
			this.trainingPeriods.splice(index, 1);
		}

		this.updateTrainingPeriodAccumulationTypeTargetValues(trainingPeriod);

		this.reorderAndSetNewDates();
	}

	changeStartDate(trainingPeriod) {
		if (moment(trainingPeriod.start_date, "DD-MM-YYYY", true).isValid()) {
			trainingPeriod.displayDateEnd = moment(trainingPeriod.start_date);
			trainingPeriod.startDateNotMonday = (trainingPeriod.start_date && moment(trainingPeriod.start_date).day() != 1);
		}
	}

	changeEndDate(trainingPeriod, index) {
		if (trainingPeriod.start_date && moment(trainingPeriod.up_to_and_including_end_date).isValid()) {

			trainingPeriod.end_date = moment(trainingPeriod.up_to_and_including_end_date).add(1, 'day');

			if (trainingPeriod.start_date && trainingPeriod.end_date) {
			
				trainingPeriod.length = moment(trainingPeriod.end_date).diff(moment(trainingPeriod.start_date), 'weeks');
				this.updateTrainingPeriodAccumulationTypeTargetValues(trainingPeriod);

				var indexIntersectingTrainingPeriod = null;
				var i = 0;
				this.trainingPeriods.forEach(function(tp) {
					if (i != index && !tp.is_removed && moment(trainingPeriod.start_date) > moment(tp.new_start_date) && moment(trainingPeriod.start_date) < moment(tp.new_end_date)) {
						indexIntersectingTrainingPeriod = i;
					}

					i++;
				});

				if (indexIntersectingTrainingPeriod != null) {
					var intersectingTrainingPeriod = this.trainingPeriods[indexIntersectingTrainingPeriod];

					if (window.confirm('De start van deze ' + (trainingPeriod.is_candidate_unavailability_period ? 'afwezigheid' : 'verdiepingsstage') + ' valt binnen de stage ' + 
						intersectingTrainingPeriod.training_period_accumulation_type.name + '(' + moment(intersectingTrainingPeriod.new_start_date).format('D MMM YYYY') + ' t/m ' + 
						moment(intersectingTrainingPeriod.new_end_date).subtract(1, 'day').format('D MMM YYYY') + ') waardoor de onderdelen niet meer op elkaar aansluiten. ' + 
						'Wil je deze stage opsplitsen zodat de invoer weer correct is?')
					) {
						var weeksBefore = moment(trainingPeriod.start_date).diff(moment(intersectingTrainingPeriod.new_start_date), 'weeks');
						var weeksAfter = this.trainingPeriods[indexIntersectingTrainingPeriod].length - weeksBefore;

						this.split(intersectingTrainingPeriod, indexIntersectingTrainingPeriod);
						this.trainingPeriods[indexIntersectingTrainingPeriod].length = weeksBefore;
						this.trainingPeriods[indexIntersectingTrainingPeriod+1].length = weeksAfter;
						this.updateTrainingPeriodAccumulationTypeTargetValues(this.trainingPeriods[indexIntersectingTrainingPeriod]);
						this.updateTrainingPeriodAccumulationTypeTargetValues(this.trainingPeriods[indexIntersectingTrainingPeriod+1]);
						this.reorderAndSetNewDates();
					}
				}
			}
			
			this.reorderAndSetNewDates();

			if (trainingPeriod.start_date && trainingPeriod.end_date) {
				this.updatePreferredOrders();
			}
		}
	}

	changeLength(trainingPeriod) {
		this.updateTrainingPeriodAccumulationTypeTargetValues(trainingPeriod);
		this.reorderAndSetNewDates();
	}
	
	changeIsBindingOrderPreference(trainingPeriod) {
		this.reorderAndSetNewDates();

		if (trainingPeriod.is_binding_order_preference) {			
			this.updatePreferredOrders();
		}
	}

	updatePreferredOrders() {
		var highestPreferredOrderUntilNow = 0;
		var offsetFromNowOn = 0;
		for (var i = 0; i < this.trainingPeriods.length; i++) {
			var tp = this.trainingPeriods[i];
			if (!tp.is_removed) {
				if (tp.is_candidate_unavailability_period || tp.is_sub_type || tp.is_binding_order_preference) {
					var lowestPreferredOrderInRemaining = 999;
					for (var j = i+1; j < this.trainingPeriods.length; j++) {
						lowestPreferredOrderInRemaining = Math.min(lowestPreferredOrderInRemaining, this.trainingPeriods[j].preferred_order);	
					}

					if (tp.is_binding_order_preference) {
						highestPreferredOrderUntilNow = Math.max(highestPreferredOrderUntilNow, parseInt(tp.preferred_order));
					}

					var j = i;
					var atLeastOneOfRemainingTrainingPeriodsHasLowerPreferredOrder = false;
					while (j < this.trainingPeriods.length) {
						if (!(this.trainingPeriods[j].is_candidate_unavailability_period || this.trainingPeriods[j].is_sub_type || this.trainingPeriods[j].is_binding_order_preference) && 
								this.trainingPeriods[j].preferred_order <= highestPreferredOrderUntilNow) {
							atLeastOneOfRemainingTrainingPeriodsHasLowerPreferredOrder = true;
							break;
						}

						j++;
					}

					if (atLeastOneOfRemainingTrainingPeriodsHasLowerPreferredOrder) {
						offsetFromNowOn = highestPreferredOrderUntilNow + 1 - lowestPreferredOrderInRemaining;
					} else {
						offsetFromNowOn = 0;
					}
				} else {				
					tp.preferred_order = parseInt(tp.preferred_order) + offsetFromNowOn;
				}

				highestPreferredOrderUntilNow = Math.max(highestPreferredOrderUntilNow, tp.preferred_order);
			}			
		}
	}

	// reorderAfterSettingDates(currentIndex) {
	// 	var trainingPeriod = this.trainingPeriods[currentIndex];

	// 	if (trainingPeriod.start_date && trainingPeriod.end_date) {
    // 		this.trainingPeriods.splice(currentIndex, 1);

	// 		var index = this.trainingPeriods.length;

	// 		for (var i = 0; i < this.trainingPeriods.length; i++) {
	// 			if (moment(trainingPeriod.start_date) <= moment(this.trainingPeriods[i].start_date)) {
	// 				index = i;
	// 				break;
	// 			}
	// 		}

	// 		this.trainingPeriods.splice(index, 0, trainingPeriod);
	// 	}
	// }

	reorderAndSetNewDates() {
		var self = this;

		var newTrainingPeriods = []

		this.trainingPeriods.sort(function(a, b) { return (!a.start_date || moment(a.start_date) > moment(b.start_date)) ? 1 : -1 });
		var i = 1;
		this.trainingPeriods.forEach(function(tp) {
			tp.tempId = i;
			i++;
		});

		var currentDate = moment(this.trainingPeriods[0].start_date);

		while(this.trainingPeriods.length > 0) {

			var newTrainingPeriod;

			var unavailabilityPeriodsOrSubTypes = this.trainingPeriods.filter(function(x) { 
				return (x.is_candidate_unavailability_period || x.is_sub_type) && moment(x.start_date) <= moment(currentDate);
			});

			if (unavailabilityPeriodsOrSubTypes.length > 0) {
				unavailabilityPeriodsOrSubTypes.sort(function(a, b) { return (moment(a.start_date) > moment(b.start_date)) ? 1 : -1 });

				unavailabilityPeriodsOrSubTypes.forEach(function(tp) {
					newTrainingPeriod = self.trainingPeriods.splice(self.trainingPeriods.map(function(y) { return y.tempId; }).indexOf(tp.tempId), 1)[0];
					newTrainingPeriod.new_start_date = newTrainingPeriod.start_date;
					newTrainingPeriod.new_end_date = moment(newTrainingPeriod.end_date).subtract(1, 'day');
					newTrainingPeriods.push(newTrainingPeriod);
					if (!newTrainingPeriod.is_removed && newTrainingPeriod.start_date) {
						currentDate = moment(newTrainingPeriod.end_date);
					}
				});
			} else {
				var fixedOrUnfixedTrainingPeriods = this.trainingPeriods.filter(function(x) { 
					return self.trainingPeriods[0].is_binding_order_preference ? 
						x.preferred_order && x.preferred_order < self.trainingPeriods[0].preferred_order
						:
						x.is_binding_order_preference && x.preferred_order <= self.trainingPeriods[0].preferred_order;
				});

				if (fixedOrUnfixedTrainingPeriods.length > 0) {
					fixedOrUnfixedTrainingPeriods.sort(function(a, b) { return a.preferred_order > b.preferred_order ? 1 : -1 });

					newTrainingPeriod = this.trainingPeriods.splice(this.trainingPeriods.map(function(y) { return y.tempId; }).indexOf(fixedOrUnfixedTrainingPeriods[0].tempId), 1)[0];
					newTrainingPeriod.new_start_date = currentDate;
					var newCurrentDate = moment(currentDate).add(newTrainingPeriod.length, 'weeks');
					newTrainingPeriod.new_end_date = moment(newCurrentDate).subtract(1, 'day');
					newTrainingPeriods.push(newTrainingPeriod);
					if (!newTrainingPeriod.is_removed) {
						currentDate = moment(newCurrentDate);
					}

				} else {

					var futureUnavailabilityPeriodsOrSubTypes = this.trainingPeriods.filter(function(x) { 
						return (x.start_date && x.end_date && (x.is_candidate_unavailability_period || x.is_sub_type) && moment(x.start_date) > moment(currentDate));
					});

					if (futureUnavailabilityPeriodsOrSubTypes.length > 0) {
						futureUnavailabilityPeriodsOrSubTypes.sort(function(a, b) { return (moment(a.start_date) > moment(b.start_date)) ? 1 : -1 });

						var startDateFirstFutureUnavailabilityPeriodOrSubType = futureUnavailabilityPeriodsOrSubTypes[0].start_date;
						var trainingPeriodFound = false;

						this.trainingPeriods.filter(function(x) { return !(x.is_candidate_unavailability_period || x.is_sub_type); }).forEach(function(trainingPeriod) {

							if (
								!trainingPeriodFound && moment(currentDate).add(
									parseInt(self.trainingPeriods.filter(function(x) { return !(x.is_candidate_unavailability_period || x.is_sub_type) && !x.is_removed && x.preferred_order && x.preferred_order < trainingPeriod.preferred_order; })
										.reduce(function(acc, tp) { return acc + tp.length; }, 0)) + parseInt(trainingPeriod.length), 'weeks'
								) <= moment(startDateFirstFutureUnavailabilityPeriodOrSubType)
							) {
								newTrainingPeriod = self.trainingPeriods.splice(self.trainingPeriods.map(function(y) { return y.tempId; }).indexOf(trainingPeriod.tempId), 1)[0];
								newTrainingPeriod.new_start_date = currentDate;
								var newCurrentDate = moment(currentDate).add(newTrainingPeriod.length, 'weeks');
								newTrainingPeriod.new_end_date = moment(newCurrentDate).subtract(1, 'day');
								newTrainingPeriods.push(newTrainingPeriod);
								if (!newTrainingPeriod.is_removed) {
									currentDate = moment(newCurrentDate);
								}
								trainingPeriodFound = true;
							}
						});

						if (!trainingPeriodFound) {
							console.error("Training period niet gevonden");

							// Take first training period (could also take the one with lowest preferred order, 
							// but that would make things extremely complicated)
							newTrainingPeriod = this.trainingPeriods.splice(0, 1)[0];
							newTrainingPeriod.new_start_date = currentDate;
							var newCurrentDate = moment(currentDate).add(newTrainingPeriod.length, 'weeks');
							newTrainingPeriod.new_end_date = moment(newCurrentDate).subtract(1, 'day');
							newTrainingPeriods.push(newTrainingPeriod);
							if (!newTrainingPeriod.is_removed) {
								currentDate = moment(newCurrentDate);
							}
						}
					} else {
						newTrainingPeriod = this.trainingPeriods.splice(0, 1)[0];
						newTrainingPeriod.new_start_date = currentDate;
						var newCurrentDate = moment(currentDate).add(newTrainingPeriod.length, 'weeks');
						newTrainingPeriod.new_end_date = moment(newCurrentDate).subtract(1, 'day');
						newTrainingPeriods.push(newTrainingPeriod);
						if (!newTrainingPeriod.is_removed) {
							currentDate = moment(newCurrentDate);
						}
					}
				}

				newTrainingPeriod.start_date_changed = moment(newTrainingPeriod.new_start_date).format('YYYY-MM-DD') != moment(newTrainingPeriod.start_date).format('YYYY-MM-DD');
				newTrainingPeriod.end_date_changed = moment(newTrainingPeriod.new_end_date).format('YYYY-MM-DD') != moment(newTrainingPeriod.computedEndDate).format('YYYY-MM-DD');
			}
		}

		this.trainingPeriods = newTrainingPeriods;
	}

	reorderAfterSettingIsBindingOrderPreference(currentIndex) {
		var trainingPeriod = this.trainingPeriods[currentIndex];

		this.trainingPeriods.splice(currentIndex, 1);

		var index = this.trainingPeriods.length;

		for (var i = 0; i < this.trainingPeriods.length; i++) {
			if (trainingPeriod.is_binding_order_preference) {			
				if (trainingPeriod.preferred_order <= this.trainingPeriods[i].preferred_order) {
					index = i;
					break;
				}
			} else {
				if (moment(trainingPeriod.start_date) <= moment(this.trainingPeriods[i].start_date)) {
					index = i;
					break;
				}
			}
		}

		this.trainingPeriods.splice(index, 0, trainingPeriod);
	}

	updateLengthUponRatioChange(trainingPeriod) {
		if (trainingPeriod.currentLength && trainingPeriod.currentRatio) {
			trainingPeriod.ratio = parseFloat(trainingPeriod.ratio.toString().replace(/,/g, '.'));
			trainingPeriod.length = Math.round(trainingPeriod.currentLength * trainingPeriod.currentRatio / trainingPeriod.ratio); 
		}

		this.updateTrainingPeriodAccumulationTypeTargetValues(trainingPeriod);
	}

	updateTrainingPeriodAccumulationTypeTargetValues(trainingPeriod) {
		if (!trainingPeriod.is_candidate_unavailability_period) {
			var trainingPeriodAccumulationTypeId = trainingPeriod.training_period_accumulation_type_id;

			var total = 0;
			
			this.trainingPeriods.filter(function(x) { return x.training_period_accumulation_type_id == trainingPeriodAccumulationTypeId && !x.is_removed; }).forEach(function(y) {
				if (y.length && parseInt(y.length) && y.ratio && parseFloat(y.ratio)) {
					total += parseInt(y.length) * parseFloat(y.ratio);
				}
			});
	
			this.selectedCandidate.trainingPeriodAccumulationTypes[trainingPeriodAccumulationTypeId].targetValue = Math.round(total * 10) / 10;	
		}
	}

  	ok(isValid) {
		var self = this;
		this.isLoading = true;
		this.submitted = true;
		this.errorLines = [];

		if (isValid) {
			var previousTrainingPeriod = null;
			for (var i=0; i < this.trainingPeriods.length; i++) {
				var trainingPeriod = this.trainingPeriods[i];

				if (!trainingPeriod.is_removed) {

					if (previousTrainingPeriod != null) {
						var startDate = trainingPeriod.new_start_date; // ? trainingPeriod.new_start_date : trainingPeriod.start_date
						var endDate = moment(previousTrainingPeriod.new_end_date).add(1, 'day'); // ? previousTrainingPeriod.new_end_date : previousTrainingPeriod.end_date
						
						if (moment(startDate).format("DD-MM-YYYY") != moment(endDate).format("DD-MM-YYYY")) {
							var difference = moment(endDate).diff(startDate, 'weeks');

							this.errorLines.push(
								"De onderdelen '" + (new TrainingPeriod(previousTrainingPeriod)).formatString() + "' en " +
								"'" + (new TrainingPeriod(trainingPeriod)).formatString() + "' overlappen (" + difference.toString() + " weken overlap)"
							);
						}
					}
				
					previousTrainingPeriod = trainingPeriod;
				}
			}

			if (this.errorLines.length == 0) {

				Object.keys(this.selectedCandidate.trainingPeriodAccumulationTypes).forEach(function(trainingPeriodAccumulationTypeId) {
					self.selectedCandidate.training_period_accumulation_type_targets.filter(function(x) { 
						return x.training_period_accumulation_type_id == trainingPeriodAccumulationTypeId }
					)[0].value = self.selectedCandidate.trainingPeriodAccumulationTypes[trainingPeriodAccumulationTypeId].targetValueChanged ? self.selectedCandidate.trainingPeriodAccumulationTypes[trainingPeriodAccumulationTypeId].currentTargetValue : self.selectedCandidate.trainingPeriodAccumulationTypes[trainingPeriodAccumulationTypeId].targetValue;
				
					self.selectedCandidate.training_period_accumulation_type_balances.filter(function(x) { 
						return x.training_period_accumulation_type_id == trainingPeriodAccumulationTypeId }
					)[0].value = self.selectedCandidate.trainingPeriodAccumulationTypes[trainingPeriodAccumulationTypeId].balanceChanged ? self.selectedCandidate.trainingPeriodAccumulationTypes[trainingPeriodAccumulationTypeId].currentBalance : self.selectedCandidate.trainingPeriodAccumulationTypes[trainingPeriodAccumulationTypeId].balance;
				});
	
				this.trainingPeriods.forEach(function(trainingPeriod) {
					if (trainingPeriod.lengthChanged) {
						trainingPeriod.length = trainingPeriod.currentLength;
					}
					if (trainingPeriod.preferredOrderChanged) {
						trainingPeriod.preferred_order = trainingPeriod.currentPreferredOrder;
						trainingPeriod.is_binding_order_preference = trainingPeriod.currentIsBindingOrderPreference;
					}
					if (trainingPeriod.preferredLocationChanged) {
						trainingPeriod.preferred_location_id = trainingPeriod.currentPreferredLocationId;
						trainingPeriod.is_binding_location_preference = trainingPeriod.currentIsBindingLocationPreference;
					}
					if (trainingPeriod.ratioChanged) {
						trainingPeriod.ratio = trainingPeriod.currentRatio;
					}
	
					if (trainingPeriod.unavailability_type_id) {
						trainingPeriod.description = self.unavailabilityTypes[trainingPeriod.unavailability_type_id - 1].name;
					}
				});
	
				for (var i = this.trainingPeriods.length-1; i >= 0; i--) {
					if (this.trainingPeriods[i].training_change_part_id) {
						if (this.trainingPeriods[i].is_removed) {
							this.trainingPeriods[i].is_removed = false;
						} else {
							this.trainingPeriods.splice(i, 1);
						}
					}
				}
	
				// if (this.trainingStartDate) {
				// 	var trainingStartDate = moment(this.trainingStartDate);
				// 	trainingStartDate.locale('en');
				// 	this.selectedCandidate.training_start_date = trainingStartDate.format("ddd D MMM YYYY");
				// } else {
				// 	this.selectedCandidate.training_start_date = null;
				// }
	
				// if (this.employmentTerminationDate) {
				// 	var employmentTerminationDate = moment(this.employmentTerminationDate);
				// 	employmentTerminationDate.locale('en');
				// 	this.selectedCandidate.employment_termination_date = employmentTerminationDate.format("ddd D MMM YYYY");
				// } else {
				// 	this.selectedCandidate.employment_termination_date = null;
				// }
	
				// this.periodicCandidate.candidate.candidate_unavailability_periods.forEach(function(candidateUnavailabilityPeriod) {
				// 	candidateUnavailabilityPeriod.start_date = candidateUnavailabilityPeriod.startDate.format("ddd D MMM YYYY");
				// 	candidateUnavailabilityPeriod.end_date = candidateUnavailabilityPeriod.endDate.format("ddd D MMM YYYY");
				// });
	
	
	
				// this.periodicCandidate.candidate.candidate_unavailability_periods.forEach(function(candidateUnavailabilityPeriod) {
				// 	candidateUnavailabilityPeriod.start_date = candidateUnavailabilityPeriod.startDate.format("ddd D MMM YYYY");
				// 	candidateUnavailabilityPeriod.end_date = candidateUnavailabilityPeriod.endDate.format("ddd D MMM YYYY");
				// });
	
				if (this.selectedCandidate.id === null) {
				// 	this.periodicCandidate.schedule_period_id = this.schedulePeriodId;
					
				// 	this.http.post("periodic_candidates", this.periodicCandidate).subscribe(() => {
				// 		this.isLoading = false;
				// 		this.action.emit(true);
				// 		this.bsModalRef.hide();
				// 	}, (response) => {
				// 		this.isLoading = false;
				// 		this.toastr.error(response.data.error);
				// 	});
				} else {
					this.http.post<any>("training_changes",
						{ 
							candidate: this.selectedCandidate,
							training_periods: this.trainingPeriods
						}
					).subscribe((response) => {
						this.isLoading = false;
						this.action.emit(response.training_change_ids);
						this.bsModalRef.hide();
					}, (response) => {
						this.isLoading = false;
						this.toastr.error(response.data.error);
					});
				}
	
				this.isLoading = false;
			} else {
				this.isLoading = false;
			}
			
		} else {
			this.isLoading = false;
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}

  	ngOnInit() {
		var self = this;

		this.location_id = 1;
		this.isLoading = true;
		this.user = this.environmentService.getUser();

		this.lengthAmounts = [];
		for(var i = 1; i <= 75; i++) { this.lengthAmounts.push(i); };

		this.preferredOrderAmounts = [];
		for(var i = 1; i <= 15; i++) { this.preferredOrderAmounts.push(i); };

		this.trainingPeriods = JSON.parse(JSON.stringify(this.originalTrainingPeriods));

		this.reorderAndSetNewDates();

		this.unavailabilityTypes = [
			{ id: 1, name: "Periode definitief" },
			{ id: 2, name: "Periode niet definitief" }
		];

		this.changeTab(this.tabIndex);

		if (this.selectedCandidate.id === null) {
			// this.periodicCandidate = new PeriodicCandidate({ 
			// 	candidate: new Candidate({ task_accumulation_targets: [], training_period_accumulation_type_targets: [], training_period_accumulation_type_balances: [], candidate_unavailability_periods: [] })
			// });
			// this.addTaskAccumulationTargets();
			// this.addTrainingPeriodAccumulationTypeTargetsAndBalances();
			// this.actionTitle = "toevoegen";
			
			this.isLoading = false;
		} else {


			// this.http.get("periodic_candidates/" + this.periodicCandidateId).subscribe(x => {
			// 	this.periodicCandidate = x;
			// 	this.addTaskAccumulationTargets();
			// 	this.addTrainingPeriodAccumulationTypeTargetsAndBalances();
	
			// 	if (this.periodicCandidate.candidate.users.length > 0) {
			// 		this.periodicCandidate.candidate.email = this.periodicCandidate.candidate.users[0].email;
			// 		this.periodicCandidate.candidate.is_admin = this.periodicCandidate.candidate.users[0].is_admin;
			// 	}
	
			// 	if (this.periodicCandidate.candidate.training_start_date != null) {
			// 		this.trainingStartDate = moment(this.periodicCandidate.candidate.training_start_date);
			// 	}
	
			// 	if (this.periodicCandidate.candidate.employment_termination_date != null) {
			// 		this.employmentTerminationDate = moment(this.periodicCandidate.candidate.employment_termination_date);
			// 	}
				
			// 	this.periodicCandidate.candidate.candidate_unavailability_periods.forEach(function(candidateUnavailabilityPeriod, index) {
			// 		candidateUnavailabilityPeriod.index = index;
			// 		candidateUnavailabilityPeriod.startDate = moment(candidateUnavailabilityPeriod.start_date);
			// 		candidateUnavailabilityPeriod.endDate = moment(candidateUnavailabilityPeriod.end_date);
			// 	});
	
			// 	this.candidateUnavailabilityPeriodIndex = this.periodicCandidate.candidate.candidate_unavailability_periods.length;
				
			// 	this.isLoading = false;
			// });

			this.isLoading = false;

			this.actionTitle = "wijzigen";
		}
		
		this.weekdays = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];
		this.workWeekdays = this.weekdays.slice(1, 6).map(function(x, index) { return { id: index + 1, name: x }; });
	
		this.http.get("candidate_types").subscribe(candidateTypes => {
			this.candidateTypes = candidateTypes;
		});
	
		this.http.get<any>("locations").subscribe(trainingLocations => {
			this.trainingPeriodAccumulationTypes.forEach(function(x) {
				self.trainingLocations[x.id] = x.location_capacities.filter(function(y) { return y.max > 0 }).map(function(y) { return trainingLocations.filter(function(z) { return z.id == y.location_id; })[0]; });
			});
		});
		

		this.http.get("locations").subscribe(locations => {
			this.locations = locations;
		});
	
		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};
	}
}



	
// 	public user: any;
// 	public candidate: any;
// 	public trainingPeriodAccumulationTypes: any;
// 	public overallTrainingPeriodAccumulationTypes: any;
// 	public totalTargetValues: any;
// 	public totalBalances: any;
// 	public candidateUnavailabilityPeriodIndex: any;
// 	public submitted: boolean;
// 	public trainingStartDate: any;
// 	public employmentTerminationDate: any;
// 	public actionTitle: string;
// 	public weekdays: string[];
// 	public workWeekdays: { id: number; name: string; }[];
// 	public candidateTypes: any;
// 	public trainingLocations: any;
// 	public locations: any;
// 	public comment;
    

// 	addTrainingPeriodAccumulationTypeTargetsAndBalances() {
// 		var self = this;
// 		this.http.get("training_period_accumulation_types").subscribe(trainingPeriodAccumulationTypes => {
// 			this.trainingPeriodAccumulationTypes = trainingPeriodAccumulationTypes;
			
// 			this.candidate.trainingPeriodAccumulationTypes = {};
			
// 			this.trainingPeriodAccumulationTypes.forEach(function(x) {
// 				var trainingPeriodAccumulationTypeTargets = self.candidate.training_period_accumulation_type_targets.filter(function(y) { return y.training_period_accumulation_type_id == x.id });
// 				var trainingPeriodAccumulationTypeTarget;
				
// 				if (trainingPeriodAccumulationTypeTargets.length == 0) {
// 					trainingPeriodAccumulationTypeTarget = new TrainingPeriodAccumulationTypeTarget({ 
// 						training_period_accumulation_type_id: x.id, candidate_id: self.candidate.id, value: null });
// 					self.candidate.training_period_accumulation_type_targets.push(trainingPeriodAccumulationTypeTarget);
// 				} else {
// 					trainingPeriodAccumulationTypeTarget = trainingPeriodAccumulationTypeTargets[0];
// 				}
				
// 				var trainingPeriodAccumulationTypeBalances = self.candidate.training_period_accumulation_type_balances.filter(function(y) { return y.training_period_accumulation_type_id == x.id });
// 				var trainingPeriodAccumulationTypeBalance;
				
// 				if (trainingPeriodAccumulationTypeBalances.length == 0) {
// 					trainingPeriodAccumulationTypeBalance = new TrainingPeriodAccumulationTypeBalance({ 
// 						training_period_accumulation_type_id: x.id, candidate_id: self.candidate.id, value: null });
// 					self.candidate.training_period_accumulation_type_balances.push(trainingPeriodAccumulationTypeBalance);
// 				} else {
// 					trainingPeriodAccumulationTypeBalance = trainingPeriodAccumulationTypeBalances[0];
// 				}
				
// 				self.candidate.trainingPeriodAccumulationTypes[x.id] = {  
// 					targetValue: trainingPeriodAccumulationTypeTarget.value,
// 					balance: trainingPeriodAccumulationTypeBalance.value
// 				};
// 			});

// 			this.http.get("overall_training_period_accumulation_types").subscribe(overallTrainingPeriodAccumulationTypes => {
// 				this.overallTrainingPeriodAccumulationTypes = overallTrainingPeriodAccumulationTypes;
				
// 				this.refreshOverallTrainingPeriodAccumulationTypeCountsAndTotals();
// 			});
// 		});
// 	}
	
// 	refreshOverallTrainingPeriodAccumulationTypeCountsAndTotals() {
// 		var self = this;
// 		this.overallTrainingPeriodAccumulationTypes.forEach(function(x) {
// 			x.total = 0;
// 		});
		
// 		this.trainingPeriodAccumulationTypes.forEach(function(x) {

// 			var overallTrainingPeriodAccumulationType = 
// 				self.overallTrainingPeriodAccumulationTypes.filter(function(y) { return x.overall_training_period_accumulation_type_id == y.id; })[0];
			
// 			if (overallTrainingPeriodAccumulationType != null && overallTrainingPeriodAccumulationType != undefined && 
// 					self.candidate.trainingPeriodAccumulationTypes[x.id].targetValue != null && self.candidate.trainingPeriodAccumulationTypes[x.id].targetValue != "") {
// 				overallTrainingPeriodAccumulationType.total += parseFloat(self.candidate.trainingPeriodAccumulationTypes[x.id].targetValue);
// 			}
// 		});
		
// 		this.refreshOverallTrainingPeriodAccumulationTypeTotals();
// 	}
	
// 	refreshOverallTrainingPeriodAccumulationTypeTotals() {
// 		var self = this;
// 		this.totalTargetValues = Object.keys(this.candidate.trainingPeriodAccumulationTypes).filter(
// 			function(x) { return self.candidate.trainingPeriodAccumulationTypes[x].targetValue != null && self.candidate.trainingPeriodAccumulationTypes[x].targetValue != "" }).map(
// 			function(x) { return self.candidate.trainingPeriodAccumulationTypes[x].targetValue }).reduce(
// 			function(acc, val) { return acc + parseFloat(val); }, 0);

// 		this.totalBalances = Object.keys(this.candidate.trainingPeriodAccumulationTypes).filter(
// 			function(x) { return self.candidate.trainingPeriodAccumulationTypes[x].balance != null && self.candidate.trainingPeriodAccumulationTypes[x].balance != "" }).map(
// 			function(x) { return self.candidate.trainingPeriodAccumulationTypes[x].balance }).reduce(
// 			function(acc, val) { return acc + parseFloat(val); }, 0);
// 	}

// 	deleteCandidateUnavailabilityPeriod(index) {
// 		var matchIndex = -1;
// 		this.candidate.candidate_unavailability_periods.some(function(el, i) {
// 		    if (el.index == index) {
// 		        matchIndex = i;
// 		        return true;
// 		    }
// 		});
		
// 		this.candidate.candidate_unavailability_periods.splice(matchIndex, 1);;
// 	}
	
// 	addCandidateUnavailabilityPeriod() {
// 		this.candidate.candidate_unavailability_periods.push(new CandidateUnavailabilityPeriod({ index: this.candidateUnavailabilityPeriodIndex++, start_date: null, end_date: null }));
// 	}


//   	ngOnInit() {
// 		this.isLoading = true;
// 		this.user = this.environmentService.getUser();
		
// 		this.http.get.one("candidates/get_for_training",
// 			{
//				params: {
// 					id: this.user.candidateId, 
// 					schedule_period_id: this.schedulePeriodId
//				}
// 			}
// 		).subscribe(x => {
			
// 			this.candidate = x;
// 			this.addTrainingPeriodAccumulationTypeTargetsAndBalances();

// 			this.candidate.candidate_unavailability_periods.forEach(function(candidateUnavailabilityPeriod, index) {
// 				candidateUnavailabilityPeriod.index = index;
// 				candidateUnavailabilityPeriod.startDate = moment(candidateUnavailabilityPeriod.start_date);
// 				candidateUnavailabilityPeriod.endDate = moment(candidateUnavailabilityPeriod.end_date);
// 			});

// 			this.candidateUnavailabilityPeriodIndex = this.candidate.candidate_unavailability_periods.length;

// 			this.isLoading = false;
// 		});

// 		this.actionTitle = "wijzigen";
		
// 		this.weekdays = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];
// 		this.workWeekdays = this.weekdays.slice(1, 6).map(function(x, index) { return { id: index + 1, name: x }; });
	
// 		this.http.get("candidate_types").subscribe(candidateTypes => {
// 			this.candidateTypes = candidateTypes;
// 		});
	
// 		this.http.get("locations", { params: { is_training: true } }).subscribe(trainingLocations => {
// 			this.trainingLocations = trainingLocations;
// 		});

// 		this.http.get("locations").subscribe(locations => {
// 			this.locations = locations;
// 		});
// 	}
// }

