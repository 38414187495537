import { Component, OnInit } from '@angular/core';
import templateString from './trainingCandidates.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { EnvironmentService } from 'site/app/environment.service';
import { Candidate } from 'site/app/models/candidate.model';
import { TrainingCandidateFormComponent } from '../trainingCandidateForm';
import * as moment from 'moment';
import { TrainingPeriodAccumulationTypeTarget } from 'site/app/models/trainingPeriodAccumulationTypeTarget.model';
import { TrainingPeriodAccumulationTypeBalance } from 'site/app/models/trainingPeriodAccumulationTypeBalance.model';
import { TrainingChangeDetailsFormComponent } from '../trainingChangeDetailsForm';
import { TrainingChangeTrainerCommentComponent } from '../trainingChangeTrainerComment';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TrainingCandidatesComponent implements OnInit {
    public candidates: any;
    public selectedCandidate: any;
    public isLoading: boolean;
    public weekdays: string[];
    public workWeekdays: any;
    public user: any;
    public trainingPeriods: any;
    public trainingPeriodAccumulationTypes: any;
    public overallTrainingPeriodAccumulationTypes: any;
    public totalTargetValues: any;
    public totalBalances: any;
    public filterOptions: { id: number; title: string; }[];
    public filterOptionId: number;
    public trainingChangeStatuses: string[];
    public selectedTab: any;
    public openTrainingChangePartsForCandidate: any;
	public trainingChangePartTypes: string[];
	public changedTrainingAccumulationTypeIds: any;
	public subTypes: any;
	public regionalCandidateNotFound: boolean;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { }

    refresh() {
        this.http.get("candidates", 
            {
				params: {
					location_id: this.filterOptionId == 1 ? this.user.location.id : 0
				}
            }
        ).subscribe(x => {
			this.candidates = x;
			this.candidates.forEach(function(candidate) {
				candidate.fullName = (new Candidate(candidate)).fullName();
			});
			
			if (!this.user.isSuperAdmin && !(this.user.isTrainingAdmin && !this.user.hasOnlyAccessToOwnTraineesAndLocation) && !this.user.isTrainer) {
				this.http.get<any>('candidates/' + this.user.candidateId).subscribe(y => {
					this.show(y.regional_candidate_id, 3);

					this.isLoading = false;
				});				
			} else {
				this.isLoading = false;
			}
		});
    }
    
    changeFilterOption(filterOptionId) {
		this.filterOptionId = filterOptionId;
		this.refresh();
	}

    create() {
        var bsModalRef = this.bsModalService.show(TrainingCandidateFormComponent, {
            initialState: {
                candidateId: null
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
            this.isLoading = true;
			this.refresh();
        });
    }

    show(candidateId, selectedTab) {
        var self = this;

		if (!candidateId) {
		
			this.regionalCandidateNotFound = true;

		} else {

			this.http.get("candidates/get_for_training",
				{
					params: {
						id: candidateId
					}
				}
			).subscribe(x => {
				this.selectedCandidate = x;

				if (!this.user.isSuperAdmin && !(this.user.isTrainingAdmin && !this.user.hasOnlyAccessToOwnTraineesAndLocation) && !this.user.isTrainer) {
					this.selectedCandidate.training_changes = this.selectedCandidate.training_changes.filter(function(y) { return !y.is_for_scenario_test; })
				}

				this.selectedCandidate.fullName = (new Candidate(this.selectedCandidate)).fullName();

				this.selectedTab = selectedTab;

				this.selectedCandidate.training_changes.forEach(function(y) {
					if (y.is_processed) {
						y.status = "Verwerkt in rooster";
					} else if (y.is_accepted_by_trainer && y.is_accepted_by_trainee) {
						y.status = "Wacht op verwerking in rooster";
					} else if (y.is_rejected_by_trainer) {
						y.status = "Afgewezen door opleider";
					} else if (y.is_rejected_by_trainee) {
						y.status = "Afgewezen door AIOS";
					} else if (y.is_accepted_by_trainer) {
						y.status = "Wacht op akkoord AIOS";
					} else if (y.is_accepted_by_trainee) {
						y.status = "Wacht op akkoord opleider";
					} else {
						y.status = "Wacht op akkoord AIOS en opleider";
					}

					y.training_change_parts.forEach(function(w) {
						w.is_sub_type = w.sub_type_id != null,
						w.subTypeName = w.sub_type_id ? self.subTypes.filter(function(z) { return z.id == w.sub_type_id; })[0].name : null;
						w.computedEndDate = w.end_date ? moment(w.end_date).subtract(1, 'day') : null;
					});
				});

				this.openTrainingChangePartsForCandidate = [];
				this.selectedCandidate.training_changes.filter(function(x) { return x.candidate_id == self.selectedCandidate.id && !x.is_processed && !x.is_rejected_by_trainee && !x.is_rejected_by_trainer; }).forEach(function(trainingChange) {
					self.openTrainingChangePartsForCandidate = self.openTrainingChangePartsForCandidate.concat(trainingChange.training_change_parts);
				});

				this.addTrainingPeriodAccumulationTypeTargetsAndBalances();
				
				this.changedTrainingAccumulationTypeIds = [];

				this.http.get("training_periods/get_for_training",
					{
						params: {
							candidate_id: candidateId 
						}
					}
				).subscribe(x => {
					this.trainingPeriods = x;

					this.trainingPeriods.forEach(function(y) {
						y.is_sub_type = (y.sub_type_id != null);
					});

					this.selectedCandidate.candidate_unavailability_periods_for_training_schedule_version.forEach(function(candidateUnavailabilityPeriod) {
						var index = self.trainingPeriods.length;

						for (var i = 0; i < self.trainingPeriods.length; i++) {
							if (candidateUnavailabilityPeriod.start_date < self.trainingPeriods[i].start_date) {
								index = i;
								break;
							}
						}

						var candidateUnavailabilityPeriodTrainingPeriod = {
							is_candidate_unavailability_period: true,
							description: candidateUnavailabilityPeriod.description,
							candidate_id: self.selectedCandidate.id,
							start_date: candidateUnavailabilityPeriod.start_date,
							end_date: candidateUnavailabilityPeriod.end_date,
							id: candidateUnavailabilityPeriod.id,
							is_locked: true,
							location_id: null,
							preferred_location_id: null,
							preferred_order: null,
							length: moment(candidateUnavailabilityPeriod.end_date).diff(moment(candidateUnavailabilityPeriod.start_date), 'weeks'),
							ratio: null,
							training_period_accumulation_type: null,
							training_period_accumulation_type_id: null,
							training_change_part_id: null
						};

						self.trainingPeriods.splice(index, 0, candidateUnavailabilityPeriodTrainingPeriod);
					});

					// 3. training_period_length
					var openTrainingChangePartsType3 = this.openTrainingChangePartsForCandidate.filter(function(x) { return x.training_change_part_type_id == 3 });

					this.trainingPeriods.forEach(function(y) {
						y.computedEndDate = moment(y.end_date).subtract(1, 'day');
						y.subTypeName = y.sub_type_id ? self.subTypes.filter(function(z) { return z.id == y.sub_type_id; })[0].name : null;

						y.currentLength = y.length;
						y.currentPreferredOrder = y.preferred_order;
						y.currentIsBindingOrderPreference = y.is_binding_order_preference;
						y.currentPreferredLocationId = y.preferred_location_id;
						y.currentPreferredLocation = y.preferred_location;
						y.currentIsBindingLocationPreference = y.is_binding_location_preference;
						y.currentRatio = y.ratio;
						
						openTrainingChangePartsType3.filter(function(z) { return z.training_period_id == y.id }).forEach(function(trainingChangePart) {
							y.length = trainingChangePart.value;
							y.lengthChanged = true;

							self.changedTrainingAccumulationTypeIds.push(y.training_period_accumulation_type_id);
						});
					});

					// 4. add training_period
					var openTrainingChangePartsType4 = this.openTrainingChangePartsForCandidate.filter(function(x) { return x.training_change_part_type_id == 4 });

					openTrainingChangePartsType4.forEach(function(trainingChangePart) {
						var index = self.trainingPeriods.length;

						if (trainingChangePart.sub_type_id) {
							for (var i = 0; i < self.trainingPeriods.length; i++) {
								if (trainingChangePart.start_date <= self.trainingPeriods[i].start_date
								) {
									index = i;
									break;
								}
							}
						} else if (trainingChangePart.preferred_order) {
							for (var i = 0; i < self.trainingPeriods.length; i++) {
								if (!self.trainingPeriods[i].is_locked && 
									trainingChangePart.preferred_order < self.trainingPeriods[i].preferred_order
								) {
									index = i;
									break;
								}
							}
						}
						
						var isSubType = trainingChangePart.sub_type_id != null;
						var newTrainingPeriod = null;

						if (isSubType) {
							newTrainingPeriod = {
								candidate_id: self.selectedCandidate.id,
								is_sub_type: true,
								sub_type_id: trainingChangePart.sub_type_id,
								subTypeName: self.subTypes.filter(function(z) { return z.id == trainingChangePart.sub_type_id; })[0].name,
								start_date: trainingChangePart.start_date,
								end_date: trainingChangePart.end_date,
								computedEndDate: moment(trainingChangePart.end_date).subtract(1, 'day'),
								id: null,
								is_locked: false,
								location_id: trainingChangePart.location_id,
								location: trainingChangePart.location,
								preferred_location_id: null,
								preferred_order: null,
								length: trainingChangePart.value,
								ratio: trainingChangePart.ratio,
								training_period_accumulation_type: trainingChangePart.training_period_accumulation_type,
								training_period_accumulation_type_id: trainingChangePart.training_period_accumulation_type_id,
								training_change_part_id: trainingChangePart.id
							};
		
						} else {
							newTrainingPeriod = {
								candidate_id: self.selectedCandidate.id,
								start_date: null,
								end_date: null,
								id: null,
								is_locked: false,
								location_id: null,
								preferred_location_id: trainingChangePart.preferred_location_id,
								preferred_location: trainingChangePart.preferred_location,
								is_binding_location_preference: trainingChangePart.is_binding_location_preference,
								preferred_order: trainingChangePart.preferred_order,
								is_binding_order_preference: trainingChangePart.is_binding_order_preference,
								length: trainingChangePart.value,
								ratio: trainingChangePart.ratio,
								training_period_accumulation_type: trainingChangePart.training_period_accumulation_type,
								training_period_accumulation_type_id: trainingChangePart.training_period_accumulation_type_id,
								training_change_part_id: trainingChangePart.id,
							};
		
						}

						self.trainingPeriods.splice(index, 0, newTrainingPeriod);
					});

					// 5. delete training_periods
					var openTrainingChangePartsType5 = this.openTrainingChangePartsForCandidate.filter(function(x) { return x.training_change_part_type_id == 5 });

					openTrainingChangePartsType5.forEach(function(trainingChangePart) {
						var trainingPeriod = self.trainingPeriods.filter(function(x) { return x.id == trainingChangePart.training_period_id })[0];

						trainingPeriod.is_removed = true;
						trainingPeriod.training_change_part_id = trainingChangePart.id;
					});

					// 6. add candidate_unavailability_period
					var openTrainingChangePartsType6 = this.openTrainingChangePartsForCandidate.filter(function(x) { return x.training_change_part_type_id == 6 });

					openTrainingChangePartsType6.forEach(function(trainingChangePart) {
						var index = self.trainingPeriods.length;

						for (var i = 0; i < self.trainingPeriods.length; i++) {
							if (trainingChangePart.start_date < self.trainingPeriods[i].start_date) {
								index = i;
								break;
							}
						}

						var candidateUnavailabilityPeriodTrainingPeriod = {
							is_candidate_unavailability_period: true,
							description: trainingChangePart.description,
							candidate_id: self.selectedCandidate.id,
							start_date: trainingChangePart.start_date,
							end_date: trainingChangePart.end_date,
							computedEndDate: moment(trainingChangePart.end_date).subtract(1, 'day'),
							id: null,
							is_locked: false,
							location_id: null,
							preferred_location_id: null,
							preferred_order: null,
							length: moment(trainingChangePart.end_date).diff(moment(trainingChangePart.start_date), 'weeks'),
							ratio: null,
							training_period_accumulation_type: null,
							training_period_accumulation_type_id: null,
							training_change_part_id: trainingChangePart.id
						};

						self.trainingPeriods.splice(index, 0, candidateUnavailabilityPeriodTrainingPeriod);
					});

					// 7. delete candidate_unavailability_period
					var openTrainingChangePartsType7 = this.openTrainingChangePartsForCandidate.filter(function(x) { return x.training_change_part_type_id == 7 });

					openTrainingChangePartsType7.forEach(function(trainingChangePart) {
						var trainingPeriod = self.trainingPeriods.filter(function(x) { return x.id == trainingChangePart.candidate_unavailability_period_id })[0];

						trainingPeriod.is_removed = true;
						trainingPeriod.training_change_part_id = trainingChangePart.id;
					});

					// 8. preferred_order + is_binding_order_preference
					var openTrainingChangePartsType8 = this.openTrainingChangePartsForCandidate.filter(function(x) { return x.training_change_part_type_id == 8 });

					openTrainingChangePartsType8.forEach(function(trainingChangePart) {
						var trainingPeriod = self.trainingPeriods.filter(function(x) { return x.id == trainingChangePart.training_period_id })[0];

						trainingPeriod.preferred_order = trainingChangePart.preferred_order;
						trainingPeriod.is_binding_order_preference = trainingChangePart.is_binding_order_preference;
						trainingPeriod.preferredOrderChanged = true;
					});

					// 9. preferred_location + is_binding_location_preference
					var openTrainingChangePartsType9 = this.openTrainingChangePartsForCandidate.filter(function(x) { return x.training_change_part_type_id == 9 });

					openTrainingChangePartsType9.forEach(function(trainingChangePart) {
						var trainingPeriod = self.trainingPeriods.filter(function(x) { return x.id == trainingChangePart.training_period_id })[0];

						trainingPeriod.preferred_location_id = trainingChangePart.preferred_location_id;
						trainingPeriod.preferred_location = trainingChangePart.preferred_location;
						trainingPeriod.is_binding_location_preference = trainingChangePart.is_binding_location_preference;
						trainingPeriod.preferredLocationChanged = true;
						trainingPeriod.preferredLocationDisabled = true;
					});

					// 10. ratio
					var openTrainingChangePartsType10 = this.openTrainingChangePartsForCandidate.filter(function(x) { return x.training_change_part_type_id == 10 });

					openTrainingChangePartsType10.forEach(function(trainingChangePart) {
						var trainingPeriod = self.trainingPeriods.filter(function(x) { return x.id == trainingChangePart.training_period_id })[0];

						trainingPeriod.ratio = trainingChangePart.ratio;
						trainingPeriod.ratioChanged = true;
					});

					// Don't allow changes for training periods of changed training_period_accumulation_types 
					// (other training_period with same training_period_accumulation_type has changed)
					this.trainingPeriods.forEach(function(y) {
						if (!y.id || self.changedTrainingAccumulationTypeIds.indexOf(y.training_period_accumulation_type_id) >= 0) {
							y.lengthAndRatioDisabled = true;
						}

						if (openTrainingChangePartsType8.length > 0) {
							y.preferredOrderDisabled = true;
						}
					});

					this.reorderAndSetNewDates();
					

					this.isLoading = false;
				});
			
			});
		}
    }

	// same as reorderAndSetNewDates in trainingCandidateForm.component.ts: make DRY!
	reorderAndSetNewDates() {
		var self = this;

		var newTrainingPeriods = []

		this.trainingPeriods.sort(function(a, b) { return (!a.start_date || moment(a.start_date) > moment(b.start_date)) ? 1 : -1 });
		var i = 1;
		this.trainingPeriods.forEach(function(tp) {
			tp.tempId = i;
			i++;
		});

		var currentDate = moment(this.trainingPeriods[0].start_date);

		while(this.trainingPeriods.length > 0) {

			var newTrainingPeriod;

			var unavailabilityPeriodsOrSubTypes = this.trainingPeriods.filter(function(x) { 
				return (x.is_candidate_unavailability_period || x.is_sub_type) && moment(x.start_date) <= moment(currentDate);
			});

			if (unavailabilityPeriodsOrSubTypes.length > 0) {
				unavailabilityPeriodsOrSubTypes.sort(function(a, b) { return (moment(a.start_date) > moment(b.start_date)) ? 1 : -1 });

				unavailabilityPeriodsOrSubTypes.forEach(function(tp) {
					newTrainingPeriod = self.trainingPeriods.splice(self.trainingPeriods.map(function(y) { return y.tempId; }).indexOf(tp.tempId), 1)[0];
					newTrainingPeriod.new_start_date = newTrainingPeriod.start_date;
					newTrainingPeriod.new_end_date = moment(newTrainingPeriod.end_date).subtract(1, 'day');
					newTrainingPeriods.push(newTrainingPeriod);
					if (!newTrainingPeriod.is_removed && newTrainingPeriod.start_date) {
						currentDate = moment(newTrainingPeriod.end_date);
					}
				});
			} else {
				var fixedOrUnfixedTrainingPeriods = this.trainingPeriods.filter(function(x) { 
					return self.trainingPeriods[0].is_binding_order_preference ? 
						x.preferred_order && x.preferred_order < self.trainingPeriods[0].preferred_order
						:
						x.is_binding_order_preference && x.preferred_order <= self.trainingPeriods[0].preferred_order;
				});

				if (fixedOrUnfixedTrainingPeriods.length > 0) {
					fixedOrUnfixedTrainingPeriods.sort(function(a, b) { return a.preferred_order > b.preferred_order ? 1 : -1 });

					newTrainingPeriod = this.trainingPeriods.splice(this.trainingPeriods.map(function(y) { return y.tempId; }).indexOf(fixedOrUnfixedTrainingPeriods[0].tempId), 1)[0];
					newTrainingPeriod.new_start_date = currentDate;
					var newCurrentDate = moment(currentDate).add(newTrainingPeriod.length, 'weeks');
					newTrainingPeriod.new_end_date = moment(newCurrentDate).subtract(1, 'day');
					newTrainingPeriods.push(newTrainingPeriod);
					if (!newTrainingPeriod.is_removed) {
						currentDate = moment(newCurrentDate);
					}

				} else {

					var futureUnavailabilityPeriodsOrSubTypes = this.trainingPeriods.filter(function(x) { 
						return (x.start_date && x.end_date && (x.is_candidate_unavailability_period || x.is_sub_type) && moment(x.start_date) > moment(currentDate));
					});

					if (futureUnavailabilityPeriodsOrSubTypes.length > 0) {
						futureUnavailabilityPeriodsOrSubTypes.sort(function(a, b) { return (moment(a.start_date) > moment(b.start_date)) ? 1 : -1 });

						var startDateFirstFutureUnavailabilityPeriodOrSubType = futureUnavailabilityPeriodsOrSubTypes[0].start_date;
						var trainingPeriodFound = false;

						this.trainingPeriods.filter(function(x) { return !(x.is_candidate_unavailability_period || x.is_sub_type); }).forEach(function(trainingPeriod) {

							if (
								!trainingPeriodFound && moment(currentDate).add(
									parseInt(self.trainingPeriods.filter(function(x) { return !(x.is_candidate_unavailability_period || x.is_sub_type) && !x.is_removed && x.preferred_order && x.preferred_order < trainingPeriod.preferred_order; })
										.reduce(function(acc, tp) { return acc + tp.length; }, 0)) + parseInt(trainingPeriod.length), 'weeks'
								) <= moment(startDateFirstFutureUnavailabilityPeriodOrSubType)
							) {
								newTrainingPeriod = self.trainingPeriods.splice(self.trainingPeriods.map(function(y) { return y.tempId; }).indexOf(trainingPeriod.tempId), 1)[0];
								newTrainingPeriod.new_start_date = currentDate;
								var newCurrentDate = moment(currentDate).add(newTrainingPeriod.length, 'weeks');
								newTrainingPeriod.new_end_date = moment(newCurrentDate).subtract(1, 'day');
								newTrainingPeriods.push(newTrainingPeriod);
								if (!newTrainingPeriod.is_removed) {
									currentDate = moment(newCurrentDate);
								}
								trainingPeriodFound = true;
							}
						});

						if (!trainingPeriodFound) {
							console.error("Training period niet gevonden");

							newTrainingPeriod = this.trainingPeriods.splice(0, 1)[0];
							newTrainingPeriod.new_start_date = currentDate;
							var newCurrentDate = moment(currentDate).add(newTrainingPeriod.length, 'weeks');
							newTrainingPeriod.new_end_date = moment(newCurrentDate).subtract(1, 'day');
							newTrainingPeriods.push(newTrainingPeriod);
							if (!newTrainingPeriod.is_removed) {
								currentDate = moment(newCurrentDate);
							}
						}
					} else {
						newTrainingPeriod = this.trainingPeriods.splice(0, 1)[0];
						newTrainingPeriod.new_start_date = currentDate;
						var newCurrentDate = moment(currentDate).add(newTrainingPeriod.length, 'weeks');
						newTrainingPeriod.new_end_date = moment(newCurrentDate).subtract(1, 'day');
						newTrainingPeriods.push(newTrainingPeriod);
						if (!newTrainingPeriod.is_removed) {
							currentDate = moment(newCurrentDate);
						}
					}
				}

				newTrainingPeriod.start_date_changed = moment(newTrainingPeriod.new_start_date).format('YYYY-MM-DD') != moment(newTrainingPeriod.start_date).format('YYYY-MM-DD');
				newTrainingPeriod.end_date_changed = moment(newTrainingPeriod.new_end_date).format('YYYY-MM-DD') != moment(newTrainingPeriod.computedEndDate).format('YYYY-MM-DD');
			}
		}

		this.trainingPeriods = newTrainingPeriods;
	}

    changeTab(tabNumber) {
	    this.selectedTab = tabNumber;
	}

    addTrainingPeriodAccumulationTypeTargetsAndBalances() {
		var self = this;

        var openTrainingChangePartsType1 = this.openTrainingChangePartsForCandidate.filter(function(x) { return x.training_change_part_type_id == 1 });
        var openTrainingChangePartsType2 = this.openTrainingChangePartsForCandidate.filter(function(x) { return x.training_change_part_type_id == 2 });

		this.http.get("training_period_accumulation_types").subscribe(trainingPeriodAccumulationTypes => {
			this.trainingPeriodAccumulationTypes = trainingPeriodAccumulationTypes;
			
			this.selectedCandidate.trainingPeriodAccumulationTypes = {};

			this.trainingPeriodAccumulationTypes.forEach(function(x) {
				var trainingPeriodAccumulationTypeTargets = self.selectedCandidate.training_period_accumulation_type_targets.filter(function(y) { return y.training_period_accumulation_type_id == x.id });
				var trainingPeriodAccumulationTypeTarget;
				
				if (trainingPeriodAccumulationTypeTargets.length == 0) {
					trainingPeriodAccumulationTypeTarget = new TrainingPeriodAccumulationTypeTarget({ 
						training_period_accumulation_type_id: x.id, candidate_id: self.selectedCandidate.id, value: null });
					self.selectedCandidate.training_period_accumulation_type_targets.push(trainingPeriodAccumulationTypeTarget);
				} else {
					trainingPeriodAccumulationTypeTarget = trainingPeriodAccumulationTypeTargets[0];
				}
				
				var trainingPeriodAccumulationTypeBalances = self.selectedCandidate.training_period_accumulation_type_balances.filter(function(y) { return y.training_period_accumulation_type_id == x.id });
				var trainingPeriodAccumulationTypeBalance;
				
				if (trainingPeriodAccumulationTypeBalances.length == 0) {
					trainingPeriodAccumulationTypeBalance = new TrainingPeriodAccumulationTypeBalance({ 
						training_period_accumulation_type_id: x.id, candidate_id: self.selectedCandidate.id, value: null });
					self.selectedCandidate.training_period_accumulation_type_balances.push(trainingPeriodAccumulationTypeBalance);
				} else {
					trainingPeriodAccumulationTypeBalance = trainingPeriodAccumulationTypeBalances[0];
				}

				var targetValue = trainingPeriodAccumulationTypeTarget.value;
				var currentTargetValue = trainingPeriodAccumulationTypeTarget.value;
				var targetValueChanged = false;
				
				openTrainingChangePartsType1.filter(function(y) { return y.training_period_accumulation_type_id == x.id }).forEach(function(trainingChangePart) {
					targetValue = trainingChangePart.value;
					targetValueChanged = true;
                });

				var balance = trainingPeriodAccumulationTypeBalance.value;
				var currentBalance = trainingPeriodAccumulationTypeBalance.value;
				var balanceChanged = false;

                openTrainingChangePartsType2.filter(function(y) { return y.training_period_accumulation_type_id == x.id }).forEach(function(trainingChangePart) {
                    balance = trainingChangePart.value;
					balanceChanged = true;
                });
				
				self.selectedCandidate.trainingPeriodAccumulationTypes[x.id] = {
					currentTargetValue: Math.round(currentTargetValue * 10) / 10,  
					targetValue: Math.round(targetValue * 10) / 10,
					targetValueChanged: targetValueChanged,
					currentBalance: Math.round(currentBalance * 10) / 10,
					balance: Math.round(balance * 10) / 10,
					balanceChanged: balanceChanged,
					computedBalance: balance ? balance : 0
				};
			});

			this.http.get("overall_training_period_accumulation_types").subscribe(overallTrainingPeriodAccumulationTypes => {
				this.overallTrainingPeriodAccumulationTypes = overallTrainingPeriodAccumulationTypes;
				
				this.refreshOverallTrainingPeriodAccumulationTypeCountsAndTotals();
			});
		});
    }
    
    refreshOverallTrainingPeriodAccumulationTypeCountsAndTotals() {
		var self = this;
		this.overallTrainingPeriodAccumulationTypes.forEach(function(x) {
			x.total = 0;
		});
		
		this.trainingPeriodAccumulationTypes.forEach(function(x) {

			var overallTrainingPeriodAccumulationType = 
				self.overallTrainingPeriodAccumulationTypes.filter(function(y) { return x.overall_training_period_accumulation_type_id == y.id; })[0];
			
			if (overallTrainingPeriodAccumulationType != null && overallTrainingPeriodAccumulationType != undefined && 
					self.selectedCandidate.trainingPeriodAccumulationTypes && self.selectedCandidate.trainingPeriodAccumulationTypes[x.id] && self.selectedCandidate.trainingPeriodAccumulationTypes[x.id].targetValue != null && self.selectedCandidate.trainingPeriodAccumulationTypes[x.id].targetValue != "") {
				overallTrainingPeriodAccumulationType.total += parseFloat(self.selectedCandidate.trainingPeriodAccumulationTypes[x.id].targetValue);
			}
		});

		this.overallTrainingPeriodAccumulationTypes.forEach(function(x) {
			x.total = Math.round(x.total * 10) / 10;
			x.totalInMonths = Math.round(x.total / 4.345 * 10) / 10;
		});
		
		this.refreshOverallTrainingPeriodAccumulationTypeTotals();
    }
    
   	refreshOverallTrainingPeriodAccumulationTypeTotals() {
		var self = this;

		if (this.selectedCandidate.trainingPeriodAccumulationTypes) {
			this.totalTargetValues = Object.keys(this.selectedCandidate.trainingPeriodAccumulationTypes).filter(
				function(x) { return self.selectedCandidate.trainingPeriodAccumulationTypes[x].targetValue != null && self.selectedCandidate.trainingPeriodAccumulationTypes[x].targetValue != "" }).map(
				function(x) { return self.selectedCandidate.trainingPeriodAccumulationTypes[x].targetValue }).reduce(
				function(acc, val) { return acc + parseFloat(val); }, 0);
	
			this.totalBalances = Object.keys(this.selectedCandidate.trainingPeriodAccumulationTypes).filter(
				function(x) { return self.selectedCandidate.trainingPeriodAccumulationTypes[x].balance != null && self.selectedCandidate.trainingPeriodAccumulationTypes[x].balance != "" }).map(
				function(x) { return self.selectedCandidate.trainingPeriodAccumulationTypes[x].balance }).reduce(
				function(acc, val) { return acc + parseFloat(val); }, 0);	
		}
    }
    
    edit() {
		var bsModalRef = this.bsModalService.show(TrainingCandidateFormComponent, {
            initialState: {
                selectedCandidate: this.selectedCandidate,
                trainingPeriodAccumulationTypes: this.trainingPeriodAccumulationTypes,
				originalTrainingPeriods: this.trainingPeriods,
				subTypes: this.subTypes,
				tabIndex: this.selectedTab == 3 ? 2 : 1
            },
            class: 'xxlModal'
        });

		this.bsModalService.onHide.subscribe(result => {
			this.isLoading = true;
			this.refresh();
			this.show(this.selectedCandidate.id, 3);
		});

        bsModalRef.content.action.subscribe(result => {
			if (result.length == 0) {
				this.toastr.warning("Geen wijzigingen gevonden");
			} else {
				var bsModalRef2 = this.bsModalService.show(TrainingChangeDetailsFormComponent, {
					initialState: {
						trainingChangeIds: result
						// selectedCandidate: this.selectedCandidate,
						// trainingPeriodAccumulationTypes: this.trainingPeriodAccumulationTypes,
						// trainingPeriods: this.trainingPeriods,
						// subTypes: this.subTypes
					},
					class: 'xxlModal'
				});
		
				bsModalRef2.content.action.subscribe(result => {
					this.isLoading = true;
					this.refresh();
					this.show(this.selectedCandidate.id, 3);
				});	
			}
        });
	}

	delete(candidateId) {
		if (window.confirm('Weet je het zeker?')) {
			this.isLoading = true;
            
            this.http.delete("candidates/" + candidateId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.isLoading = false;
				this.toastr.error(response.data.error);
			});
		}
    }
    
    deleteTrainingChange(trainingChangeId) {
		if (window.confirm('Weet je het zeker?')) {
			this.http.delete("training_changes/" + trainingChangeId).subscribe(() => {
				this.refresh();
				this.environmentService.refreshTotalOpenTrainingChangesCount();
				this.show(this.selectedCandidate.id, 5);
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
	}

	approveTrainingChange(trainingChange) {
		if (trainingChange.candidate_id == this.user.regionalCandidateId) {
			trainingChange.is_accepted_by_trainee = true;
		} else if ((this.user.isTrainingAdmin && !this.user.hasOnlyAccessToOwnTraineesAndLocation)) {
			trainingChange.is_accepted_by_trainer = true;
		}

		this.http.put("training_changes/" + trainingChange.id, trainingChange).subscribe(() => {
			this.toastr.warning("De wijziging is succesvol geaccepteerd");
			this.refresh();
			this.environmentService.refreshTotalOpenTrainingChangesCount();
			this.show(this.selectedCandidate.id, 5);
		}, (response) => {
			this.toastr.error(response.data.error);
		});
	}

	rejectTrainingChange(trainingChange) {

		var bsModalRef = this.bsModalService.show(TrainingChangeTrainerCommentComponent, {
			initialState: {
			},
		});

		bsModalRef.content.action.subscribe(result => {
			trainingChange.trainer_comment = result;

			if (trainingChange.candidate_id == this.user.candidateId) {
				trainingChange.is_rejected_by_trainee = true;
			} else if ((this.user.isTrainingAdmin && !this.user.hasOnlyAccessToOwnTraineesAndLocation)) {
				trainingChange.is_rejected_by_trainer = true;
			}
	
			this.http.put("training_changes/" + trainingChange.id, trainingChange).subscribe(() => {
				this.toastr.warning("De wijziging is afgewezen");
				this.refresh();
				this.environmentService.refreshTotalOpenTrainingChangesCount();
				this.show(this.selectedCandidate.id, 5);
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        });
	}

	undoApprovalTrainingChange(trainingChange) {
		if (trainingChange.candidate_id == this.user.candidateId) {
			trainingChange.is_accepted_by_trainee = false;
		} else if ((this.user.isTrainingAdmin && !this.user.hasOnlyAccessToOwnTraineesAndLocation)) {
			trainingChange.is_accepted_by_trainer = false;
		}

		this.http.put("training_changes/" + trainingChange.id, trainingChange).subscribe(() => {
			this.toastr.warning("De acceptatie van de wijziging is ongedaan gemaakt");
			this.refresh();
			this.environmentService.refreshTotalOpenTrainingChangesCount();
			this.show(this.selectedCandidate.id, 5);
		}, (response) => {
			this.toastr.error(response.data.error);
		});
	}

	undoRejectionTrainingChange(trainingChange) {
		if (trainingChange.candidate_id == this.user.candidateId) {
			trainingChange.is_rejected_by_trainee = false;
		} else if ((this.user.isTrainingAdmin && !this.user.hasOnlyAccessToOwnTraineesAndLocation)) {
			trainingChange.is_rejected_by_trainer = false;
		}

		this.http.put("training_changes/" + trainingChange.id, trainingChange).subscribe(() => {
			this.toastr.warning("De afwijzing van de wijziging is ongedaan gemaakt");
			this.refresh();
			this.environmentService.refreshTotalOpenTrainingChangesCount();
			this.show(this.selectedCandidate.id, 5);
		}, (response) => {
			this.toastr.error(response.data.error);
		});
	}
	
	editInline(trainingPeriod) {
		trainingPeriod.isEdit = true;
	}

	saveInline(trainingPeriod) {
		this.http.put("training_periods/" + trainingPeriod.id, trainingPeriod).subscribe((result) => {
			this.isLoading = false;
			this.toastr.success("Stage opgeslagen");
			trainingPeriod.isEdit = false;
		}, (response) => {
			this.isLoading = false;
			this.toastr.error(response.data.error);
		});
	}

	initialize() {
		this.isLoading = true;
        
        this.user = this.environmentService.getUser();

		this.weekdays = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];
		this.workWeekdays = this.weekdays.slice(1, 6);

        this.trainingChangePartTypes = [
			"Duur stagetype opleidingsplan", 
			"Startbalans stagetype opleidingsplan", 
			"Duur stage", 
			"Nieuwe stage", 
			"Verwijderde stage", 
			"Nieuwe afwezigheid", 
			"Verwijderde afwezigheid", 
			"Volgordevoorkeur", 
			"Locatievoorkeur",
			"FTE"
		];

		this.subTypes = [
			{ id: 1, name: "1. Acute neurologie" },
			{ id: 2, name: "2. Cerebrovasculaire aandoeningen" },
			{ id: 3, name: "3a. Intensive care & Medium care" },
			{ id: 4, name: "3b. Intensive care volwassenen" },
			{ id: 5, name: "4a. Bewegingsstoornissen AMC" },
			{ id: 6, name: "4b. Bewegingsstoornissen VUmc" },
			{ id: 7, name: "5. Neuropsychiatrie" },
			{ id: 8, name: "6. SOLK en somatisch-psychiatrische comorbiditeit" },
			{ id: 9, name: "7. Cognitieve neurologie" },
			{ id: 10, name: "8. Neurodegeneratieve ziekten: bewegingsstoornissen en cognitieve neurologie" },
			{ id: 11, name: "9. Multiple sclerose perifeer in combinatie met algemene polikliniek" },
			{ id: 12, name: "10. Multipele sclerose academisch" },
			{ id: 13, name: "11. Neuro-immunologische en neurologische infectieziekten" },
			{ id: 14, name: "12. Neuro-oncologie" },
			{ id: 15, name: "13. Neuromusculaire ziekten" },
			{ id: 16, name: "14. Klinische epileptologie" },
			{ id: 17, name: "15. Epilepsiechirurgie" },
			{ id: 18, name: "16. Ouderen, cognitieve stoornissen, bewegingsstoornissen en vallen" },
			{ id: 19, name: "17. Polikliniek algemene neurologie" },
			{ id: 20, name: "18. Rug- en radiculaire pijn" },
			{ id: 21, name: "19. Slaapgeneeskunde" },
			{ id: 22, name: "20. KNF perifeer" },
			{ id: 23, name: "21. KNF academisch" },
			{ id: 24, name: "22. KNF epilepsie en het EEG" },
			{ id: 25, name: "23. Leiderschap en management in combinatie met geïndividualiseerde polikliniek stage" },
			{ id: 26, name: "24. Leiderschap en opleiding in combinatie met geïndividualiseerde polikliniek stage" },
			{ id: 27, name: "25. Leiderschap en innovatie in combinatie met geïndividualiseerde polikliniek stage" },
			{ id: 28, name: "Onderwijs" },
			{ id: 29, name: "Overig" },
		];

		if (this.user.location == null) {
			this.filterOptions = [
				{ id: 1, title: " " },
				{ id: 2, title: "Alle" }
			];

			this.filterOptionId = 2;
		} else {
			this.filterOptions = [
				{ id: 1, title: this.user.location.name },
				{ id: 2, title: "Alle" }
			];

			this.filterOptionId = 1;
		}

		this.refresh();
	}
	
    ngOnInit() {
        this.initialize();
    }
}
