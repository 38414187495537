import { Component, OnInit } from '@angular/core';
import templateString from './otherUsers.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { OtherUserFormComponent } from '../otherUserForm';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProfileFormComponent } from 'site/app/profile/profileForm';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class OtherUsersComponent implements OnInit {
    public destroyed$: any;
    public schedulePeriods: any;
    public users: any;
    public currentUser: any;
    public isLoading: boolean;
    public selectedSchedulePeriod: any;
    public isTestEnvironment: boolean;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }
    
	refresh() {
        this.http.get("other_users", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
            this.users = x;
        })
	}

    create() {
        var bsModalRef = this.bsModalService.show(OtherUserFormComponent, {
            initialState: {
                userId: null,
                schedulePeriod: this.selectedSchedulePeriod
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }
	
	sendRegistrationMail(userId) {
		if (window.confirm('Weet je het zeker?')) {
            this.http.post("other_users/send_registration_mail", { user_id: userId }).subscribe(() => {
			    this.refresh();
			});
		}
	}

    edit(userId) {
        var bsModalRef = this.bsModalService.show(OtherUserFormComponent, {
            initialState: {
                userId: userId,
                schedulePeriod: this.selectedSchedulePeriod
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

	delete(userId) {
		if (window.confirm('Weet je het zeker?')) {
            this.http.delete("other_users/" + userId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
		}
    }
    
    editProfile(userId) {
        this.isLoading = true;

		this.http.get("other_users/get_for_profile",
			{
                params: {
                    id: userId, 
                    schedule_period_id: this.selectedSchedulePeriod.id    
                }
			}
		).subscribe(x => {
			var extendedUser = x;

            var bsModalRef = this.bsModalService.show(ProfileFormComponent, {
                initialState: {
                    originalExtendedUser: extendedUser,
                    schedulePeriodId: this.selectedSchedulePeriod.id
                },
                class: 'xlModal'
            });
    
            bsModalRef.content.action.subscribe(result => {
                this.refresh();
            });

			this.isLoading = false;
		});
    }

    setPasswordForTest(userId) {
        this.http.post("other_users/set_password_for_test", { user_id: userId }).subscribe(() => {
            this.toastr.success("Wachtwoord aangepast");
        });
    }

    initialize() {
		this.isLoading = true;
        
        this.isTestEnvironment = (window.location.host === 'localhost:3000');
        this.currentUser = this.environmentService.getUser();
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
