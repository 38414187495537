import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './trainingScheduleVersionEvaluationForm.component.html'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TrainingScheduleVersionEvaluation } from 'site/app/models/trainingScheduleVersionEvaluation.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TrainingScheduleVersionEvaluationFormComponent implements OnInit {
	@Output() action = new EventEmitter();
	
	public trainingScheduleVersionId;
	public lastTrainingScheduleVersionEvaluationForLocation;

	public isLoading;
	public submitted;
	public trainingScheduleVersionEvaluation: any;
	public evaluationStatuses: string[];
	public evaluationStatusButtonClasses: string[];
	public user: any;

	constructor(
		private renderer: Renderer2,
		private http: HttpClient,
		private environmentService: EnvironmentService,
		private bsModalRef: BsModalRef,
		private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	setEvaluationStatus(statusId) {
    	this.trainingScheduleVersionEvaluation.status_id = statusId;
  	}

	ok(isValid) {
		this.submitted = true;

		if (isValid) {
			this.isLoading = true;

			this.http.post("training_schedule_version_evaluations", this.trainingScheduleVersionEvaluation).subscribe(() => {
				this.isLoading = false;
				this.action.emit(true);
				this.bsModalRef.hide();
			}, (response) => {
				this.isLoading = false;
				this.toastr.error(response.data.error);
			});
		}
  	};

  	dismiss() {
		this.bsModalRef.hide();
  	}

	cancel() {
		this.bsModalRef.hide();
	}

	ngOnInit() {
		this.user = this.environmentService.getUser();

		this.evaluationStatuses = ["Geweigerd", "Niet verwerkt", "Geaccepteerd"];
		this.evaluationStatusButtonClasses = ["btn-danger", "btn-warning", "btn-primary"];

		this.trainingScheduleVersionEvaluation = new TrainingScheduleVersionEvaluation();
		this.trainingScheduleVersionEvaluation.training_schedule_version_id = this.trainingScheduleVersionId;
		this.trainingScheduleVersionEvaluation.status_id = this.lastTrainingScheduleVersionEvaluationForLocation.status_id;
		this.trainingScheduleVersionEvaluation.location_id = this.user.customer.regional_location_id;
		this.trainingScheduleVersionEvaluation.comment = this.lastTrainingScheduleVersionEvaluationForLocation.comment;
	}
}