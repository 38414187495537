import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './taskForm.component.html';
import { EnvironmentService } from 'site/app/environment.service';
import { Task } from 'site/app/models/task.model';
import { TaskDay } from 'site/app/models/taskDay.model';
import { Candidate } from 'site/app/models/candidate.model';
import { forkJoin } from 'rxjs';
import { TaskAllowedTask } from 'site/app/models/taskAllowedTask.model';
import { TaskAllowedTaskType } from 'site/app/models/taskAllowedTaskType.model';
import { TaskConflictException } from 'site/app/models/taskConflictException.model';
import { RecurringPeriod } from 'site/app/models/recurringPeriod.model';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'site/app/data.service';
import { TimeSlot } from 'site/app/models/timeSlot.model';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskFormComponent implements OnInit {
    @Output() action = new EventEmitter();

    public taskId;
    public schedulePeriodId;

    public isLoading;
    public customer;
    public weekdays;
    public weekdaysObjects;
    public showViolationHelp;
    public selectedTab;
    public task;
    public taskAllowedTaskIndex;
	public taskAllowedTaskTypeIndex;
    public taskConflictExceptionIndex;
    public taskTypes;
    public recurringPeriodIndex;
    public submitted: boolean;
    public formerRecurringPeriods: any;
    public formerIsIncludedInMainSchedule: any;
    public formerIsOpenedWhenPublicHoliday: any;
    public formerIsClosedWhenPublicHoliday: any;
    public actionTitle: string;
    public periodicCandidates: any;
    public locations: any;
    public tasksForAllowedTasksAndConflictExceptions: any;
	public taskTypesForAllowedTaskTypes: any;
    public taskVariantGroups: any;
	public colors: any;
	public timeSlots: any;
	public dayScheduleTabs: any;
	public taskDisplayGroups: any;
	public continuousPeriodsStartDate: any;
	public formerHasContinuousPeriods: any;
	public formerContinuousPeriodsStartDate: any;
	public formerContinuousPeriodLength: any;
	public formerContinuousPeriodIntervalLength: any;
	public user: any;
    
    constructor(
        private environmentService: EnvironmentService,
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private toastr: ToastrService,
		private dataService: DataService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeTab(tabNumber) {
	    this.selectedTab = tabNumber;
	}

	deleteTaskAllowedTask(index) {
		var matchIndex = -1;
		this.task.task_allowed_tasks.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.task.task_allowed_tasks.splice(matchIndex, 1);
	}
	
	addTaskAllowedTask() {
		this.task.task_allowed_tasks.push(new TaskAllowedTask({ index: this.taskAllowedTaskIndex++ }));
	}

	deleteTaskAllowedTaskType(index) {
		var matchIndex = -1;
		this.task.task_allowed_task_types.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.task.task_allowed_task_types.splice(matchIndex, 1);
	}
	
	addTaskAllowedTaskType() {
		this.task.task_allowed_task_types.push(new TaskAllowedTaskType({ index: this.taskAllowedTaskTypeIndex++ }));
	}

	deleteTaskConflictException(index) {
		var matchIndex = -1;
		this.task.task_conflict_exceptions.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.task.task_conflict_exceptions.splice(matchIndex, 1);;
	}
	
	addTaskConflictException() {
		this.task.task_conflict_exceptions.push(new TaskConflictException({ index: this.taskConflictExceptionIndex++ }));
	}

	changeTaskTypeId() {
        var self = this;
		this.task.task_type = (this.task.task_type_id == null || this.task.task_type_id == undefined) ? null : this.taskTypes.filter(function(x) { return x.id == self.task.task_type_id; })[0];
	}
	
	toggleViolationHelp() {
		this.showViolationHelp = !this.showViolationHelp;
	}
	
	setIsAllDay(taskDay, isAllDay) {
		if (isAllDay) {
			taskDay.start_time = null;
			taskDay.end_time = null;
		} else {
			// this is necessary because otherwise date of today will be the date part of the time,
			// and when it's daylight savings time the saved date will be one hour off
			taskDay.start_time = new Date(2000, 0, 1);
			taskDay.end_time = new Date(2000, 0, 1);
		}
	}
	
	setPublicHolidayIsAllDay(isAllDay) {
		if (isAllDay) {
			this.task.public_holiday_start_time = null;
			this.task.public_holiday_end_time = null;			
		} else {
			// this is necessary because otherwise date of today will be the date part of the time,
			// and when it's daylight savings time the saved date will be one hour off
			this.task.public_holiday_start_time = new Date(2000, 0, 1);
			this.task.public_holiday_end_time = new Date(2000, 0, 1);
		}
	}

	deleteRecurringPeriod(index) {
		var matchIndex = -1;
		this.task.recurring_periods.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.task.recurring_periods.splice(matchIndex, 1);;
	}
	
	addRecurringPeriod() {
        this.task.recurring_periods.push(new RecurringPeriod({ index: this.recurringPeriodIndex++, is_optional: false, is_cancelled: false }));
	}
	
	computeDisabledOfTaskDays() {
		if (this.task.has_continuous_periods) {
			this.task.task_days.forEach(function(taskDay) {
				taskDay.disabled = false;
			});
		} else {
			var validRecurringPeriods = this.task.recurring_periods.filter(function(x) { return x.start_day_of_week != null && x.end_day_of_week != null });

			this.task.task_days.forEach(function(taskDay) {
				taskDay.disabled = (
					validRecurringPeriods.filter(function(x) { return x.start_day_of_week <= x.end_day_of_week ? 
						taskDay.day_of_week >= x.start_day_of_week && taskDay.day_of_week <= x.end_day_of_week :
					  !(taskDay.day_of_week > x.end_day_of_week && taskDay.day_of_week < x.start_day_of_week) })
					.length == 0
				);
			});	
		}
	}
	
	goToFirstError() {
        var first = this.closestByClass(
            document.querySelector('form[name="taskForm"] input.ng-invalid, form[name="taskForm"] select.ng-invalid'),
            'tab-pane'
        );

		var firstId = first.id;
        if (firstId != undefined) {
			var tabNumber = parseInt(firstId.substring(4));
			this.changeTab(tabNumber);
		} else {
			first = this.closestByClass(
                document.querySelector('form[name="recurringPeriodForm"] input.ng-invalid, form[name="recurringPeriodForm"] select.ng-invalid'),
                'tab-pane'
            );
			firstId = first.id;
			if (firstId != undefined) {
			    this.changeTab(2);
			} else {
				this.changeTab(3);
			}
		}
    }
    
    closestByClass(el, className) {
	    while (!el.classList || !el.classList.contains(className)) {
	        el = el.parentNode;
	        if (!el) {
	            return null;
	        }
	    }

        return el;
	}

	changeTaskVariantGroupId() {
		var self = this;
		if (this.task.task_variant_group_id == null) {
			this.task.task_variant_group = null;
		} else {
			this.task.task_variant_group = this.taskVariantGroups.filter(function(x) { return x.id == self.task.task_variant_group_id; })[0];
		}
	}

	ok(isValid) {
		this.submitted = true;

		if (this.taskId === null) {
			this.isLoading = true;
			
			if (isValid) {
				this.task.schedule_period_id = this.schedulePeriodId;

				if (this.continuousPeriodsStartDate) {
					var continuousPeriodsStartDate = moment(this.continuousPeriodsStartDate);
					continuousPeriodsStartDate.locale('en');
					this.task.continuous_periods_start_date = continuousPeriodsStartDate.format("ddd D MMM YYYY");
				} else {
					this.task.continuous_periods_start_date = null;
				}

                this.http.post("tasks", this.task).subscribe(() => {
					this.isLoading = false;
                
                    this.action.emit(true);
					this.bsModalRef.hide();
				});
			} else {
				this.isLoading = false;
				this.goToFirstError();
			}
		} else {			
			if (isValid) {
				var newContinuousPeriodsStartDate = null;
				if (this.continuousPeriodsStartDate) {
					newContinuousPeriodsStartDate = moment(this.continuousPeriodsStartDate);
					newContinuousPeriodsStartDate.locale('en');
				}

                var hasChangesThatRequireRecreatingShifts = 
                    !_.isEqual(this.task.recurring_periods, this.formerRecurringPeriods) ||
					!_.isEqual(this.task.has_continuous_periods.toString(), this.formerHasContinuousPeriods.toString()) ||
					!_.isEqual(moment(newContinuousPeriodsStartDate).format('YYYY-MM-DD'), moment(this.formerContinuousPeriodsStartDate).format('YYYY-MM-DD')) ||
					!_.isEqual(this.task.continuous_period_length, this.formerContinuousPeriodLength) ||
					!_.isEqual(this.task.continuous_period_interval_length, this.formerContinuousPeriodIntervalLength) ||
					!_.isEqual(this.task.is_included_in_main_schedule, this.formerIsIncludedInMainSchedule) ||
					!_.isEqual(this.task.is_opened_when_public_holiday, this.formerIsOpenedWhenPublicHoliday) ||
					!_.isEqual(this.task.is_closed_when_public_holiday, this.formerIsClosedWhenPublicHoliday);
			
				if (!hasChangesThatRequireRecreatingShifts || 
					window.confirm('Weet je het zeker? Er zijn wijzigingen gemaakt (mbt \'herhaling\', \'in dagrooster\' en/of \'standaard open/gesloten tijdens feestdagen\') '+
						'waardoor alle taken en toewijzingen in het dagrooster behorende bij deze activiteit worden verwijderd en opnieuw aangemaakt.')
				) {
					this.isLoading = true;

					if (this.continuousPeriodsStartDate) {
						var continuousPeriodsStartDate = moment(this.continuousPeriodsStartDate);
						continuousPeriodsStartDate.locale('en');
						this.task.continuous_periods_start_date = continuousPeriodsStartDate.format("ddd D MMM YYYY");
					} else {
						this.task.continuous_periods_start_date = null;
					}

                    this.http.put("tasks/" + this.task.id, this.task).subscribe(() => {
						if (hasChangesThatRequireRecreatingShifts) {
                            this.http.get("tasks/reset_shifts", { params: { task_id: this.taskId } }).subscribe(() => {
								this.isLoading = false;
                                this.toastr.success("Toewijzingen in het rooster zijn succesvol verwijderd");
                                this.action.emit(true);
								this.bsModalRef.hide();
							}, () => {
                                this.isLoading = false;
                                this.action.emit(true);
								this.toastr.error("reset failed");
							});
						} else { 
                            this.isLoading = false;
                            this.action.emit(true);
							this.bsModalRef.hide();
						}
					});
				} else {
					this.isLoading = false;
				}
			} else {
				this.goToFirstError();
			}			
		}
    }

    cancel() {
        this.bsModalRef.hide();
	};
	
	trackByFunction(index) {
		return index;
	}

    ngOnInit() {
        var self = this;

        this.isLoading = true;
        this.customer = this.environmentService.getUser().customer;
        this.weekdays = ["maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag", "zondag"];
        this.weekdaysObjects = this.weekdays.map(function(x, index) { return { id: index + 1, name: x }; });
        this.showViolationHelp = false;
		this.colors = this.dataService.colors;
		this.user = this.environmentService.getUser();

        this.changeTab(1);

		this.http.get("day_schedule_tabs").subscribe(dayScheduleTabs => {
			this.dayScheduleTabs = dayScheduleTabs;

			if (this.taskId === null) {
				this.actionTitle = "toevoegen";
				this.task = new Task({ 
					is_included_in_main_schedule: true, 
					public_holiday_is_all_day: true, 
					is_side_activity: false, 
					has_selection_of_allowed_tasks: false,
					recurring_periods: [],
					task_days: [],
					task_allowed_tasks: [],
					task_allowed_task_types: [],
					task_conflict_exceptions: [],
					day_schedule_tab_ids: {}
				});
				
				this.weekdaysObjects.forEach(function(weekday) {
					self.task.task_days.push(new TaskDay({ 
						disabled: true, 
						day_of_week: weekday.id, 
						is_all_day: true, 
						start_time: null, 
						end_time: null
					}));
				})
				
				this.recurringPeriodIndex = 0;

				this.dayScheduleTabs.forEach(function(y) { 
					self.task.day_schedule_tab_ids[y.id] = true;
				});
			} else {
				this.actionTitle = "wijzigen";
				this.http.get<any>("tasks/" + this.taskId).subscribe(x => {
					this.task = x;
					this.task.day_schedule_tab_ids = {}

					x.recurring_periods.forEach(function(recurringPeriod, index) {
						recurringPeriod.index = index;
					});
					
					x.task_allowed_tasks.forEach(function(taskAllowedTask, index) {
						taskAllowedTask.index = index;
					});
					this.taskAllowedTaskIndex = x.task_allowed_tasks.length;

					x.task_allowed_task_types.forEach(function(taskAllowedTaskType, index) {
						taskAllowedTaskType.index = index;
					});
					this.taskAllowedTaskTypeIndex = x.task_allowed_task_types.length;
		
					x.task_conflict_exceptions.forEach(function(taskConflictException, index) {
						taskConflictException.index = index;
					});
					this.taskConflictExceptionIndex = x.task_conflict_exceptions.length;
					
					this.formerRecurringPeriods = JSON.parse(JSON.stringify(this.task.recurring_periods));
					this.formerHasContinuousPeriods = JSON.parse(JSON.stringify(this.task.has_continuous_periods));
					this.formerContinuousPeriodsStartDate = JSON.parse(JSON.stringify(this.task.continuous_periods_start_date));
					this.formerContinuousPeriodLength = JSON.parse(JSON.stringify(this.task.continuous_period_length));
					this.formerContinuousPeriodIntervalLength = JSON.parse(JSON.stringify(this.task.continuous_period_interval_length));
					this.formerIsIncludedInMainSchedule = this.task.is_included_in_main_schedule;
					this.formerIsOpenedWhenPublicHoliday = this.task.is_opened_when_public_holiday;
					this.formerIsClosedWhenPublicHoliday = this.task.is_closed_when_public_holiday;
		
					this.recurringPeriodIndex = x.recurring_periods.length;
					
					this.dayScheduleTabs.forEach(function(y) { 
						self.task.day_schedule_tab_ids[y.id] = self.task.day_schedule_tabs.map(function(z) { return z.id; }).indexOf(y.id) >= 0;
					});

					if (this.task.continuous_periods_start_date != null) {
						this.continuousPeriodsStartDate = moment(this.task.continuous_periods_start_date);
					}

					this.computeDisabledOfTaskDays();
				});
			}
		});
        
        forkJoin([
            this.http.get("task_types"),
            this.http.get("periodic_candidates/index_alphabetically", { params: { schedule_period_id: this.schedulePeriodId } }),
            this.http.get("locations"),
            this.http.get("tasks", { params: { schedule_period_id: this.schedulePeriodId } }),
			this.http.get("task_variant_groups"),
			this.http.get("time_slots"),
			this.http.get("task_display_groups", { params: { schedule_period_id: this.schedulePeriodId } })
        ]).subscribe(data => {
            this.taskTypes = data[0];
            this.periodicCandidates = data[1];
            this.locations = data[2];
            this.tasksForAllowedTasksAndConflictExceptions = data[3];
			this.taskVariantGroups = data[4];
			this.timeSlots = data[5];
			this.taskDisplayGroups = data[6];
    
            this.periodicCandidates.forEach(function(periodicCandidate) {
                periodicCandidate.lastnameWithInfix = (new Candidate(periodicCandidate.candidate)).lastnameWithInfix();
            });
    
            this.tasksForAllowedTasksAndConflictExceptions.forEach(function(task) {
                task.nameWithEquivalencePosition = task.name + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
			});
			
			this.timeSlots.forEach(function(timeSlot) {
				timeSlot.nameWithTime = (new TimeSlot(timeSlot)).nameWithTime();
			});

            this.isLoading = false;
        });
    }
}
