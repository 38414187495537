import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import templateString from './allocationRequestForm.component.html';
import * as moment from 'moment';
import { AllocationRequestPart } from 'site/app/models/allocationRequestPart.model';
import { Candidate } from 'site/app/models/candidate.model';
import { EnvironmentService } from 'site/app/environment.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TimeSlot } from 'site/app/models/timeSlot.model';
import { AllocationRequest } from 'site/app/models/allocationRequest.model';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Component({ template: templateString })
export class AllocationRequestFormComponent implements OnInit {
    @Output() action = new EventEmitter();

	public allocationRequestId;
	public selectedSchedulePeriod;
	public published_candidate_type_schedule_periods_contains_candidate_type_of_current_user: any;
	
	public allocationRequest;
	public activeDateStart;
	public activeDateEnd;
	public selectedSchedulePeriodStartDate;
	public selectedSchedulePeriodEndDate;
	public activeDate: any;
	public selectedDatesContainDateOutsideSchedulePeriod: boolean;
	public selectedDates: any;
	public periodicCandidateIds: any;
	public schedulePeriods: any;
	public shiftsForTaskType: any;
	public shiftsForTask: any;
	public allocationRequestTypes: any;
	public periodicCandidates: any;
	public periodicCandidate: any;
	public commonStatusId: any;
	public submitted: boolean;
	public allocationRequestForm: any;
	public user: any;
	public isNew: boolean;
	public allocationRequestShifts: {};
	public dateSelectionTypes: { id: number; title: string; }[];
	public allocationRequestStatuses: string[];
	public allocationRequestStatusButtonClasses: string[];
	public weekdays: { id: number; name: string; }[];
	public timeSlots: any;
	public actionTitle: string;
	public datePickerConfig: any;
	public datePickerConfigMultiSelect: any;
	public minDate;
	public maxDate;
	public displayDateStart: any;
	public displayDateEnd: any;
	public displayDate: any;
	public isLoading = false;
	public shiftsForPeriodicCandidate: any;
	public selectedShift: any;
	public candidateId: any;

	constructor(
		private renderer: Renderer2,
		private http: HttpClient,
		private environmentService: EnvironmentService,
		private bsModalRef: BsModalRef
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeSelectedDates() {
		var self = this;
		this.candidateId = null;

		if (this.allocationRequest.allocation_request_type.is_time_registration) {
			this.candidateId = (this.user.isAdmin || this.user.isAllocationRequestAdmin) ? 
				self.periodicCandidates.filter(function(x) { return self.periodicCandidateIds[0] == x.id; })[0].candidate_id :
				this.periodicCandidate.candidate_id;

			if (this.candidateId) {
				forkJoin([
					self.http.get<any>("shifts/for_agenda", 
						{ 
							params: {
								candidate_id: this.candidateId, 
								start_date: self.selectedSchedulePeriod.start_date,
								end_date: self.selectedSchedulePeriod.end_date
							}
						}
					),
					self.http.get<any>("shifts/time_registration_shifts_for_schedule_period_by_candidate", 
						{ 
							params: {
								candidate_id: this.candidateId, 
								schedule_period_id: self.selectedSchedulePeriod.id
							}
						}
					),
				]).subscribe(data => {
					var shifts = data[0];
					var shiftsWithExistingAllocationRequest = data[1];
					var shiftsForPeriodicCandidate = [];

					shifts.forEach(function(shift) {
						if (shiftsWithExistingAllocationRequest.filter(function(x) { return x.id == shift.id }).length == 0 &&
							moment(shift.start_date) >= moment(self.selectedSchedulePeriod.start_date) && moment(shift.start_date) < moment(self.selectedSchedulePeriod.end_date)) {
							shift.period = moment(shift.start_date).format('dd D MMM YYYY');
							if (!moment(shift.start_date).isSame(moment(shift.end_date).subtract(1, 'days'))) {
								shift.period += " t/m " + moment(shift.end_date).add(-1, 'days').format('dd D MMM YYYY');
							}
							shiftsForPeriodicCandidate.push(shift);
						}
					});

					self.shiftsForPeriodicCandidate = shiftsForPeriodicCandidate.sort(function(a, b) { return a.start_date > b.start_date ? 1 : -1 });;

					self.selectedShift = (self.allocationRequest.shift_id) ? self.shiftsForPeriodicCandidate.filter(function(x) { return x.id == self.allocationRequest.shift_id })[0] : null;
				});
			}

		} else if (this.allocationRequest.allocation_request_type.task_id != null && this.allocationRequest.allocation_request_type.is_allocate_preference) {

			// shift
			if (this.allocationRequest.shift_id == null) {
				this.allocationRequest.allocation_request_parts = [];
			} else {

				var shiftForTask = this.shiftsForTask[this.allocationRequest.allocation_request_type.task_id].filter(
					function(x) { return x.id == self.allocationRequest.shift_id; })[0];

				if (this.allocationRequest.allocation_request_parts.length == 0) {
					this.allocationRequest.allocation_request_parts = [new AllocationRequestPart({
						is_allocate_preference: this.allocationRequest.allocation_request_type.is_allocate_preference,
						shift_id: shiftForTask.id,
						start_date: moment(shiftForTask.start_date).format("DD-MM-YYYY"),
						end_date: moment(shiftForTask.end_date).format("DD-MM-YYYY"),
						status_id: 2,
						formatted_end_date: moment(shiftForTask.end_date).subtract(1, 'day').format("DD-MM-YYYY"),
						openAllocationRequestPartCount: shiftForTask.open_allocation_request_part_count,
						acceptedAllocationRequestPartCount: shiftForTask.accepted_allocation_request_part_count,
						is_all_day: true
					})];
				} else {
					this.allocationRequest.allocation_request_parts[0].is_allocate_preference =
						this.allocationRequest.allocation_request_type.is_allocate_preference;
					this.allocationRequest.allocation_request_parts[0].shift_id = shiftForTask.id;
					this.allocationRequest.allocation_request_parts[0].start_date = moment(shiftForTask.start_date).format("DD-MM-YYYY");
					this.allocationRequest.allocation_request_parts[0].end_date = moment(shiftForTask.end_date).format("DD-MM-YYYY");
					this.allocationRequest.allocation_request_parts[0].formatted_end_date = moment(shiftForTask.end_date).subtract(1, 'day').format("DD-MM-YYYY");
					this.allocationRequest.allocation_request_parts[0].openAllocationRequestPartCount = shiftForTask.open_allocation_request_part_count;
					this.allocationRequest.allocation_request_parts[0].acceptedAllocationRequestPartCount = shiftForTask.accepted_allocation_request_part_count;
				}
			}

		} else if (this.allocationRequest.allocation_request_type.task_type_id != null &&
			this.allocationRequest.allocation_request_type.is_allocate_preference &&
				!this.allocationRequest.allocation_request_type.is_period_containing_tasks) {

			if (this.allocationRequest.shift_id == null) {
				this.allocationRequest.allocation_request_parts = [];
			} else {

				var shiftForTaskType = this.shiftsForTaskType[self.allocationRequest.allocation_request_type.task_type_id].filter(
					function(x) { return x.id == self.allocationRequest.shift_id; })[0];

				if (this.allocationRequest.allocation_request_parts.length == 0) {
					this.allocationRequest.allocation_request_parts = [new AllocationRequestPart({
						is_allocate_preference: this.allocationRequest.allocation_request_type.is_allocate_preference,
						shift_id: shiftForTaskType.id,
						task_type_id: this.allocationRequest.allocation_request_type.task_type_id,
						start_date: moment(shiftForTaskType.start_date).format("DD-MM-YYYY"),
						end_date: moment(shiftForTaskType.end_date).format("DD-MM-YYYY"),
						status_id: 2,
						formatted_end_date: moment(shiftForTaskType.end_date).subtract(1, 'day').format("DD-MM-YYYY"),
						openAllocationRequestPartCount: shiftForTaskType.open_allocation_request_part_count,
						acceptedAllocationRequestPartCount: shiftForTaskType.accepted_allocation_request_part_count,
						is_all_day: true
					})];
				} else if (!shiftForTaskType) {
					this.allocationRequest.shift_id = null;
					this.allocationRequest.allocation_request_parts = [];
				} else {
					this.allocationRequest.allocation_request_parts[0].is_allocate_preference =
						this.allocationRequest.allocation_request_type.is_allocate_preference;
					this.allocationRequest.allocation_request_parts[0].shift_id = shiftForTaskType.id;
					this.allocationRequest.allocation_request_parts[0].start_date = moment(shiftForTaskType.start_date).format("DD-MM-YYYY");
					this.allocationRequest.allocation_request_parts[0].end_date = moment(shiftForTaskType.end_date).format("DD-MM-YYYY");
					this.allocationRequest.allocation_request_parts[0].formatted_end_date = moment(shiftForTaskType.end_date).subtract(1, 'day').format("DD-MM-YYYY");
					this.allocationRequest.allocation_request_parts[0].openAllocationRequestPartCount = shiftForTaskType.open_allocation_request_part_count;
					this.allocationRequest.allocation_request_parts[0].acceptedAllocationRequestPartCount = shiftForTaskType.accepted_allocation_request_part_count;
				}
			}
		} else {
			if (this.allocationRequest.date_selection_type_id != 2) {
				this.allocationRequest.allocation_request_parts = [];
			}
			
			if (this.allocationRequest.start_date != null && this.allocationRequest.end_date != null) {
				if (this.allocationRequest.date_selection_type_id == 1) {

					var currentDate = moment(this.activeDateStart);
					var endDate = moment(this.activeDateEnd).add(1, 'day');
					
					if (this.allocationRequest.allocation_request_type.is_allocate_preference) {
						// is_allocate_preference is true

						var currentTaskTypeShift = null;

						while (currentDate <= endDate) {
							// find task_type shift of which current date is in
							var matchedShiftsWithStartDateEqualToCurrentDate =
									this.shiftsForTaskType[this.allocationRequest.allocation_request_type.task_type_id].filter(
								function (x) { return moment(currentDate).format("DD-MM-YYYY") == moment(x.start_date).format("DD-MM-YYYY"); }
							);

							// task_type shift found
							if (matchedShiftsWithStartDateEqualToCurrentDate.length > 0) {

								if (currentTaskTypeShift != null) {
									this.allocationRequest.allocation_request_parts.push(
										new AllocationRequestPart({
											is_allocate_preference: true,
											shift_id: currentTaskTypeShift.id,
											task_type_id: this.allocationRequest.allocation_request_type.task_type_id,
											start_date: moment(currentTaskTypeShift.start_date).format("DD-MM-YYYY"),
											end_date: moment(currentTaskTypeShift.end_date).format("DD-MM-YYYY"),
											formatted_end_date: moment(currentTaskTypeShift.end_date).subtract(1, 'day').format("DD-MM-YYYY"),
											status_id: 2,
											openAllocationRequestPartCount: currentTaskTypeShift.open_allocation_request_part_count,
											acceptedAllocationRequestPartCount: currentTaskTypeShift.accepted_allocation_request_part_count,
											is_all_day: true
										})
									);
								}

								// start taskTypeShift
								currentTaskTypeShift = matchedShiftsWithStartDateEqualToCurrentDate[0];
							} else {

								if (currentTaskTypeShift == null) {
									// create request for each day, don't do it when we're at the last date
									if (moment(currentDate).isBefore(moment(endDate))) {
										this.allocationRequest.allocation_request_parts.push(
											new AllocationRequestPart({
												is_allocate_preference: false,
												start_date: moment(currentDate).format("DD-MM-YYYY"),
												end_date: moment(currentDate).add(1, 'day').format("DD-MM-YYYY"),
												status_id: 2,
												is_all_day: true
											})
										);
									}
								} else if (moment(currentDate).format("DD-MM-YYYY") == moment(currentTaskTypeShift.end_date).format("DD-MM-YYYY")) {
									this.allocationRequest.allocation_request_parts.push(
										new AllocationRequestPart({
											is_allocate_preference: true,
											shift_id: currentTaskTypeShift.id,
											task_type_id: this.allocationRequest.allocation_request_type.task_type_id,
											start_date: moment(currentTaskTypeShift.start_date).format("DD-MM-YYYY"),
											end_date: moment(currentTaskTypeShift.end_date).format("DD-MM-YYYY"),
											formatted_end_date: moment(currentTaskTypeShift.end_date).subtract(1, 'day').format("DD-MM-YYYY"),
											status_id: 2,
											openAllocationRequestPartCount: currentTaskTypeShift.open_allocation_request_part_count,
											acceptedAllocationRequestPartCount: currentTaskTypeShift.accepted_allocation_request_part_count,
											is_all_day: true
										})
									);

									currentTaskTypeShift = null;

									// Don't do this when we're at the last date
									if (moment(currentDate).isBefore(moment(endDate))) {

										this.allocationRequest.allocation_request_parts.push(
											new AllocationRequestPart({
												is_allocate_preference: false,
												// is_allocate_preference of allocation_request_type is true (for example for 'vakantie'), so this single
												// day should have is_allocate_preference=false because it's not linked to 'vakantie'
												task_type_id: null,
												start_date: moment(currentDate).format("DD-MM-YYYY"),
												end_date: moment(currentDate).add(1, 'day').format("DD-MM-YYYY"),
												status_id: 2,
												is_all_day: true
											})
										);
									}
								}
								
								if (moment(currentDate).isSame(moment(endDate)) && currentTaskTypeShift)	 {

									var currentDate2 = moment(currentTaskTypeShift.start_date);

									while (moment(currentDate2).isBefore(moment(currentDate))) {
										this.allocationRequest.allocation_request_parts.push(
											new AllocationRequestPart({
												is_allocate_preference: false,
												task_type_id: null,
												start_date: moment(currentDate2).format("DD-MM-YYYY"),
												end_date: moment(currentDate2).add(1, 'day').format("DD-MM-YYYY"),
												status_id: 2,
												is_all_day: true
											})
										);

										currentDate2 = moment(currentDate2).add(1, 'day');
									}
								}
							}

							currentDate = currentDate.add(1, 'day');
						}
					} else {
						// create single allocationRequestPart for 'geen dienst' (task_type) or 'geen verlengde dienst (task)
						this.allocationRequest.allocation_request_parts = [
							new AllocationRequestPart({
								is_allocate_preference: false,
								task_type_id: this.allocationRequest.allocation_request_type.task_type_id,
								start_date: moment(currentDate).format("DD-MM-YYYY"),
								end_date: moment(endDate).format("DD-MM-YYYY"),
								formatted_end_date: moment(endDate).subtract(1, 'day').format("DD-MM-YYYY"),
								status_id: 2,
								is_all_day: true
							})
						];
					}
				// losse dagen
				} else if (this.allocationRequest.date_selection_type_id == 2) {
					this.selectedDates = this.selectedDates.sort(function(a, b) { return (<any> moment(a)) - (<any> moment(b)); })

					var selectedDates = this.selectedDates.slice(0);

					for (var i = this.allocationRequest.allocation_request_parts.length - 1; i >= 0; i--) {

						var allocationRequestPart = this.allocationRequest.allocation_request_parts[i];

						var index = selectedDates.map(function(y) { return y.format("YYYYMMDD") }).indexOf(allocationRequestPart.start_date_for_order);

						if (index >= 0) {
							selectedDates.splice(index, 1);
						} else {
							this.allocationRequest.allocation_request_parts.splice(i, 1);
						}
					}

					selectedDates.forEach(function(x) {
						// find task_type shift of which current date is in
						var matchedShiftsWithStartDateEqualToCurrentDate =
							self.allocationRequest.allocation_request_type.task_type_id && self.shiftsForTaskType[self.allocationRequest.allocation_request_type.task_type_id] ? 
								self.shiftsForTaskType[self.allocationRequest.allocation_request_type.task_type_id].filter(
									function (y) { return moment(x).format("DD-MM-YYYY") == moment(y.start_date).format("DD-MM-YYYY") &&
										moment(x).add(1, 'day').format("DD-MM-YYYY") == moment(y.end_date).format("DD-MM-YYYY"); }) : 
								[];

						var currentTaskTypeShift;
						if (matchedShiftsWithStartDateEqualToCurrentDate.length > 0) {
							currentTaskTypeShift = matchedShiftsWithStartDateEqualToCurrentDate[0];
						}

						self.allocationRequest.allocation_request_parts.push(new AllocationRequestPart({
							// if is_allocate_preference of allocation_request_type is true (for example for 'vakantie'), then this single
							// day should have is_allocate_preference=false because it's not linked to 'vakantie'
							// is_allocate_preference of allocation_request_type is false (for example for 'geen dienst'), so 
							// it should have is_allocate_preference=true because it's linked to 'dienst'
							// !! unless this single day matches a corresponding shift that lasts for the same
							is_allocate_preference: self.allocationRequest.allocation_request_type.is_allocate_preference && !!currentTaskTypeShift,
							shift_id: currentTaskTypeShift ? currentTaskTypeShift.id : null,
							task_type_id: self.allocationRequest.allocation_request_type.is_allocate_preference && !currentTaskTypeShift ? null : self.allocationRequest.allocation_request_type.task_type_id,
							start_date: moment(x).format("DD-MM-YYYY"),
							start_date_for_order: moment(x).format("YYYYMMDD"),
							start_date_time: x,
							end_date: moment(x).add(1, 'day').format("DD-MM-YYYY"),
							status_id: 2,
							is_all_day: true,
							openAllocationRequestPartCount: currentTaskTypeShift ? currentTaskTypeShift.open_allocation_request_part_count : null,
							acceptedAllocationRequestPartCount: currentTaskTypeShift ? currentTaskTypeShift.accepted_allocation_request_part_count : null
						}));
					});

					this.selectedDatesContainDateOutsideSchedulePeriod = this.allocationRequest.allocation_request_parts.filter(function(x) { 
						return moment(x.start_date_time) < self.selectedSchedulePeriodStartDate || moment(x.start_date_time).add(1, 'day') > self.selectedSchedulePeriodEndDate 
					}).length > 0;

				// dag in de week
				} else if (this.allocationRequest.date_selection_type_id == 3) {

					if (this.allocationRequest.start_date != null && this.allocationRequest.end_date != null) {						
						var currentDate = moment(this.activeDateStart);
						var endDate = moment(this.activeDateEnd).add(1, 'day');

						while (currentDate < endDate) {

							// find task_type shift of which current date is in
							var matchedShiftsWithStartDateEqualToCurrentDate =
								self.allocationRequest.allocation_request_type.task_type_id ? 
									self.shiftsForTaskType[self.allocationRequest.allocation_request_type.task_type_id].filter(
										function (y) { return moment(currentDate).format("DD-MM-YYYY") == moment(y.start_date).format("DD-MM-YYYY") &&
											moment(currentDate).add(1, 'day').format("DD-MM-YYYY") == moment(y.end_date).format("DD-MM-YYYY"); }) : 
									[];

							var currentTaskTypeShift;
							if (matchedShiftsWithStartDateEqualToCurrentDate.length > 0) {
								currentTaskTypeShift = matchedShiftsWithStartDateEqualToCurrentDate[0];
							}

							if (currentDate.day() == this.allocationRequest.day_of_week) {
								this.allocationRequest.allocation_request_parts.push(new AllocationRequestPart({
									// if is_allocate_preference of allocation_request_type is true (for example for 'vakantie'), then this single
									// day should have is_allocate_preference=false because it's not linked to 'vakantie'
									// is_allocate_preference of allocation_request_type is false (for example for 'geen dienst'), so 
									// it should have is_allocate_preference=true because it's linked to 'dienst'
									// !! unless this single day matches a corresponding shift that lasts for the same
									is_allocate_preference: self.allocationRequest.allocation_request_type.is_allocate_preference && !!currentTaskTypeShift,
									shift_id: currentTaskTypeShift ? currentTaskTypeShift.id : null,
									task_type_id: self.allocationRequest.allocation_request_type.is_allocate_preference && !currentTaskTypeShift ? null : self.allocationRequest.allocation_request_type.task_type_id,
									start_date: moment(currentDate).format("DD-MM-YYYY"),
									start_date_for_order: moment(currentDate).format("YYYYMMDD"),
									start_date_time: currentDate,
									end_date: moment(currentDate).add(1, 'day').format("DD-MM-YYYY"),
									status_id: 2,
									is_all_day: true,
									openAllocationRequestPartCount: currentTaskTypeShift ? currentTaskTypeShift.open_allocation_request_part_count : null,
									acceptedAllocationRequestPartCount: currentTaskTypeShift ? currentTaskTypeShift.accepted_allocation_request_part_count : null
								}));
							}

							currentDate = currentDate.add(1, 'day');
						}
					}
				}
			}
		}
		
		this.computeFormattedPeriods();

		this.resetCommonStatusId();
	}

	changeStartDate(changeSelectedDates) {
		this.allocationRequest.formattedStartDate = moment(this.activeDateStart).format("DD-MM-YYYY");
		
		if (this.activeDateStart >= this.activeDateEnd) {
			this.displayDateEnd = moment(this.activeDateStart);

			// this is a hack because changing displayDate and ngModel date at the same time will only change one of them...
			setTimeout(() => {
				this.activeDateEnd = moment(this.activeDateStart);
				this.changeEndDate(changeSelectedDates);
			}, 1000);
			
		} else if (changeSelectedDates) {
			this.changeSelectedDates();
		}
	}
	
	changeEndDate(changeSelectedDates) {
		this.allocationRequest.formattedEndDate = moment(this.activeDateEnd).format("DD-MM-YYYY");
		if (changeSelectedDates) {
			this.changeSelectedDates();			
		}
	}
	
	initializePeriod() {
		this.activeDateStart = moment(this.allocationRequest.start_date);
		this.activeDateEnd = moment(this.allocationRequest.end_date).subtract(1, 'day');
		
		this.displayDateStart = this.activeDateStart;
		this.displayDateEnd = this.activeDateEnd;

		this.changeStartDate(false);
		this.changeEndDate(false);
	}

  	resetDateSelection() {
		this.selectedDatesContainDateOutsideSchedulePeriod = false;
		
		this.allocationRequest.allocation_request_parts = [];

		var initStartDate;
		var today = moment();
		
		if ((today.isSame(moment(this.selectedSchedulePeriodStartDate)) || today.isAfter(moment(this.selectedSchedulePeriodStartDate))) && today.isBefore(this.selectedSchedulePeriodEndDate)) {
			// TODO only use moment
			initStartDate = moment(new Date(today.year(), today.month(), today.date()));
		} else {
			initStartDate = moment(new Date(this.selectedSchedulePeriodStartDate.year(), this.selectedSchedulePeriodStartDate.month(), this.selectedSchedulePeriodStartDate.date())); 
		}

		var initStartDatePlusOne = moment(initStartDate).add(1, 'day');
		var initEndDate = moment(new Date(initStartDatePlusOne.year(), initStartDatePlusOne.month(), initStartDatePlusOne.date()));

		this.selectedDates.splice(0, this.selectedDates.length);
		this.activeDate = initStartDate;
		
		this.allocationRequest.start_date = initStartDate;
		this.allocationRequest.end_date = initEndDate;
		
		this.initializePeriod();
  	}
	
	resetDatePickerOptions(schedulePeriod) {
		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		}

		this.datePickerConfigMultiSelect = {
			firstDayOfWeek: "mo",
			allowMultiSelect: true
		}
	}

	deletePeriodicCandidateId(index) {
		this.periodicCandidateIds.splice(index, 1);;
	}
	
	addPeriodicCandidateId() {
		this.periodicCandidateIds.push(null);
	}

	changeSchedulePeriod(resetFields) {
		var self = this;

		if (resetFields) {
			this.allocationRequest.allocation_request_type_id = null;
			this.allocationRequest.date_selection_type_id = null;
			for(var i = 0; i < this.periodicCandidateIds.length; i++) {
				this.periodicCandidateIds[i] = null;
			}
		}

		this.selectedSchedulePeriod = this.schedulePeriods.filter(function(x) { return x.id == self.allocationRequest.schedule_period_id; })[0];
		this.selectedSchedulePeriodStartDate = moment(this.selectedSchedulePeriod.start_date);
		this.selectedSchedulePeriodEndDate = moment(this.selectedSchedulePeriod.end_date);
		
		this.minDate = moment(this.selectedSchedulePeriod.start_date);
		this.maxDate = moment(this.selectedSchedulePeriod.end_date).subtract(1, 'day');

		this.resetDatePickerOptions(this.selectedSchedulePeriod);
		
		this.http.get("allocation_request_types",
			{ 
				params: {
					schedule_period_id: this.allocationRequest.schedule_period_id 
				}
			}
		).subscribe(x => {

			this.allocationRequestTypes = x;
		
			this.allocationRequestTypes.filter(function(y) { return y.is_allocate_preference }).forEach(function(y) {
				if (y.task_id != null) {
					self.http.get<any>("shifts/with_task", 
						{ 
							params: {
								task_id: y.task_id, 
								allocation_request_id: self.allocationRequest.id,
								only_allocate_open_shifts: y.only_allocate_open_shifts 	
							}
						}
					).subscribe(shifts => {
						var shiftsForTask = shifts;

						shiftsForTask.forEach(function(shift) {
							shift.period = moment(shift.start_date).format('dd D MMM YYYY');
							if (!moment(shift.start_date).isSame(moment(shift.end_date).subtract(1, 'days'))) {
								shift.period += " t/m " + moment(shift.end_date).add(-1, 'days').format('dd D MMM YYYY');
							}
						});
		
						self.shiftsForTask[y.task_id] = shiftsForTask;
					});
				} else if (y.task_type_id != null) {
					self.http.get<any>("shifts/with_task_type", 
						{ 
							params: {
								task_type_id: y.task_type_id, 
								schedule_period_id: self.allocationRequest.schedule_period_id, 
								allocation_request_id: self.allocationRequest.id,
								only_allocate_open_shifts: y.only_allocate_open_shifts	
							}
						}
					).subscribe(shifts => {
						var shiftsForTaskType = shifts;
						shiftsForTaskType.forEach(function(shift) {
							shift.period = moment(shift.start_date).format('dd D MMM YYYY');
							if (!moment(shift.start_date).isSame(moment(shift.end_date).subtract(1, 'days'))) {
								shift.period += " t/m " + moment(shift.end_date).add(-1, 'days').format('dd D MMM YYYY');
							}
						});
		
						self.shiftsForTaskType[y.task_type_id] = shiftsForTaskType;
					});
				}
			});
		
			this.changeAllocationRequestType(resetFields);
		});
		
		this.http.get("periodic_candidates/index_alphabetically", 
			{ 
				params: {
					schedule_period_id: this.allocationRequest.schedule_period_id
				}
			}
		).subscribe(periodicCandidates => {
			this.periodicCandidates = periodicCandidates;
			
			this.periodicCandidates.forEach(function(periodicCandidate) {
				periodicCandidate.lastnameWithInfix = (new Candidate(periodicCandidate.candidate)).lastnameWithInfix();
			});
			
			this.periodicCandidate = this.periodicCandidates.filter(function(x) { return x.candidate_id == self.user.candidateId; })[0];
		});
	}
	
	changeAllocationRequestType(resetFields) {
		var self = this;
		if (this.allocationRequest.allocation_request_type_id == null) {
			this.allocationRequest.allocation_request_type = null;
		} else {
			this.allocationRequest.allocation_request_type = this.allocationRequestTypes.filter(function(x) { return x.id == self.allocationRequest.allocation_request_type_id })[0];
		}

		if (resetFields) {
			this.changeDateSelectionType();
		}
	}
	
	changeDateSelectionType() {
		this.resetDateSelection();
		if (this.allocationRequest.allocation_request_type != null) {
			this.changeSelectedDates();
		}
	}

  	removeFromSelectedDates(date) {
    	this.selectedDates.splice(this.selectedDates.indexOf(date), 1);
		this.changeSelectedDates();
  	}
	
  	resetCommonStatusId() {
		this.commonStatusId = undefined;
		
		if (this.allocationRequest.allocation_request_parts.length > 0) {
			var uniqueStatusId = this.allocationRequest.allocation_request_parts[0].status_id;
			
			if (this.allocationRequest.allocation_request_parts.filter(function(x) { return x.status_id != uniqueStatusId; }).length == 0) {
				this.commonStatusId = uniqueStatusId;
			}
		}
	}

	computeFormattedPeriods() {
		this.allocationRequest.allocation_request_parts.forEach(function(x) {
			x.formattedPeriod = x.start_date;
			
			if (x.formatted_end_date != null && x.start_date != x.formatted_end_date) {
				x.formattedPeriod += " t/m " + x.formatted_end_date;
			}
		});
	}
	
	getAllocationRequestPartType(allocationRequestPart) {
		if (this.allocationRequest.allocation_request_type.task_type_id != null && allocationRequestPart.is_allocate_preference) {
			return this.allocationRequest.allocation_request_type.task_type.name;
		} else {
			return this.allocationRequest.allocation_request_type.name;
		}
	}
	
  	setAllocationRequestStatus(allocationRequestStatusId) {
    	this.allocationRequest.status_id = allocationRequestStatusId;
  	}
	
	setAllocationRequestPartStatus(allocationRequestPart, statusId) {
		this.allocationRequest.allocation_request_parts.filter(function(x) { return x.start_date == allocationRequestPart.start_date })[0].status_id = statusId;
		
		this.resetCommonStatusId();
	}
	
	setAllAllocationRequestPartStatuses(statusId) {
		this.allocationRequest.allocation_request_parts.forEach(function(x) { 
			x.status_id = statusId;
		});
		
		this.resetCommonStatusId();
	}

	setAllocationRequestPartIsAllDay(allocationRequestPart) {
		allocationRequestPart.time_slot_id = null;
	}

	removeAllocationRequestPart(index) {
		this.allocationRequest.allocation_request_parts.splice(index, 1);
	}

  	ok(isValid) {
		// // Don't use isValid as a parameter here, because we want to make some changes to the validation results first:
		// // Because of the min and max dates added in datepickerOptions and we use today as the default filled in date for the datepickers, 
		// // the datepickers that are not used (thus hidden) could have an invalid date, which causes the form.$valid to be false
		// // The following alternatives are possible:
		// // - use *ngIf instead of [hidden], then validation will not occur -> datepickers are not reinitialized correctly and stop working when they become visible again [NOT CHOSEN]
		// // - initialize not with today date, but with first date in schedule period -> then we need to reinitialize the init-date of the datepickers when another scheduleperiod is selected [NOT CHOSEN]
		// // - manually set the particular validation to true for all the datepicker fields [CHOSEN]
		// this.allocationRequestForm.activeDateStart.$setValidity('dateDisabled', true);
		// this.allocationRequestForm.activeDateEnd.$setValidity('dateDisabled', true);
		// this.allocationRequestForm.activeDate.$setValidity('dateDisabled', true);				
		
		this.submitted = true;

		if ((this.user.isAdmin || this.user.isAllocationRequestAdmin || this.periodicCandidate != null) && isValid && 
			(this.allocationRequestId != null || !(this.user.isAdmin && !this.user.isAllocationRequestAdmin) || this.periodicCandidateIds.length > 0) &&
			(
				(this.allocationRequest.allocation_request_type.task_id != null && this.allocationRequest.shift_id != null) ||
				(this.allocationRequest.allocation_request_type.is_allocate_preference && 
					!this.allocationRequest.allocation_request_type.is_period_containing_tasks && 
					this.allocationRequest.shift_id != null) ||
				(
					(
						(
							this.allocationRequest.allocation_request_type.task_id == null || 
							!this.allocationRequest.allocation_request_type.is_allocate_preference
						) && 
						this.allocationRequest.date_selection_type_id != null
					) && 
					(
						(
							(this.allocationRequest.date_selection_type_id == 1 || this.allocationRequest.date_selection_type_id == 3) && 
								(
									moment(this.activeDateStart) <= moment(this.activeDateEnd) &&
									moment(this.activeDateStart) >= moment(this.selectedSchedulePeriodStartDate) &&
									moment(this.activeDateEnd) < moment(this.selectedSchedulePeriodEndDate) // use < because activeDateEnd is the day before the actual end date
								)
						) &&
						(this.allocationRequest.date_selection_type_id != 3 || this.allocationRequest.day_of_week != null) &&
						(this.allocationRequest.allocation_request_parts.length > 0)
					) ||
					(
						this.allocationRequest.date_selection_type_id == 2 && 
							(
								this.allocationRequest.allocation_request_parts.length > 0 &&
								!this.selectedDatesContainDateOutsideSchedulePeriod
							)
					)
				) ||
				(this.allocationRequest.allocation_request_type.is_time_registration &&
					(this.allocationRequestId != null || (this.selectedShift.shift_days[0].start_time && this.selectedShift.shift_days[0].end_time)))
			)
		) {
			this.isLoading = true;

			if (this.allocationRequestId == null) {
				if (this.allocationRequest.allocation_request_type.is_time_registration) {
					this.allocationRequest.overwritten_start_time = this.selectedShift.shift_days[0].start_time;
					this.allocationRequest.overwritten_end_time = this.selectedShift.shift_days[0].end_time;
				}

				if (this.user.isAdmin || this.user.isAllocationRequestAdmin) {
					this.allocationRequest.periodic_candidate_ids = this.periodicCandidateIds;
				} else {
					this.allocationRequest.periodic_candidate_ids = [this.periodicCandidate.id]
				}

				if (this.selectedSchedulePeriod.id != this.environmentService.getSchedulePeriodSelection().id) {
					// change globally selected schedule period
					this.environmentService.setSchedulePeriodSelectionExternally(this.selectedSchedulePeriod);
				}

				this.http.post('allocation_requests',
					{ allocation_request: this.allocationRequest }
				).subscribe(result => {
					var changedShifts = JSON.parse(result['changed']);
					
					if (changedShifts.length > 0){
						window.alert("De volgende activiteiten zijn automatisch toegewezen door het accepteren van de betreffende verzoeksonderdelen: " + 
							changedShifts.map(function(x) { return "\n- " + x.task.name + " (" + moment(x.start_date).format("DD-MM-YYYY") + ") toegewezen aan " + (new Candidate(x.periodic_candidate.candidate)).infixWithLastname() }))
					}

					this.action.emit();
					this.bsModalRef.hide();
				});
			} else {
				this.http.put("allocation_requests/" + this.allocationRequest.id, { allocation_request: this.allocationRequest }).subscribe((result) => {
					var changedShifts = JSON.parse(result['changed']);
					
					if (changedShifts.length > 0){
						window.alert("De volgende activiteiten zijn automatisch toegewezen door het accepteren van de betreffende verzoeksonderdelen: " + 
							changedShifts.map(function(x) { return "\n- " + x.task.name + " (" + moment(x.start_date).format("DD-MM-YYYY") + ") toegewezen aan " + (new Candidate(x.periodic_candidate.candidate)).infixWithLastname() }))
					}
					
					this.action.emit();
					this.bsModalRef.hide();
				});
			}			
		}		
  	};

  	cancel() {
    	this.bsModalRef.hide();
	};
	  
	trackByFunction(index) {
		return index;
	}

	ngOnInit() {		
		this.isNew = this.allocationRequestId == null;

		this.user = this.environmentService.getUser();
	
		this.allocationRequestShifts = {};
		this.commonStatusId = undefined;
		this.shiftsForTask = {};
		this.shiftsForTaskType = {};
		this.shiftsForPeriodicCandidate = [];
		this.selectedDates = [];
		this.selectedDatesContainDateOutsideSchedulePeriod = false;
		
		this.periodicCandidateIds = [null];
		
		 this.dateSelectionTypes = [
			{ id: 1, title: "Periode" }, 
			{ id: 2, title: "Losse dagen" },
			{ id: 3, title: "Vaste dag in de week" }
		];
		
		this.allocationRequestStatuses = ["Geweigerd", "Niet verwerkt", "Geaccepteerd"];
		this.allocationRequestStatusButtonClasses = ["btn-danger", "btn-warning", "btn-primary"];
	
		this.resetDatePickerOptions(this.selectedSchedulePeriod);
		
	
		var weekdays = ["maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag", "zondag"];
		this.weekdays = weekdays.map(function(x, index) { return { id: index + 1, name: x }; });
	
		// initialize date pickers TODO DRY -> this should be here (not within promise) otherwise it takes too long to fill activeDateStart and -end
		// and bootstrap datepicker gives error when initializing
		var now = new Date();
		var today = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate()));
		this.activeDateStart = today;
		this.activeDateEnd = today;
		this.selectedDates.splice(0, this.selectedDates.length);
		this.activeDate = today;
	
		this.http.get("time_slots").subscribe(x => {
			this.timeSlots = x;
			
			this.timeSlots.forEach(function(timeSlot) {
				timeSlot.nameWithTime = (new TimeSlot(timeSlot)).nameWithTime();
			});
		});
	
		this.http.get("schedule_periods").subscribe(schedulePeriods => {
			this.schedulePeriods = schedulePeriods;
			
			this.schedulePeriods.forEach(function(schedulePeriod) {
				schedulePeriod.nameWithPeriod = schedulePeriod.name + " (" + moment(schedulePeriod.start_date).format("D MMM YYYY") + " - " + moment(schedulePeriod.end_date).subtract(1, 'day').format("D MMM YYYY") + ")";
			});
			
			if (this.allocationRequestId == null) {
				this.actionTitle = "toevoegen";
				this.allocationRequest = new AllocationRequest();
				this.allocationRequest.allocation_request_parts = []
	
				if (this.selectedSchedulePeriod == null) {
					this.schedulePeriods.forEach(function(schedulePeriod) {
						if (schedulePeriod.is_default) {
							this.allocationRequest.schedule_period_id = schedulePeriod.id;
						}
					});
				} else {
					this.allocationRequest.schedule_period_id = this.selectedSchedulePeriod.id;
				}
			
				this.changeSchedulePeriod(false);
				this.resetDateSelection();
			} else {
				
				this.http.get("allocation_requests/" + this.allocationRequestId).subscribe(x => {
					this.allocationRequest = x;
	
					if ((this.allocationRequest.allocation_request_type.task_id != null && this.allocationRequest.allocation_request_type.is_allocate_preference) ||
							(
								this.allocationRequest.allocation_request_type.task_type_id != null && 
								this.allocationRequest.allocation_request_type.is_allocate_preference &&
								!this.allocationRequest.allocation_request_type.is_period_containing_tasks
							)
					) {
						this.allocationRequest.shift_id = this.allocationRequest.allocation_request_parts[0].shift_id;
						
						this.allocationRequest.allocation_request_parts[0].start_date = moment(this.allocationRequest.allocation_request_parts[0].start_date).format("DD-MM-YYYY");
						var endDate = this.allocationRequest.allocation_request_parts[0].end_date;
						this.allocationRequest.allocation_request_parts[0].end_date = moment(endDate).format("DD-MM-YYYY");
						this.allocationRequest.allocation_request_parts[0].formatted_end_date = moment(endDate).subtract(1, 'day').format("DD-MM-YYYY");
						this.allocationRequest.allocation_request_parts[0].openAllocationRequestPartCount = this.allocationRequest.allocation_request_parts[0].open_allocation_request_part_count;
						this.allocationRequest.allocation_request_parts[0].acceptedAllocationRequestPartCount = this.allocationRequest.allocation_request_parts[0].accepted_allocation_request_part_count;
						
					} else if (this.allocationRequest.date_selection_type_id == 2) {
						//this.selectedDates = this.allocationRequest.allocation_request_parts.map(function(y) { return new Date(y.start_date).getTime() - 7200000 });
						this.selectedDates = this.allocationRequest.allocation_request_parts.map(function(y) { return moment(y.start_date) });

						this.activeDate = moment(new Date(this.allocationRequest.allocation_request_parts[0].start_date));

						this.displayDate = this.activeDate;
	
						this.allocationRequest.allocation_request_parts.forEach(function(y) {
							y.start_date_time = moment(new Date(y.start_date).getTime() - 7200000);
							y.start_date_for_order = moment(y.start_date).format("YYYYMMDD");
							y.start_date = moment(y.start_date).format("DD-MM-YYYY");
							y.end_date = moment(y.end_date).format("DD-MM-YYYY");
						});
					} else if (this.allocationRequest.date_selection_type_id == 1 || this.allocationRequest.date_selection_type_id == 3) {
	
						this.initializePeriod();
	
						this.allocationRequest.allocation_request_parts.forEach(function(y) {
							var endDate = moment(y.end_date);
	
							y.start_date_time = moment(new Date(y.start_date).getTime() - 7200000);
							y.start_date_for_order = moment(y.start_date).format("YYYYMMDD");
							y.start_date = moment(y.start_date).format("DD-MM-YYYY");
							y.end_date = moment(endDate).format("DD-MM-YYYY");
							y.formatted_end_date = moment(endDate).subtract(1, 'day').format("DD-MM-YYYY");
							y.openAllocationRequestPartCount = y.open_allocation_request_part_count;
							y.acceptedAllocationRequestPartCount = y.accepted_allocation_request_part_count;
						});
					
						if (this.allocationRequest.date_selection_type_id == 3) {
							this.resetCommonStatusId();
						}
					}
	
					this.changeSchedulePeriod(false);
					this.computeFormattedPeriods();
				});
			
				this.actionTitle = "wijzigen";
			}		
		});	
	}
}
