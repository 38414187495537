import { Component, OnInit } from '@angular/core';
import templateString from './dayScheduleTabs.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { DayScheduleTabFormComponent } from '../dayScheduleTabForm';
import { DayScheduleTabChangeOrderComponent } from '../dayScheduleTabChangeOrder';
import { Task } from 'site/app/models/task.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class DayScheduleTabsComponent implements OnInit {
    
    public dayScheduleTabs: any;
    public selectedSchedulePeriod: any;
    public destroyed$: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        this.http.get("day_schedule_tabs", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
            this.dayScheduleTabs = x;

            this.dayScheduleTabs.forEach(function(dayScheduleTab) {
                dayScheduleTab.task_day_schedule_tab_memberships_for_schedule_period.forEach(function(taskDayScheduleMembership) {
                    taskDayScheduleMembership.task.computedNameWithEquivalencePosition = (new Task(taskDayScheduleMembership.task)).getComputedName() + (taskDayScheduleMembership.task.equivalence_position == null ? "" : " (" + taskDayScheduleMembership.task.equivalence_position + ")");
                });
			});
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(DayScheduleTabFormComponent, {
            initialState: {
                dayScheduleTabId: null,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(dayScheduleTabId) {
        var bsModalRef = this.bsModalService.show(DayScheduleTabFormComponent, {
            initialState: {
                dayScheduleTabId: dayScheduleTabId,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(dayScheduleTabId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("day_schedule_tabs/" + dayScheduleTabId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(DayScheduleTabChangeOrderComponent, {
            initialState: {
                originalDayScheduleTabs: this.dayScheduleTabs
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
