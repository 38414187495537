import { Component, OnInit } from '@angular/core';
import templateString from './allocationRequestAccumulationPeriods.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AllocationRequestAccumulationPeriodFormComponent } from '../allocationRequestAccumulationPeriodForm';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class AllocationRequestAccumulationPeriodsComponent implements OnInit {
    public destroyed$: any;
    public isLoading: boolean;
    public year: any;
    public allocationRequestAccumulationPeriods: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

	refresh() {
        this.http.get("allocation_request_accumulation_periods", { params: { year: this.year } }).subscribe(x => {
            this.allocationRequestAccumulationPeriods = x;
			this.isLoading = false;
		});
    }

    create() {
        var bsModalRef = this.bsModalService.show(AllocationRequestAccumulationPeriodFormComponent, {
            initialState: {
                allocationRequestAccumulationPeriodId: null,
                year: this.year,
                allocationRequestAccumulationPeriods: this.allocationRequestAccumulationPeriods
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(allocationRequestAccumulationPeriodId) {
        var bsModalRef = this.bsModalService.show(AllocationRequestAccumulationPeriodFormComponent, {
            initialState: {
                allocationRequestAccumulationPeriodId: allocationRequestAccumulationPeriodId,
                year: this.year,
                allocationRequestAccumulationPeriods: this.allocationRequestAccumulationPeriods
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(allocationRequestAccumulationPeriodId) {
		if (window.confirm('Weet je het zeker?')) {
            this.http.delete("allocation_request_accumulation_periods/" + allocationRequestAccumulationPeriodId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
		}
	};

	initialize() {
		this.isLoading = true;
		
		var selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

        // TODO: DRY
        if (moment(selectedSchedulePeriod.start_date).year() == moment(selectedSchedulePeriod.end_date).year()) {
            this.year = moment(selectedSchedulePeriod.start_date).year();
        } else {
            // define year by calculating where largest part of current schedule_period lies in
            if (
                moment.duration(moment(selectedSchedulePeriod.start_date).endOf('year').diff(moment(selectedSchedulePeriod.start_date))).asDays() >
                moment.duration(moment(selectedSchedulePeriod.end_date).diff(moment(selectedSchedulePeriod.end_date).startOf('year'))).asDays()
            ) {
                this.year = moment(selectedSchedulePeriod.start_date).year();
            } else {
                this.year = moment(selectedSchedulePeriod.end_date).year();
            }
        }

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
