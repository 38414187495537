import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './otherUserForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { User } from 'site/app/models/user.model';
import { EnvironmentService } from 'site/app/environment.service';
import { UserTaskDisplayGroupMembership } from 'site/app/models/userTaskDisplayGroupMembership.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class OtherUserFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public userId: any;
	public schedulePeriod: any;
	
	public submitted: boolean;
	public user: any;
	public actionTitle: string;
	public currentUser: any;
	public customAgendas: any;
	public trainingPeriodAccumulationTypes: any;
	public taskDisplayGroups: any;
	public userTaskDisplayGroupMembershipIndex: number;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteCustomAgendaId(index) {
		this.user.custom_agenda_ids.splice(index, 1);;
	}
	
	addCustomAgendaId() {
		this.user.custom_agenda_ids.push(null);
	}

	deleteUserTaskDisplayGroupMembership(index) {
		var matchIndex = -1;
		this.user.user_task_display_group_memberships_for_schedule_period.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		// hack because error lingers after it is deleted
		this.user.user_task_display_group_memberships_for_schedule_period[matchIndex].task_display_group_id = this.taskDisplayGroups[0].id;
		this.user.user_task_display_group_memberships_for_schedule_period[matchIndex].task_display_group_id = null;

		this.user.user_task_display_group_memberships_for_schedule_period.splice(matchIndex, 1);;
	}
	
	addUserTaskDisplayGroupMembership() {
		this.user.user_task_display_group_memberships_for_schedule_period.push(new UserTaskDisplayGroupMembership({ index: this.userTaskDisplayGroupMembershipIndex++ }));
	}

  	ok(isValid) {
		this.submitted = true;

		if (isValid) {
			if (this.userId === null) {
				this.http.post("other_users", this.user).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			} else {
				this.http.put("other_users/" + this.user.id,
					{
						user: this.user,
						schedule_period_id: this.schedulePeriod.id
					}
				).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			}
		}
	}

  	cancel() {
		this.bsModalRef.hide();
	}

	trackByFunction(index) {
		return index;
	}
	
    ngOnInit() {
		this.currentUser = this.environmentService.getUser();

		if (this.userId === null) {
			this.actionTitle = "toevoegen";

			this.user = new User();
			this.user.custom_agenda_ids = [];
			this.user.user_task_display_group_memberships_for_schedule_period = [];
			this.userTaskDisplayGroupMembershipIndex = 0;
		} else {
			this.actionTitle = "wijzigen";

			this.http.get<any>("other_users/" + this.userId, 
				{
					params: {
						schedule_period_id: this.schedulePeriod.id
					}
				}
			).subscribe(x => {
				this.user = x;
				this.user.custom_agenda_ids = this.user.custom_agendas.map(function(y) { return y.id; });
				
				this.user.user_task_display_group_memberships_for_schedule_period.forEach(function(userTaskDisplayGroupMembership, index) {
					userTaskDisplayGroupMembership.index = index;
				});

				this.userTaskDisplayGroupMembershipIndex = x.user_task_display_group_memberships_for_schedule_period.length;
			});
		}

		this.http.get("custom_agendas").subscribe(x => {
			this.customAgendas = x;
		});

		this.http.get("training_period_accumulation_types").subscribe(x => {
			this.trainingPeriodAccumulationTypes = x;
		});
		
		this.http.get("task_display_groups", { params: { schedule_period_id: this.schedulePeriod.id } }).subscribe(taskDisplayGroups => {
            this.taskDisplayGroups = taskDisplayGroups;
		});
    }
}
