import { Component, OnInit } from '@angular/core';
import templateString from './customAgendas.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { CustomAgendaFormComponent } from '../customAgendaForm';
import { CustomAgendaChangeOrderComponent } from '../customAgendaChangeOrder';
import { Task } from 'site/app/models/task.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class CustomAgendasComponent implements OnInit {
    
    public customAgendas: any;
    public selectedSchedulePeriod: any;
    public destroyed$: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        this.http.get("custom_agendas", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
            this.customAgendas = x;

            this.customAgendas.forEach(function(customAgenda) {
                customAgenda.custom_agenda_tasks_for_schedule_period.forEach(function(customAgendaTask) {
                    customAgendaTask.task.computedNameWithEquivalencePosition = (new Task(customAgendaTask.task)).getComputedName() + (customAgendaTask.task.equivalence_position == null ? "" : " (" + customAgendaTask.task.equivalence_position + ")");
                });
			});
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(CustomAgendaFormComponent, {
            initialState: {
                customAgendaId: null,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(customAgendaId) {
        var bsModalRef = this.bsModalService.show(CustomAgendaFormComponent, {
            initialState: {
                customAgendaId: customAgendaId,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(customAgendaId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("custom_agendas/" + customAgendaId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(CustomAgendaChangeOrderComponent, {
            initialState: {
                originalCustomAgendas: this.customAgendas
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
