import { Component, OnInit } from '@angular/core';
import templateString from './taskVariantGroups.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TaskVariantGroupFormComponent } from '../taskVariantGroupForm';
import { TaskVariantGroupChangeOrderComponent } from '../taskVariantGroupChangeOrder';
import { DataService } from 'site/app/data.service';
import { TaskVariantsChangeOrderComponent } from '../taskVariantsChangeOrder';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskVariantGroupsComponent implements OnInit {
    
    public taskVariantGroups: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private dataService: DataService,
        private environmentService: EnvironmentService
	) {  }

    refresh() {
        this.http.get("task_variant_groups").subscribe(x => {
            this.taskVariantGroups = x;
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(TaskVariantGroupFormComponent, {
            initialState: {
                taskVariantGroupId: null
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(taskVariantGroupId) {
        var bsModalRef = this.bsModalService.show(TaskVariantGroupFormComponent, {
            initialState: {
                taskVariantGroupId: taskVariantGroupId
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(taskVariantGroupId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("task_variant_groups/" + taskVariantGroupId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(TaskVariantGroupChangeOrderComponent, {
            initialState: {
                originalTaskVariantGroups: this.taskVariantGroups
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    changeTaskVariantsOrder(taskVariantGroup) {
        var bsModalRef = this.bsModalService.show(TaskVariantsChangeOrderComponent, {
            initialState: {
                originalTaskVariants: taskVariantGroup.task_variants
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    getColor(taskVariant) {
        return this.dataService.colors[taskVariant.color_id];
    }

    initialize() {
		this.refresh();
	}
	
    ngOnInit() {
        this.initialize();
    }
}
