import { Component, OnInit } from '@angular/core';
import templateString from './taskDisplayGroups.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Task } from 'site/app/models/task.model';
import { TaskDisplayGroupFormComponent } from '../taskDisplayGroupForm';
import { TaskDisplayGroupChangeOrderComponent } from '../taskDisplayGroupChangeOrder';
import { Subject } from 'rxjs';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskDisplayGroupsComponent implements OnInit {
    
    public taskDisplayGroups: any;
    public selectedSchedulePeriod: any;
    public destroyed$: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        this.http.get("task_display_groups", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
            this.taskDisplayGroups = x;
            
            this.taskDisplayGroups.forEach(function(taskDisplayGroup) {
                taskDisplayGroup.tasks.forEach(function(task) {
                    task.computedName = (new Task(task)).getComputedName();
                }); 
            });
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(TaskDisplayGroupFormComponent, {
            initialState: {
                taskDisplayGroupId: null,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(taskDisplayGroupId) {
        var bsModalRef = this.bsModalService.show(TaskDisplayGroupFormComponent, {
            initialState: {
                taskDisplayGroupId: taskDisplayGroupId,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(taskDisplayGroupId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("task_display_groups/" + taskDisplayGroupId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(TaskDisplayGroupChangeOrderComponent, {
            initialState: {
                originalTaskDisplayGroups: this.taskDisplayGroups
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
