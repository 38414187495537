import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser';
import { appRoutingModule } from './../app.routing';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from './../directives';
import { PipesModule } from './../pipes';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TasksComponent } from './tasks/tasks';
import { TaskFormComponent } from './tasks/taskForm';
import { TaskCloneComponent } from './tasks/taskClone';
import { TaskChangeOrderComponent } from './tasks/taskChangeOrder/taskChangeOrder.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PeriodicCandidatesComponent } from './periodicCandidates/periodicCandidates';
import { PeriodicCandidateFormComponent } from './periodicCandidates/periodicCandidateForm';
import { PeriodicCandidateCloneComponent } from './periodicCandidates/periodicCandidateClone';
import { PeriodicCandidateChangeOrderComponent } from './periodicCandidates/periodicCandidateChangeOrder';
import { DpDatePickerModule } from 'ng2-date-picker';
import { SchedulePeriodsComponent } from './schedulePeriods/schedulePeriods';
import { SchedulePeriodFormComponent } from './schedulePeriods/schedulePeriodForm';
import { OtherUsersComponent } from './otherUsers/otherUsers';
import { OtherUserFormComponent } from './otherUsers/otherUserForm';
import { SpecialEventsComponent } from './specialEvents/specialEvents';
import { SpecialEventFormComponent } from './specialEvents/specialEventForm';
import { AccumulationPeriodsComponent } from './accumulationPeriods/accumulationPeriods';
import { AccumulationPeriodFormComponent } from './accumulationPeriods/accumulationPeriodForm';
import { EnvironmentComponent } from './environment/environment';
import { EnvironmentFormComponent } from './environment/environmentForm';
import { TaskDisplayGroupsComponent } from './taskDisplayGroups/taskDisplayGroups';
import { TaskDisplayGroupFormComponent } from './taskDisplayGroups/taskDisplayGroupForm';
import { TaskDisplayGroupChangeOrderComponent } from './taskDisplayGroups/taskDisplayGroupChangeOrder';
import { TaskTypesComponent } from './taskTypes/taskTypes';
import { TaskTypeFormComponent } from './taskTypes/taskTypeForm';
import { TaskTypeChangeOrderComponent } from './taskTypes/taskTypeChangeOrder';
import { AllocationRequestTypesComponent } from './allocationRequestTypes/allocationRequestTypes';
import { AllocationRequestTypeFormComponent } from './allocationRequestTypes/allocationRequestTypeForm';
import { CandidateTypesComponent } from './candidateTypes/candidateTypes';
import { CandidateTypeFormComponent } from './candidateTypes/candidateTypeForm';
import { CustomAgendasComponent } from './customAgendas/customAgendas';
import { CustomAgendaFormComponent } from './customAgendas/customAgendaForm';
import { LocationsComponent } from './locations/locations';
import { LocationFormComponent } from './locations/locationForm';
import { OptimizationGroupsComponent } from './optimizationGroups/optimizationGroups';
import { OptimizationGroupFormComponent } from './optimizationGroups/optimizationGroupForm';
import { OptimizationGroupRunFormComponent } from './optimizationGroups/optimizationGroupRunForm';
import { OptimizationGroupRunOptionsComponent } from './optimizationGroups/optimizationGroupRunOptions';
import { AllocationRequestTypeChangeOrderComponent } from './allocationRequestTypes/allocationRequestTypeChangeOrder/allocationRequestTypeChangeOrder.component';
import { CandidateTypeChangeOrderComponent } from './candidateTypes/candidateTypeChangeOrder';
import { CustomAgendaChangeOrderComponent } from './customAgendas/customAgendaChangeOrder';
import { LocationChangeOrderComponent } from './locations/locationChangeOrder';
import { OptimizationGroupChangeOrderComponent } from './optimizationGroups/optimizationGroupChangeOrder';
import { ShiftAccumulationTypesComponent } from './shiftAccumulationTypes/shiftAccumulationTypes';
import { ShiftAccumulationTypeFormComponent } from './shiftAccumulationTypes/shiftAccumulationTypeForm';
import { ShiftAccumulationTypeChangeOrderComponent } from './shiftAccumulationTypes/shiftAccumulationTypeChangeOrder';
import { TaskAccumulationTypesComponent } from './taskAccumulationTypes/taskAccumulationTypes';
import { TaskAccumulationTypeFormComponent } from './taskAccumulationTypes/taskAccumulationTypeForm';
import { TaskAccumulationTypeChangeOrderComponent } from './taskAccumulationTypes/taskAccumulationTypeChangeOrder';
import { TaskVariantGroupsComponent } from './taskVariantGroups/taskVariantGroups';
import { TaskVariantGroupFormComponent } from './taskVariantGroups/taskVariantGroupForm';
import { TaskVariantGroupChangeOrderComponent } from './taskVariantGroups/taskVariantGroupChangeOrder';
import { TemplatePeriodAccumulationTypesComponent } from './templatePeriodAccumulationTypes/templatePeriodAccumulationTypes';
import { TemplatePeriodAccumulationTypeFormComponent } from './templatePeriodAccumulationTypes/templatePeriodAccumulationTypeForm';
import { TemplatePeriodAccumulationTypeChangeOrderComponent } from './templatePeriodAccumulationTypes/templatePeriodAccumulationTypeChangeOrder';
import { TrainingPeriodAccumulationTypesComponent } from './trainingPeriodAccumulationTypes/trainingPeriodAccumulationTypes';
import { TrainingPeriodAccumulationTypeFormComponent } from './trainingPeriodAccumulationTypes/trainingPeriodAccumulationTypeForm';
import { TrainingPeriodAccumulationTypeChangeOrderComponent } from './trainingPeriodAccumulationTypes/trainingPeriodAccumulationTypeChangeOrder';
import { TaskAccumulationPeriodsComponent } from './taskAccumulationPeriods/taskAccumulationPeriods';
import { TaskAccumulationPeriodFormComponent } from './taskAccumulationPeriods/taskAccumulationPeriodForm';
import { TaskAccumulationTargetsComponent } from './taskAccumulationTargets/taskAccumulationTargets';
import { TaskAccumulationTargetFormComponent } from './taskAccumulationTargets/taskAccumulationTargetForm';
import { TaskConflictExceptionsComponent } from './taskConflictExceptions/taskConflictExceptions';
import { TaskConflictExceptionFormComponent } from './taskConflictExceptions/taskConflictExceptionForm';
import { AllocationRequestAccumulationTypesComponent } from './allocationRequestAccumulationTypes/allocationRequestAccumulationTypes';
import { AllocationRequestAccumulationTypeFormComponent } from './allocationRequestAccumulationTypes/allocationRequestAccumulationTypeForm';
import { AllocationRequestAccumulationPeriodsComponent } from './allocationRequestAccumulationPeriods/allocationRequestAccumulationPeriods';
import { AllocationRequestAccumulationPeriodFormComponent } from './allocationRequestAccumulationPeriods/allocationRequestAccumulationPeriodForm';
import { AllocationRequestAccumulationTypeChangeOrderComponent } from './allocationRequestAccumulationTypes/allocationRequestAccumulationTypeChangeOrder';
import { ReportsComponent } from './reports/reports';
import { ReportFormComponent } from './reports/reportForm';
import { CandidateAllocationRequestBudgetComponentsComponent } from './candidateAllocationRequestBudgetComponents/candidateAllocationRequestBudgetComponents';
import { CandidateAllocationRequestBudgetComponentFormComponent } from './candidateAllocationRequestBudgetComponents/candidateAllocationRequestBudgetComponentForm';
import { DayScheduleTabsComponent } from './dayScheduleTabs/dayScheduleTabs';
import { DayScheduleTabFormComponent } from './dayScheduleTabs/dayScheduleTabForm';
import { DayScheduleTabChangeOrderComponent } from './dayScheduleTabs/dayScheduleTabChangeOrder';
import { AllocationRequestTypeCloneComponent } from './allocationRequestTypes/allocationRequestTypeClone';
import { CandidateTypeTaskMembershipsComponent } from './candidateTypeTaskMemberships/candidateTypeTaskMemberships';
import { CandidateTypeTaskMembershipFormComponent } from './candidateTypeTaskMemberships/candidateTypeTaskMembershipForm';
import { DefaultAssignmentsComponent } from './defaultAssignments/defaultAssignments';
import { ChangeDefaultAssignmentModalComponent } from './defaultAssignments/changeDefaultAssignmentModal';
import { TaskVariantsChangeOrderComponent } from './taskVariantGroups/taskVariantsChangeOrder';
import { ReportPartsChangeOrderComponent } from './reports/reportPartsChangeOrder';
import { ReportChangeOrderComponent } from './reports/reportChangeOrder';
import { ProximityConstraintsComponent } from './proximityConstraints/proximityConstraints';
import { ProximityConstraintFormComponent } from './proximityConstraints/proximityConstraintForm/proximityConstraintForm.component';
import { CustomersComponent } from './customers/customers';
import { CustomerFormComponent } from './customers/customerForm';
import { ApplyDefaultAssignmentDetailsComponent } from './defaultAssignments/applyDefaultAssignmentDetails';
import { ArticlesComponent } from './articles/articles';
import { ArticleFormComponent } from './articles/articleForm';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  	declarations: [
		TasksComponent,
		TaskFormComponent,
		TaskCloneComponent,
		TaskChangeOrderComponent,
		PeriodicCandidatesComponent,
		PeriodicCandidateFormComponent,
		PeriodicCandidateCloneComponent,
		PeriodicCandidateChangeOrderComponent,
		SchedulePeriodsComponent,
		SchedulePeriodFormComponent,
		OtherUsersComponent,
		OtherUserFormComponent,
		SpecialEventsComponent,
		SpecialEventFormComponent,
		AccumulationPeriodsComponent,
		AccumulationPeriodFormComponent,
		EnvironmentComponent,
		EnvironmentFormComponent,
		TaskDisplayGroupsComponent,
		TaskDisplayGroupFormComponent,
		TaskDisplayGroupChangeOrderComponent,
		TaskTypesComponent,
		TaskTypeFormComponent,
		TaskTypeChangeOrderComponent,
		AllocationRequestTypesComponent,
		AllocationRequestTypeFormComponent,
		AllocationRequestTypeChangeOrderComponent,
		AllocationRequestTypeCloneComponent,
		CandidateTypesComponent,
		CandidateTypeFormComponent,
		CandidateTypeChangeOrderComponent,
		CustomAgendasComponent,
		CustomAgendaFormComponent,
		CustomAgendaChangeOrderComponent,
		LocationsComponent,
		LocationFormComponent,
		LocationChangeOrderComponent,
		OptimizationGroupsComponent,
		OptimizationGroupFormComponent,
		OptimizationGroupRunFormComponent,
		OptimizationGroupRunOptionsComponent,
		OptimizationGroupChangeOrderComponent,
		ShiftAccumulationTypesComponent,
		ShiftAccumulationTypeFormComponent,
		ShiftAccumulationTypeChangeOrderComponent,
		TaskAccumulationTypesComponent,
		TaskAccumulationTypeFormComponent,
		TaskAccumulationTypeChangeOrderComponent,
		TaskVariantGroupsComponent,
		TaskVariantGroupFormComponent,
		TaskVariantGroupChangeOrderComponent,
		TaskVariantsChangeOrderComponent,
		TemplatePeriodAccumulationTypesComponent,
		TemplatePeriodAccumulationTypeFormComponent,
		TemplatePeriodAccumulationTypeChangeOrderComponent,
		TrainingPeriodAccumulationTypesComponent,
		TrainingPeriodAccumulationTypeFormComponent,
		TrainingPeriodAccumulationTypeChangeOrderComponent,
		TaskAccumulationPeriodsComponent,
		TaskAccumulationPeriodFormComponent,
		TaskAccumulationTargetsComponent,
		TaskAccumulationTargetFormComponent,
		TaskConflictExceptionsComponent,
		TaskConflictExceptionFormComponent,
		ReportsComponent,
		ReportFormComponent,
		ReportChangeOrderComponent,
		ReportPartsChangeOrderComponent,
		AllocationRequestAccumulationTypesComponent,
		AllocationRequestAccumulationTypeFormComponent,
		AllocationRequestAccumulationTypeChangeOrderComponent,
		AllocationRequestAccumulationPeriodsComponent,
		AllocationRequestAccumulationPeriodFormComponent,
		CandidateAllocationRequestBudgetComponentsComponent,
		CandidateAllocationRequestBudgetComponentFormComponent,
		DayScheduleTabsComponent,
		DayScheduleTabFormComponent,
		DayScheduleTabChangeOrderComponent,
		CandidateTypeTaskMembershipsComponent,
		CandidateTypeTaskMembershipFormComponent,
		DefaultAssignmentsComponent,
		ChangeDefaultAssignmentModalComponent,
		ProximityConstraintsComponent,
		ProximityConstraintFormComponent,
		CustomersComponent,
		CustomerFormComponent,
		ApplyDefaultAssignmentDetailsComponent,
		ArticlesComponent,
		ArticleFormComponent
	],
  	imports: [
		BrowserModule,
		appRoutingModule,
		FormsModule,
		DirectivesModule,
		ModalModule.forRoot(),
		TimepickerModule.forRoot(),
		PipesModule,
		DragDropModule,
		DpDatePickerModule,
		AngularEditorModule
	],
  	providers: [	
	],
	entryComponents: [
		TaskFormComponent,
		TaskCloneComponent,
		TaskChangeOrderComponent,
		PeriodicCandidateFormComponent,
		PeriodicCandidateCloneComponent,
		PeriodicCandidateChangeOrderComponent,
		SchedulePeriodFormComponent,
		OtherUserFormComponent,
		SpecialEventFormComponent,
		AccumulationPeriodFormComponent,
		EnvironmentFormComponent,
		TaskDisplayGroupFormComponent,
		TaskDisplayGroupChangeOrderComponent,
		TaskTypeFormComponent,
		TaskTypeChangeOrderComponent,
		AllocationRequestTypeFormComponent,
		AllocationRequestTypeChangeOrderComponent,
		AllocationRequestTypeCloneComponent,
		CandidateTypeFormComponent,
		CandidateTypeChangeOrderComponent,
		CustomAgendaFormComponent,
		CustomAgendaChangeOrderComponent,
		LocationFormComponent,
		LocationChangeOrderComponent,
		OptimizationGroupFormComponent,
		OptimizationGroupRunFormComponent,
		OptimizationGroupRunOptionsComponent,
		OptimizationGroupChangeOrderComponent,
		ShiftAccumulationTypeFormComponent,
		ShiftAccumulationTypeChangeOrderComponent,
		TaskAccumulationTypeFormComponent,
		TaskAccumulationTypeChangeOrderComponent,
		TaskVariantGroupFormComponent,
		TaskVariantGroupChangeOrderComponent,
		TaskVariantsChangeOrderComponent,
		TemplatePeriodAccumulationTypeFormComponent,
		TemplatePeriodAccumulationTypeChangeOrderComponent,
		TrainingPeriodAccumulationTypeFormComponent,
		TrainingPeriodAccumulationTypeChangeOrderComponent,
		TaskAccumulationPeriodFormComponent,
		TaskAccumulationTargetFormComponent,
		TaskConflictExceptionFormComponent,
		ReportFormComponent,
		ReportChangeOrderComponent,
		ReportPartsChangeOrderComponent,
		AllocationRequestAccumulationTypeFormComponent,
		AllocationRequestAccumulationTypeChangeOrderComponent,
		AllocationRequestAccumulationPeriodFormComponent,
		CandidateAllocationRequestBudgetComponentFormComponent,
		DayScheduleTabFormComponent,
		DayScheduleTabChangeOrderComponent,
		CandidateTypeTaskMembershipFormComponent,
		ChangeDefaultAssignmentModalComponent,
		ProximityConstraintFormComponent,
		CustomerFormComponent,
		ApplyDefaultAssignmentDetailsComponent,
		ArticleFormComponent
	]
})
export class SettingsModule {}
