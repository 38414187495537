import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './trainingPeriodAccumulationTypeForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'site/app/data.service';
import { TrainingPeriodAccumulationType } from 'site/app/models/trainingPeriodAccumulationType.model';
import { DefaultTemplatePeriodTask } from 'site/app/models/defaultTemplatePeriodTask.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TrainingPeriodAccumulationTypeFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public trainingPeriodAccumulationTypeId: any;
	public schedulePeriodId: any;

	public trainingPeriodAccumulationType: any;
	public submitted: boolean;	
	public actionTitle: string;
	public isLoading: boolean;
	public colors: any;
	public overallTrainingPeriodAccumulationTypes: any;
	public tasks: any;
	public defaultTemplatePeriodTaskIndex: number;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private toastr: ToastrService,
		private dataService: DataService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteDefaultTemplatePeriodTask(index) {
		var matchIndex = -1;
		this.trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		// hack because error lingers after it is deleted
		this.trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period[matchIndex].task_id = this.tasks[0].id;
		this.trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period[matchIndex].task_id = null;

		this.trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period.splice(matchIndex, 1);;
	}
	
	addDefaultTemplatePeriodTask() {
		this.trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period.push(new DefaultTemplatePeriodTask({ index: this.defaultTemplatePeriodTaskIndex++ }));
	}


  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.trainingPeriodAccumulationTypeId === null) {
				this.http.post("training_period_accumulation_types",
					{
						training_period_accumulation_type: this.trainingPeriodAccumulationType,
						schedule_period_id: this.schedulePeriodId
					}
				).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			} else {
				this.http.put("training_period_accumulation_types/" + this.trainingPeriodAccumulationType.id, 
					{
						training_period_accumulation_type: this.trainingPeriodAccumulationType,
						schedule_period_id: this.schedulePeriodId
					}
				).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}

    ngOnInit() {
		this.colors = this.dataService.colorsRegional;
		this.http.get("overall_training_period_accumulation_types").subscribe(x => {
			this.overallTrainingPeriodAccumulationTypes = x;
		});
		
		if (this.trainingPeriodAccumulationTypeId === null) {
			this.actionTitle = "toevoegen";
			this.trainingPeriodAccumulationType = new TrainingPeriodAccumulationType();
			this.trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period = [];
			this.defaultTemplatePeriodTaskIndex = 0;
		} else {
			this.actionTitle = "wijzigen";
			this.http.get<any>("training_period_accumulation_types/" + this.trainingPeriodAccumulationTypeId,
				{
					params: {
						schedule_period_id: this.schedulePeriodId
					}
				}
			).subscribe(x => {
				this.trainingPeriodAccumulationType = x;

				if (!this.trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period) {
					this.trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period = [];
				}
				
				this.trainingPeriodAccumulationType.default_template_period_tasks_for_schedule_period.forEach(function(defaultTemplatePeriodTask, index) {
					defaultTemplatePeriodTask.index = index;
				});	

				this.defaultTemplatePeriodTaskIndex = x.default_template_period_tasks_for_schedule_period.length;
			});
		}

		this.http.get<any>("tasks", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(tasks => {
			this.tasks = tasks;
	
			tasks.forEach(function(task) {
				task.nameWithEquivalencePosition = task.name + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
			});
	
			this.isLoading = false;
		});
    }
}
