import { Component, OnInit } from '@angular/core';
import templateString from './login.component.html';
import { Router } from '@angular/router';
import { EnvironmentService } from './../../environment.service';
import { AngularTokenService } from './../../angular-token'
import { BsModalService } from 'ngx-bootstrap/modal';
import { SelectCustomerComponent } from '../selectCustomer';
import { Candidate } from 'site/app/models/candidate.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class LoginComponent implements OnInit {
	public showIncorrect = false;
	public customerId;
	public email;
	public password;

	constructor(
		private router: Router, 
		private environmentService: EnvironmentService, 
		private tokenService: AngularTokenService,
		private http: HttpClient,
		private bsModalService: BsModalService
	) { }
	
	login() {
		// check other registered user already to get list of customers if multiple
		this.customerId = null;

		this.http.get<any>('other_users/get_other_registered_users_with_same_email_by_email_password', 
			{ 
				params: 
					{ 
						email: this.email,
						password: this.password
					}
			}
		).subscribe(users => {

			if (users.length > 1) {
				var bsModalRef = this.bsModalService.show(SelectCustomerComponent, {
					initialState: {
						users: users
					}
				});
		
				bsModalRef.content.action.subscribe(result => {	
					this.customerId = result;
					this.startSession();
				});

			} else {
				this.startSession();
			}
		});
	}

	startSession() {
		
		// this logout call is a hack, it is done before at logging of, but this is here in case
		// the signout action doesn't go well, which happens when during loading the page (for example the longlasting schedule-page)
		// the logout button is pressed. In that case we get this in the backend output:
		// | "Started DELETE "/users/sign_out.json" for 127.0.0.1 at 2019-07-11 02:50:07 +0200
		// | Processing by Devise::SessionsController#destroy as JSON
		// | Filter chain halted as :verify_signed_out_user rendered or redirected
		// then the session is not destroyed and when we sign in again the old session is picked up
		// when changing envirionments this goes wrong, so the Auth.logout call makes sure that
		// we are definitely logged out
		this.tokenService.signOut().subscribe(oldUser => {
			this.environmentService.deleteUser();
			//$rootScope.$broadcast('refreshUser');

			this.tokenService.signIn({
				login: this.email,
				password: this.password,
			  	customer_id: this.customerId
		  	}).subscribe(res => {
				var user = res.body;
					  
				// first reset environmentService otherwise we get weird situations when items from previous session (when logging out and not doing refresh page) are still in the environmentService, like selected schedule periods or something
				this.environmentService.reset();

				this.http.get<any>('customers/' + user.customer_id).subscribe(customer => {
					this.http.get<any>('locations').subscribe(locations => {
						var location = null;

						if (customer.regional_location_id != null) {
							location = locations.filter(function(x) { return x.id == customer.regional_location_id })[0];
						}

						if (user.candidate_id != null) {
							this.http.get('candidates/' + user.candidate_id).subscribe(x => {
								var candidate = new Candidate(x);
								this.environmentService.setUser(user.id_in_shard, candidate.id, candidate.infixWithLastname(), user.is_admin, user.is_training_admin, user.is_trainer, false, customer, candidate.periodic_candidates.map(function(x) { return x.schedule_period_id; }), candidate.periodic_candidates, location, candidate.regional_candidate_id, false, user.has_only_access_to_training_period_accumulation_type_id, user.has_only_access_to_own_trainees_and_location, user.has_only_access_to_task_display_groups, user.is_allocation_request_admin, false);

								// $rootScope.$broadcast('refreshUser');
								this.redirect(user, customer);
							});
						} else {
							this.environmentService.setUser(user.id_in_shard, null, user.lastname, user.is_admin, user.is_training_admin, user.is_trainer, user.is_super_admin, customer, [], [], location, null, user.is_day_schedule_not_accessible, user.has_only_access_to_training_period_accumulation_type_id, user.has_only_access_to_own_trainees_and_location, user.has_only_access_to_task_display_groups, user.is_allocation_request_admin, user.is_day_schedule_also_accessible);
							// $rootScope.$broadcast('refreshUser');
							this.redirect(user, customer);
						}
					});
				});
			},
			error => {
				console.log(error);
				this.showIncorrect = true;
			});

			//this.toastr.success("U bent uitgelogd");
			this.router.navigate(['/login']);
		}, (error) => {
		  console.error(error);
		});
	}

	redirect(user, customer) {
		if (customer.customer_type_id == 2) {
			if (user.is_admin) {
				this.router.navigate(['/app/trainingSchedule']);
			} else {
				this.router.navigate(['/app/trainingCandidates']);
			}
		} else if (customer.is_only_regional || user.has_only_access_to_training_period_accumulation_type_id) {
			if (user.is_admin || user.is_training_admin) {
				this.router.navigate(['/app/trainingSchedule']);
			} else {
				this.router.navigate(['/app/trainingCandidates']);
			}
		} else if (user.is_admin || user.is_super_admin || (user.candidate_id == null && !user.is_day_schedule_not_accessible) || user.has_only_access_to_task_display_groups) {
			this.router.navigate(['/app/daySchedule']);
		} else {
			this.router.navigate(['/app/agenda']);
		}
	}

	ngOnInit() {
		// make sure that when we arrive here user is logged out
		// TODO: DRY
		this.tokenService.signOut().subscribe(oldUser => {
			this.environmentService.deleteUser();
			this.router.navigate(["/login"]);
		}, function(error) {
			console.error(error);
		});
	}
}
