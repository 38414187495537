import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './specialEventForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { SpecialEvent } from 'site/app/models/specialEvent.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class SpecialEventFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public isPublicHoliday: any;
	public specialEventId: any;
	public selectedSchedulePeriod: any;

	public specialEvent: any;
	public activeDateStart: any;
	public activeDateEnd: any;
	public submitted: boolean;
	public minDate: any;
	public maxDate: any;
	public datePickerConfig: { firstDayOfWeek: string; };
	public actionTitle: string;
	public displayDateStart: any;
	public displayDateEnd: any;
	public isLoading: any = false;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeStartDate() {
	   	this.specialEvent.formattedStartDate = moment(this.activeDateStart).format("DD-MM-YYYY");
		
	   	if (this.activeDateStart > this.activeDateEnd) {
		   	this.displayDateEnd = moment(this.activeDateStart);

			// this is a hack because changing displayDate and ngModel date at the same time will only change one of them...
			setTimeout(() => {
				this.activeDateEnd = moment(this.activeDateStart);
				this.changeEndDate();
			}, 1000);			   
	   	}
   }
   
   changeEndDate() {
	   this.specialEvent.formattedEndDate = moment(this.activeDateEnd).format("DD-MM-YYYY");
   }
   
   initializePeriod() {
		this.activeDateStart = moment(this.specialEvent.start_date);
		this.activeDateEnd = moment(this.specialEvent.end_date).subtract(1, 'day');
		
		this.displayDateStart = moment(this.activeDateStart);
		this.displayDateEnd = moment(this.activeDateEnd);

		this.changeStartDate();
		this.changeEndDate();
	}

	ok(isValid) {
	   	this.submitted = true;
	   
	   	if (isValid && moment(this.activeDateStart) <= moment(this.activeDateEnd)) {
			this.isLoading = true;

			var startDate = moment(this.activeDateStart);
			var endDate = moment(this.activeDateEnd).add(1, 'day');
			startDate.locale('en');
			endDate.locale('en');
			this.specialEvent.start_date = startDate.format("ddd D MMM YYYY");
			this.specialEvent.end_date = endDate.format("ddd D MMM YYYY");
	   
		   	this.specialEvent.is_public_holiday = this.isPublicHoliday;

			if (this.specialEventId === null) {
				this.http.post("special_events", this.specialEvent).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error, '', { enableHtml: true });
				});
			} else {
				this.http.put("special_events/" + this.specialEvent.id, this.specialEvent).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			}
		}
	}

 	cancel() {
		this.bsModalRef.hide();
 	}

    ngOnInit() {
		this.minDate = moment(this.selectedSchedulePeriod.start_date);
		this.maxDate = moment(this.selectedSchedulePeriod.end_date).subtract(1, 'day');

		// TODO: DRY
		this.selectedSchedulePeriod.nameWithPeriod = this.selectedSchedulePeriod.name + " (" + moment(this.selectedSchedulePeriod.start_date).format("D MMM YYYY") + " - " + moment(this.selectedSchedulePeriod.end_date).subtract(1, 'day').format("D MMM YYYY") + ")";

		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};

		if (this.specialEventId === null) {
		   this.actionTitle = "toevoegen";
		   
		   this.specialEvent = new SpecialEvent();
	
		   this.specialEvent.start_date = moment(this.selectedSchedulePeriod.start_date);
		   this.specialEvent.end_date = moment(this.selectedSchedulePeriod.start_date).add(1, 'day');
   
		   this.initializePeriod();
	
	   } else {
		   this.actionTitle = "wijzigen";
		   
			// // TODO remove this, now necessary to avoid 'cannot ... getFullYear() of undefined', prob takes too long to give activeDateStart a value
			// this.activeDateStart = now;
			// this.activeDateEnd = tomorrow;
	
			this.http.get("special_events/" + this.specialEventId).subscribe(x => {
			   this.specialEvent = x;
	
			   this.initializePeriod();
		   });
	   }
    }
}
