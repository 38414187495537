import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './periodicCandidateForm.component.html';
import { EnvironmentService } from 'site/app/environment.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { TaskAccumulationTarget } from 'site/app/models/taskAccumulationTarget.model';
import { TrainingPeriodAccumulationTypeTarget } from 'site/app/models/trainingPeriodAccumulationTypeTarget.model';
import { TrainingPeriodAccumulationTypeBalance } from 'site/app/models/trainingPeriodAccumulationTypeBalance.model';
import { CandidateUnavailabilityPeriod } from 'site/app/models/candidateUnavailabilityPeriod.model';
import { PeriodicCandidate } from 'site/app/models/periodicCandidate.model';
import { Candidate } from 'site/app/models/candidate.model';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { UserTaskDisplayGroupMembership } from 'site/app/models/userTaskDisplayGroupMembership.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class PeriodicCandidateFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public schedulePeriodId: any;
	public periodicCandidateId;

	public isLoading: boolean;
	public user: any;
	public selectedTab: any;
	public periodicCandidate: any;
	public trainingPeriodAccumulationTypes: any;
	public overallTrainingPeriodAccumulationTypes: any;
	public totalTargetValues: any;
	public totalBalances: any;
	public candidateUnavailabilityPeriodIndex: any;
	public datepickerPopupStartEmployeeTerminationDate: any;
	public datepickerPopupStartTrainingStartDate: any;
	public submitted: boolean;
	public trainingStartDate: any;
	public employmentTerminationDate: any;
	public actionTitle: string;
	public weekdays: string[];
	public workWeekdays: { id: number; name: string; }[];
	public candidateTypes: any;
	public trainingLocations: any;
	public locations: any;
	public datePickerConfig;
	public locationId: number;
	public dayScheduleTabs: any;
	public timeSlots: any;
	public tasksForPeriodicCandidate: any;
	public regionalCandidates: any;
	public notLinkedRegionalCandidates: any;
	public taskDisplayGroups: any;
	public userTaskDisplayGroupMembershipIndex: number;
    
    constructor(
        private environmentService: EnvironmentService,
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeTab(tabNumber) {
		this.selectedTab = tabNumber;
	}

	addTaskAccumulationTargets() {
		var self = this;
		this.http.get<any>("task_accumulation_periods/index_for_shift_count_overview",
			{
				params: {
					schedule_period_id: this.schedulePeriodId
				}
			}
		).subscribe(taskAccumulationPeriods => {
			taskAccumulationPeriods.forEach(function(x) {
				if (self.periodicCandidate.candidate.task_accumulation_targets.filter(function(y) { return y.task_accumulation_period_id == x.id }).length == 0) {
					self.periodicCandidate.candidate.task_accumulation_targets.push(new TaskAccumulationTarget({ task_accumulation_period_id: x.id, task_accumulation_period: x, is_absolute: false, relative_value: 1 }));
				}
			});
			
			this.periodicCandidate.candidate.task_accumulation_targets.filter(function(x) { return !x.is_absolute; }).forEach(function(x) {
				x.percentage = x.relative_value * 100
			});
		});
	}
	
	addTrainingPeriodAccumulationTypeTargetsAndBalances() {
		var self = this;
		this.http.get("training_period_accumulation_types").subscribe(trainingPeriodAccumulationTypes => {
			this.trainingPeriodAccumulationTypes = trainingPeriodAccumulationTypes;
			
			this.periodicCandidate.trainingPeriodAccumulationTypes = {};
			
			this.trainingPeriodAccumulationTypes.forEach(function(x) {
				var trainingPeriodAccumulationTypeTargets = self.periodicCandidate.candidate.training_period_accumulation_type_targets.filter(function(y) { return y.training_period_accumulation_type_id == x.id });
				var trainingPeriodAccumulationTypeTarget;
				
				if (trainingPeriodAccumulationTypeTargets.length == 0) {
					trainingPeriodAccumulationTypeTarget = new TrainingPeriodAccumulationTypeTarget({ 
						training_period_accumulation_type_id: x.id, candidate_id: self.periodicCandidate.candidate_id, value: null });
					self.periodicCandidate.candidate.training_period_accumulation_type_targets.push(trainingPeriodAccumulationTypeTarget);
				} else {
					trainingPeriodAccumulationTypeTarget = trainingPeriodAccumulationTypeTargets[0];
				}
				
				var trainingPeriodAccumulationTypeBalances = self.periodicCandidate.candidate.training_period_accumulation_type_balances.filter(function(y) { return y.training_period_accumulation_type_id == x.id });
				var trainingPeriodAccumulationTypeBalance;
				
				if (trainingPeriodAccumulationTypeBalances.length == 0) {
					trainingPeriodAccumulationTypeBalance = new TrainingPeriodAccumulationTypeBalance({ 
						training_period_accumulation_type_id: x.id, candidate_id: self.periodicCandidate.candidate_id, value: null });
					self.periodicCandidate.candidate.training_period_accumulation_type_balances.push(trainingPeriodAccumulationTypeBalance);
				} else {
					trainingPeriodAccumulationTypeBalance = trainingPeriodAccumulationTypeBalances[0];
				}
				
				self.periodicCandidate.trainingPeriodAccumulationTypes[x.id] = {  
					targetValue: trainingPeriodAccumulationTypeTarget.value,
					balance: trainingPeriodAccumulationTypeBalance.value
				};
			});

			this.http.get("overall_training_period_accumulation_types").subscribe(overallTrainingPeriodAccumulationTypes => {
				this.overallTrainingPeriodAccumulationTypes = overallTrainingPeriodAccumulationTypes;
				
				this.refreshOverallTrainingPeriodAccumulationTypeCountsAndTotals();
			});
		});
	}
	
	refreshOverallTrainingPeriodAccumulationTypeCountsAndTotals() {
		var self = this;
		this.overallTrainingPeriodAccumulationTypes.forEach(function(x) {
			x.total = 0;
		});
		
		this.trainingPeriodAccumulationTypes.forEach(function(x) {

			var overallTrainingPeriodAccumulationType = 
				self.overallTrainingPeriodAccumulationTypes.filter(function(y) { return x.overall_training_period_accumulation_type_id == y.id; })[0];
			
			if (overallTrainingPeriodAccumulationType != null && overallTrainingPeriodAccumulationType != undefined && 
					self.periodicCandidate.trainingPeriodAccumulationTypes[x.id].targetValue != null && self.periodicCandidate.trainingPeriodAccumulationTypes[x.id].targetValue != "") {
				overallTrainingPeriodAccumulationType.total += parseFloat(self.periodicCandidate.trainingPeriodAccumulationTypes[x.id].targetValue);
			}
		});
		
		this.refreshOverallTrainingPeriodAccumulationTypeTotals();
	}
	
	refreshOverallTrainingPeriodAccumulationTypeTotals() {
		var self = this;
		this.totalTargetValues = Object.keys(this.periodicCandidate.trainingPeriodAccumulationTypes).filter(
			function(x) { return self.periodicCandidate.trainingPeriodAccumulationTypes[x].targetValue != null && self.periodicCandidate.trainingPeriodAccumulationTypes[x].targetValue != "" }).map(
			function(x) { return self.periodicCandidate.trainingPeriodAccumulationTypes[x].targetValue }).reduce(
			function(acc, val) { return acc + parseFloat(val); }, 0);

		this.totalBalances = Object.keys(this.periodicCandidate.trainingPeriodAccumulationTypes).filter(
			function(x) { return self.periodicCandidate.trainingPeriodAccumulationTypes[x].balance != null && self.periodicCandidate.trainingPeriodAccumulationTypes[x].balance != "" }).map(
			function(x) { return self.periodicCandidate.trainingPeriodAccumulationTypes[x].balance }).reduce(
			function(acc, val) { return acc + parseFloat(val); }, 0);
	}

	deleteCandidateUnavailabilityPeriod(index) {
		var matchIndex = -1;
		this.periodicCandidate.candidate.candidate_unavailability_periods.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.periodicCandidate.candidate.candidate_unavailability_periods.splice(matchIndex, 1);;
	}
	
	addCandidateUnavailabilityPeriod() {
		this.periodicCandidate.candidate.candidate_unavailability_periods.push(new CandidateUnavailabilityPeriod({ index: this.candidateUnavailabilityPeriodIndex++, start_date: null, end_date: null }));
	}

  	ok(isValid) {
		var self = this;
		this.isLoading = true;
		this.submitted = true;

		if (isValid) {
			this.periodicCandidate.candidate.task_accumulation_targets.forEach(function(x) {
				if (x.is_absolute) {
					x.relative_value = null;
				} else {
					x.relative_value = x.percentage / 100;
					x.absolute_value = null;
				}
			});

			Object.keys(this.periodicCandidate.trainingPeriodAccumulationTypes).forEach(function(trainingPeriodAccumulationTypeId) {
				self.periodicCandidate.candidate.training_period_accumulation_type_targets.filter(function(x) { 
					return x.training_period_accumulation_type_id == trainingPeriodAccumulationTypeId }
				)[0].value = self.periodicCandidate.trainingPeriodAccumulationTypes[trainingPeriodAccumulationTypeId].targetValue;
			
				self.periodicCandidate.candidate.training_period_accumulation_type_balances.filter(function(x) { 
					return x.training_period_accumulation_type_id == trainingPeriodAccumulationTypeId }
				)[0].value = self.periodicCandidate.trainingPeriodAccumulationTypes[trainingPeriodAccumulationTypeId].balance;
			});

			if (this.trainingStartDate) {
				var trainingStartDate = moment(this.trainingStartDate);
				trainingStartDate.locale('en');
				this.periodicCandidate.candidate.training_start_date = trainingStartDate.format("ddd D MMM YYYY");
			} else {
				this.periodicCandidate.candidate.training_start_date = null;
			}

			if (this.employmentTerminationDate) {
				var employmentTerminationDate = moment(this.employmentTerminationDate);
				employmentTerminationDate.locale('en');
				this.periodicCandidate.candidate.employment_termination_date = employmentTerminationDate.format("ddd D MMM YYYY");
			} else {
				this.periodicCandidate.candidate.employment_termination_date = null;
			}

			// this.periodicCandidate.candidate.candidate_unavailability_periods.forEach(function(candidateUnavailabilityPeriod) {
			// 	candidateUnavailabilityPeriod.start_date = candidateUnavailabilityPeriod.startDate.format("ddd D MMM YYYY");
			// 	candidateUnavailabilityPeriod.end_date = candidateUnavailabilityPeriod.endDate.format("ddd D MMM YYYY");
			// });

			if (this.periodicCandidateId === null) {
				this.periodicCandidate.schedule_period_id = this.schedulePeriodId;
				
				this.http.post("periodic_candidates", this.periodicCandidate).subscribe(() => {
					this.isLoading = false;
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.isLoading = false;
					this.toastr.error(response.data.error);
				});
			} else {
				this.http.put("periodic_candidates/" + this.periodicCandidate.id, this.periodicCandidate).subscribe(() => {
					this.isLoading = false;
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.isLoading = false;
					this.toastr.error(response.data.error);
				});
			}
		} else {
			this.isLoading = false;
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}

	getForPeriodicCandidateTasks() {
		var self = this;

		forkJoin([
			this.http.get("time_slots"),
			this.http.get("day_schedule_tabs"),
			this.http.get("tasks", { params: { schedule_period_id: self.schedulePeriodId, periodic_candidate_id: self.periodicCandidateId } })
		]).subscribe(data => {
			this.timeSlots = data[0];
			this.dayScheduleTabs = data[1];
			this.tasksForPeriodicCandidate = data[2];
			
			this.periodicCandidate.task_in_day_schedule = {};
			this.periodicCandidate.task_in_tab = {}
			this.timeSlots.forEach(function(timeSlot) {

				var tasksForTimeSlot = self.tasksForPeriodicCandidate.filter(function(x) { return x.time_slot_id == timeSlot.id; });
				self.periodicCandidate.task_in_day_schedule[timeSlot.id] = tasksForTimeSlot.length > 0;
				self.periodicCandidate.task_in_tab[timeSlot.id] = {}
				
				if (tasksForTimeSlot.length > 0) {
					var taskForTimeSlot = tasksForTimeSlot[0];

					self.dayScheduleTabs.forEach(function(dayScheduleTab) {
						self.periodicCandidate.task_in_tab[timeSlot.id][dayScheduleTab.id] = 
							taskForTimeSlot.task_day_schedule_tab_memberships.filter(function(x) { return x.day_schedule_tab_id == dayScheduleTab.id; }).length > 0;
					});
				}
			});

			this.isLoading = false;
		});
	}

	deleteUserTaskDisplayGroupMembership(index) {
		var matchIndex = -1;
		this.periodicCandidate.candidate.user_task_display_group_memberships_for_schedule_period.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		// hack because error lingers after it is deleted
		this.periodicCandidate.candidate.user_task_display_group_memberships_for_schedule_period[matchIndex].task_display_group_id = this.taskDisplayGroups[0].id;
		this.periodicCandidate.candidate.user_task_display_group_memberships_for_schedule_period[matchIndex].task_display_group_id = null;

		this.periodicCandidate.candidate.user_task_display_group_memberships_for_schedule_period.splice(matchIndex, 1);;
	}
	
	addUserTaskDisplayGroupMembership() {
		this.periodicCandidate.candidate.user_task_display_group_memberships_for_schedule_period.push(new UserTaskDisplayGroupMembership({ index: this.userTaskDisplayGroupMembershipIndex++ }));
	}

  	ngOnInit() {
		var self = this;

		this.locationId = 1;
		this.isLoading = true;
		this.user = this.environmentService.getUser();
		this.changeTab(1);

		if (this.periodicCandidateId === null) {
			this.periodicCandidate = new PeriodicCandidate({ 
				candidate: new Candidate({ 
					task_accumulation_targets: [], 
					training_period_accumulation_type_targets: [], 
					training_period_accumulation_type_balances: [], 
					candidate_unavailability_periods: [],
					user_task_display_group_memberships_for_schedule_period: []
				})
			});
			this.userTaskDisplayGroupMembershipIndex = 0
			this.addTaskAccumulationTargets();
			this.addTrainingPeriodAccumulationTypeTargetsAndBalances();
			this.actionTitle = "toevoegen";

			this.getForPeriodicCandidateTasks();
		} else {
			this.http.get("periodic_candidates/" + this.periodicCandidateId).subscribe(x => {
				this.periodicCandidate = x;
				this.addTaskAccumulationTargets();
				this.addTrainingPeriodAccumulationTypeTargetsAndBalances();
	
				if (this.periodicCandidate.candidate.users.length > 0) {
					this.periodicCandidate.candidate.email = this.periodicCandidate.candidate.users[0].email;
					this.periodicCandidate.candidate.is_admin = this.periodicCandidate.candidate.users[0].is_admin;
					this.periodicCandidate.candidate.is_training_admin = this.periodicCandidate.candidate.users[0].is_training_admin;
					this.periodicCandidate.candidate.has_only_access_to_own_trainees_and_location = this.periodicCandidate.candidate.users[0].has_only_access_to_own_trainees_and_location;
					this.periodicCandidate.candidate.has_only_access_to_task_display_groups = this.periodicCandidate.candidate.users[0].has_only_access_to_task_display_groups;
					this.periodicCandidate.candidate.user_task_display_group_memberships_for_schedule_period = this.periodicCandidate.candidate.users[0].user_task_display_group_memberships_for_schedule_period;

					this.periodicCandidate.candidate.user_task_display_group_memberships_for_schedule_period.forEach(function(userTaskDisplayGroupMembership, index) {
						userTaskDisplayGroupMembership.index = index;
					});
	
					this.userTaskDisplayGroupMembershipIndex = this.periodicCandidate.candidate.user_task_display_group_memberships_for_schedule_period.length;	
				} else {
					this.periodicCandidate.candidate.user_task_display_group_memberships_for_schedule_period = [];
				}
	
				if (this.periodicCandidate.candidate.training_start_date != null) {
					this.trainingStartDate = moment(this.periodicCandidate.candidate.training_start_date);
				}
	
				if (this.periodicCandidate.candidate.employment_termination_date != null) {
					this.employmentTerminationDate = moment(this.periodicCandidate.candidate.employment_termination_date);
				}
				
				this.periodicCandidate.candidate.candidate_unavailability_periods.forEach(function(candidateUnavailabilityPeriod, index) {
					candidateUnavailabilityPeriod.index = index;
					candidateUnavailabilityPeriod.startDate = moment(candidateUnavailabilityPeriod.start_date);
					candidateUnavailabilityPeriod.endDate = moment(candidateUnavailabilityPeriod.end_date);
				});
	
				this.candidateUnavailabilityPeriodIndex = this.periodicCandidate.candidate.candidate_unavailability_periods.length;
				
				this.getForPeriodicCandidateTasks();

				if (this.user.customer.regional_customer_id != null) {
					this.http.get("candidates", 
						{
							params: {
								location_id: this.user.customer.regional_location_id
							}
						}
					).subscribe(y => {
						this.regionalCandidates = y;
						this.regionalCandidates.forEach(function(candidate) {
							candidate.fullName = (new Candidate(candidate)).fullName();
						});

						this.notLinkedRegionalCandidates = this.regionalCandidates.filter(function(z) { return !z.is_linked_to_candidate; });
						
						if (this.periodicCandidate.candidate.regional_candidate_id) {
							this.periodicCandidate.candidate.regionalCandidate = 
								this.regionalCandidates.filter(function(z) { return z.id == self.periodicCandidate.candidate.regional_candidate_id; })[0];
						}
					});
				}
			});

			this.actionTitle = "wijzigen";
		}
		
		this.weekdays = ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"];
		this.workWeekdays = this.weekdays.slice(1, 6).map(function(x, index) { return { id: index + 1, name: x }; });
	
		this.http.get("candidate_types").subscribe(candidateTypes => {
			this.candidateTypes = candidateTypes;
		});
	
		this.http.get("locations", { params: { is_training: 'true' } }).subscribe(trainingLocations => {
			this.trainingLocations = trainingLocations;
		});

		this.http.get("locations").subscribe(locations => {
			this.locations = locations;
		});
	
		this.datePickerConfig = {
			firstDayOfWeek: "mo"
		};

		this.http.get("task_display_groups", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(taskDisplayGroups => {
            this.taskDisplayGroups = taskDisplayGroups;
		});
	}
}

