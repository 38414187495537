import { Component, OnInit } from '@angular/core';
import templateString from './accumulationPeriods.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AccumulationPeriodFormComponent } from '../accumulationPeriodForm';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class AccumulationPeriodsComponent implements OnInit {
    public accumulationPeriods: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService
	) { }

	refresh() {
        this.http.get("accumulation_periods").subscribe(x => {
			this.accumulationPeriods = x;
			
			for(var i=0; i < this.accumulationPeriods.length; i++) {
				var accumulationPeriod = this.accumulationPeriods[i];

				accumulationPeriod.formattedSchedulePeriods = accumulationPeriod.schedule_periods.map(function(x) { return x.name }).join(", ");
			}
		});
	}

    create() {
        var bsModalRef = this.bsModalService.show(AccumulationPeriodFormComponent, {
            initialState: {
                accumulationPeriodId: null
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(accumulationPeriodId) {
        var bsModalRef = this.bsModalService.show(AccumulationPeriodFormComponent, {
            initialState: {
                accumulationPeriodId: accumulationPeriodId
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(accumulationPeriodId) {
		if (window.confirm('Weet je het zeker?')) {
            this.http.delete("accumulation_periods/" + accumulationPeriodId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
		}
	};

    ngOnInit() {
        this.refresh();
    }
}
