import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './dayScheduleTabForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DayScheduleTab } from 'site/app/models/dayScheduleTab.model';
import { TaskDayScheduleTabMembership } from 'site/app/models/taskDayScheduleTabMembership.model';
import { Task } from 'site/app/models/task.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class DayScheduleTabFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public dayScheduleTabId: any;
	public schedulePeriodId: any;

	public dayScheduleTab: any;
	public submitted: boolean;	
	public actionTitle: string;
	public tasks: any;
	public isLoading: boolean;
	public taskDayScheduleTabMembershipIndex: number;
	public candidateTypes: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteCandidateTypeId(index) {
		this.dayScheduleTab.candidate_type_ids.splice(index, 1);;
	}
	
	addCandidateTypeId() {
		this.dayScheduleTab.candidate_type_ids.push(null);
	}

	deleteTaskDayScheduleTabMembership(index) {
		var matchIndex = -1;
		this.dayScheduleTab.task_day_schedule_tab_memberships_for_schedule_period.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		// hack because error lingers after it is deleted
		this.dayScheduleTab.task_day_schedule_tab_memberships_for_schedule_period[matchIndex].task_id = this.tasks[0].id;
		this.dayScheduleTab.task_day_schedule_tab_memberships_for_schedule_period[matchIndex].task_id = null;

		this.dayScheduleTab.task_day_schedule_tab_memberships_for_schedule_period.splice(matchIndex, 1);;
	}
	
	addTaskDayScheduleTabMembership() {
		this.dayScheduleTab.task_day_schedule_tab_memberships_for_schedule_period.push(new TaskDayScheduleTabMembership({ index: this.taskDayScheduleTabMembershipIndex++ }));
	}

  	ok(isValid) {
		this.submitted = true;

		if (isValid) {
			if (this.dayScheduleTabId === null) {
				this.http.post("day_schedule_tabs",
					{
						day_schedule_tab: this.dayScheduleTab,
						schedule_period_id: this.schedulePeriodId
					}
				).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			} else {
				this.http.put("day_schedule_tabs/" + this.dayScheduleTab.id,
					{
						day_schedule_tab: this.dayScheduleTab,
						schedule_period_id: this.schedulePeriodId
					}
				).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}
	
    trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		if (this.dayScheduleTabId === null) {
			this.actionTitle = "toevoegen";
			this.dayScheduleTab = new DayScheduleTab();
			this.dayScheduleTab.candidate_type_ids = [];
			this.dayScheduleTab.task_day_schedule_tab_memberships_for_schedule_period = [];
			this.taskDayScheduleTabMembershipIndex = 0;		
		} else {
			this.actionTitle = "wijzigen";
			this.http.get<any>("day_schedule_tabs/" + this.dayScheduleTabId,
				{
					params: {
						schedule_period_id: this.schedulePeriodId
					}
				}
			).subscribe(x => {
				this.dayScheduleTab = x;

				this.dayScheduleTab.candidate_type_ids = this.dayScheduleTab.candidate_types.map(function(y) { return y.id; });

				this.dayScheduleTab.task_day_schedule_tab_memberships_for_schedule_period.forEach(function(taskDayScheduleTabMembership, index) {
					taskDayScheduleTabMembership.index = index;
				});
				
				this.taskDayScheduleTabMembershipIndex = x.task_day_schedule_tab_memberships_for_schedule_period.length;
			});
		}

		this.http.get("candidate_types").subscribe(x => {
			this.candidateTypes = x;
		});
		
		this.http.get("tasks", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(tasks => {
			this.tasks = tasks;
	
			this.tasks.forEach(function(task) {
				task.computedNameWithEquivalencePosition = (new Task(task)).getComputedName() + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
			});
	
			this.isLoading = false;
		});
    }
}
