import { Component, OnInit } from '@angular/core';
import templateString from './candidateTypes.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CandidateTypeFormComponent } from '../candidateTypeForm';
import { CandidateTypeChangeOrderComponent } from '../candidateTypeChangeOrder';
import { DataService } from 'site/app/data.service';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class CandidateTypesComponent implements OnInit {
    
    public candidateTypes: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private dataService: DataService,
        private environmentService: EnvironmentService
	) { }

    refresh() {
        this.http.get("candidate_types").subscribe(x => {
            this.candidateTypes = x;
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(CandidateTypeFormComponent, {
            initialState: {
                candidateTypeId: null
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(candidateTypeId) {
        var bsModalRef = this.bsModalService.show(CandidateTypeFormComponent, {
            initialState: {
                candidateTypeId: candidateTypeId
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(candidateTypeId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("candidate_types/" + candidateTypeId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(CandidateTypeChangeOrderComponent, {
            initialState: {
                originalCandidateTypes: this.candidateTypes
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    getColor(candidateType) {
        return this.dataService.colors[candidateType.color_id];
    }

    initialize() {
		this.refresh();
	}
	
    ngOnInit() {
        this.initialize();
    }
}
