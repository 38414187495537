import { Component, OnInit } from '@angular/core';
import templateString from './specialEvents.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { SpecialEventFormComponent } from '../specialEventForm';
import { takeUntil } from 'rxjs/operators';
import { EnvironmentService } from 'site/app/environment.service';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class SpecialEventsComponent implements OnInit {
    public destroyed$: any;
    public selectedSchedulePeriod: any;
    public isPublicHoliday: any;
    public specialEvents: any;
    public isLoading: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService,
        private route: ActivatedRoute
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        this.http.get("special_events",
            { 
                params: {
                    schedule_period_id: this.selectedSchedulePeriod.id, 
                    is_public_holiday: this.isPublicHoliday    
                }
            }
        ).subscribe(x => {
            this.specialEvents = x;
            
            for(var i=0; i < this.specialEvents.length; i++) {
                var specialEvent = this.specialEvents[i];

                specialEvent.formattedPeriod = moment(specialEvent.start_date).isSame(moment(specialEvent.end_date).add(-1, 'days')) ? 
                    moment(specialEvent.start_date).format('dd D MMM YYYY') : 
                    moment(specialEvent.start_date).format('dd D MMM YYYY') + " t/m " + moment(specialEvent.end_date).add(-1, 'days').format('dd D MMM YYYY');
            }

            this.isLoading = false;
        });
    }

    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(SpecialEventFormComponent, {
            initialState: {
                specialEventId: null,
                isPublicHoliday: this.isPublicHoliday,
                selectedSchedulePeriod: this.selectedSchedulePeriod
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(specialEventId) {
        var bsModalRef = this.bsModalService.show(SpecialEventFormComponent, {
            initialState: {
                specialEventId: specialEventId,
                isPublicHoliday: this.isPublicHoliday,
                selectedSchedulePeriod: this.selectedSchedulePeriod
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(specialEventId) {
        if (window.confirm('Weet je het zeker?')) {
            this.isLoading = true;

            this.http.delete("special_events/" + specialEventId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };

    initialize() {
        this.isLoading = true;

        this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

        this.refresh();
    }

    ngOnInit() {
        this.route.queryParams.subscribe(
            params => {
                this.isPublicHoliday = params["is_public_holiday"] == 'true';
                this.initialize();
            }
        );

        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
