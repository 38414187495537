import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './taskTypeForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { DataService } from 'site/app/data.service';
import { TaskType } from 'site/app/models/taskType.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskTypeFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public taskTypeId: any;

	public taskType: any;
	public submitted: boolean;	
	public actionTitle: string;
	public colors: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
		private toastr: ToastrService,
		private dataService: DataService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.taskTypeId === null) {
				this.http.post("task_types", this.taskType).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			} else {
				this.http.put("task_types/" + this.taskType.id, this.taskType).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}

    ngOnInit() {
		this.colors = this.dataService.colors;

		if (this.taskTypeId === null) {
			this.actionTitle = "toevoegen";
			this.taskType = new TaskType({ is_available: true, is_side_activity: false });
		} else {
			this.actionTitle = "wijzigen";
			this.http.get("task_types/" + this.taskTypeId).subscribe(x => {
				this.taskType = x;
			});
		}
    }
}
