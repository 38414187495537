import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './locationForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class LocationFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public locationId: any;

	public location: any;
	public submitted: boolean;	
	public actionTitle: string;
	public tasks: any;
	public isLoading: boolean;
	public selectedTab: any;
	public trainingPeriodAccumulationTypes: any;
	public dates: any[];
	public locationCapacities: {};

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	changeTab(tabNumber) {
		this.selectedTab = tabNumber;
	}

  	ok(isValid) {
		var self = this;

		this.isLoading = true;
		this.submitted = true;
		
		if (isValid) {
			if (this.locationId === null) {
				this.http.post("locations", this.location).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			} else {
				this.http.put("locations/" + this.location.id, this.location).subscribe(() => {

					var locationCapacitiesForUpdate = [];
		
					Object.keys(this.locationCapacities).forEach(function(trainingPeriodAccumulationTypeId) {
						Object.keys(self.locationCapacities[trainingPeriodAccumulationTypeId]).forEach(function(date) {
							locationCapacitiesForUpdate.push(self.locationCapacities[trainingPeriodAccumulationTypeId][date]);
						});
					});
			
					this.http.post("locations/update_location_capacities",
						{ 
							location_capacities: locationCapacitiesForUpdate 
						}
					).subscribe(() => {
						this.action.emit(true);
						this.bsModalRef.hide();
					});
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}

    ngOnInit() {
		var self = this;

		this.changeTab(1);

		if (this.locationId === null) {
			this.actionTitle = "toevoegen";
			this.location = new Location();
		} else {
			this.actionTitle = "wijzigen";
			
			forkJoin([
				this.http.get("locations/" + this.locationId),
				this.http.get("training_period_accumulation_types")
			]).subscribe(data => {
				this.location = data[0];
				this.trainingPeriodAccumulationTypes = data[1];

				this.dates = [];
				var currentDate = moment([2019, 0, 1]);
				while (currentDate < moment([2026, 0, 1])) {
					this.dates.push(currentDate.format("MMM YYYY"));
					currentDate = moment(currentDate).add(1, 'month');
				}

				this.locationCapacities = {};
				this.location.location_capacities.forEach(function(locationCapacity) {
					if (self.locationCapacities[locationCapacity.training_period_accumulation_type_id] == null) {
						self.locationCapacities[locationCapacity.training_period_accumulation_type_id] = {};
					}
					
					self.locationCapacities[locationCapacity.training_period_accumulation_type_id][moment(locationCapacity.date).format("MMM YYYY")] = locationCapacity;
				});
			});
		}
    }
}
