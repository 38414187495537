import { Component, OnInit } from '@angular/core';
import templateString from './reports.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReportFormComponent } from '../reportForm';
import { ReportPartsChangeOrderComponent } from '../reportPartsChangeOrder';
import { ReportChangeOrderComponent } from '../reportChangeOrder';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ReportsComponent implements OnInit {
    
    public reports: any;
    public reportPartTypes: { id: number; name: string; }[];

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) {  }

    refresh() {
        this.http.get("reports").subscribe(x => {
            this.reports = x;
        });
    }

    create() {
        var bsModalRef = this.bsModalService.show(ReportFormComponent, {
            initialState: {
                reportId: null
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(reportId) {
        var bsModalRef = this.bsModalService.show(ReportFormComponent, {
            initialState: {
                reportId: reportId
            },
            class: 'xlModal'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(reportId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("reports/" + reportId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };

    changeOrder() {
        var bsModalRef = this.bsModalService.show(ReportChangeOrderComponent, {
            initialState: {
                originalReports: this.reports
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    changeReportPartsOrder(report) {
        var bsModalRef = this.bsModalService.show(ReportPartsChangeOrderComponent, {
            initialState: {
                originalReportParts: report.report_parts
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
		this.refresh();
	}
	
    ngOnInit() {
        // TODO: DRY
		this.reportPartTypes = [
			{ id: 1, name: "Activiteiten werkdagen" },
			{ id: 2, name: "Afwezigheid werkdagen" },
			{ id: 3, name: "Totaal werkdagen" },
			{ id: 4, name: "Sommatiegroepen activiteiten" },
		];

        this.initialize();
    }
}
