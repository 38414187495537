import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { TrainingPeriod } from './models/trainingPeriod.model';

@Injectable({ providedIn: 'root' })
export class DataService {
	public colors = [
		"white", 
		"rgba(26, 179, 148, 0.5)", 
		"rgba(35, 198, 200, 0.5)", 
		"rgba(28, 132, 198, 0.5)", 
		"rgba(180, 180, 180, 0.5)" //,
		// "#b879d3",
		// "#4a44bd",
		// "#d3c579"
	];

	public colorsRegional = [
		"rgba(177, 156, 70, 0.4)",
		"rgba(94,201,180, 0.5)",
		"rgba(94,201,180, 1)",
		"rgba(169,132,214, 0.4)",
		"rgba(169,132,214, 0.7)",
		"rgba(95,168,215, 0.4)",
		"rgba(95,168,215, 0.7)",
		"#DDD",
		"#BBB",
		"rgba(100,215,216, 0.5)",
		"rgba(100,215,216, 1)",
	];

	constructor() { }

	createTemplatePeriodAccumulationTypeCount(templatePeriodAccumulationTypeCount) {
		var colorClass = "availableCandidateCount";
		var addition = "";
	
		if (
			(
				templatePeriodAccumulationTypeCount.minimum_required_amount != null &&
				templatePeriodAccumulationTypeCount.min_value < templatePeriodAccumulationTypeCount.minimum_required_amount
			) ||
			(
				templatePeriodAccumulationTypeCount.maximum_required_amount != null &&
				templatePeriodAccumulationTypeCount.max_value > templatePeriodAccumulationTypeCount.maximum_required_amount
			)
		) {
			colorClass = "templatePeriodAccumulationTypeCountViolation";
			addition = " (vereist: " +
				(templatePeriodAccumulationTypeCount.minimum_required_amount == null ? "0" : templatePeriodAccumulationTypeCount.minimum_required_amount) +
				".." +
				(templatePeriodAccumulationTypeCount.maximum_required_amount == null ? "" : templatePeriodAccumulationTypeCount.maximum_required_amount) +
			")";
		}
	
		var ganttTask = {
			name: templatePeriodAccumulationTypeCount.min_value == templatePeriodAccumulationTypeCount.max_value ?
				templatePeriodAccumulationTypeCount.min_value.toString() :
				templatePeriodAccumulationTypeCount.min_value.toString() + "-" + templatePeriodAccumulationTypeCount.max_value.toString(),
			from: templatePeriodAccumulationTypeCount.start_date,
			to: templatePeriodAccumulationTypeCount.end_date,
			classes: colorClass,
			color: "transparent",
			tooltipTitle: (templatePeriodAccumulationTypeCount.min_value == templatePeriodAccumulationTypeCount.max_value ?
				templatePeriodAccumulationTypeCount.min_value.toString() :
				templatePeriodAccumulationTypeCount.min_value.toString() + "-" + templatePeriodAccumulationTypeCount.max_value.toString()) + addition,
			tooltipSubtitle: "# " + templatePeriodAccumulationTypeCount.name,
			dateLabel: moment(templatePeriodAccumulationTypeCount.start_date).format('MMMM')
		}

		this.addAsJson(ganttTask);
		
		return ganttTask;
	}

	createTrainingPeriodAccumulationTypeCount(trainingPeriodAccumulationTypeCount) {
		var colorClass = "availableCandidateCount";
		var addition = "";
		var showValueIfZero = false;
	
		if (trainingPeriodAccumulationTypeCount.location_capacity != null) {
			addition = " (" + 
				trainingPeriodAccumulationTypeCount.location_capacity.min + ".." + 
				trainingPeriodAccumulationTypeCount.location_capacity.target + ".." + 
				trainingPeriodAccumulationTypeCount.location_capacity.max + 
			")";

			if (
				trainingPeriodAccumulationTypeCount.occupation_amount > trainingPeriodAccumulationTypeCount.location_capacity.max ||
				trainingPeriodAccumulationTypeCount.occupation_amount < trainingPeriodAccumulationTypeCount.location_capacity.min
			) {
				colorClass = "trainingPeriodAccumulationTypeCountViolation";
				showValueIfZero = true;
			} else if (
				trainingPeriodAccumulationTypeCount.occupation_amount != trainingPeriodAccumulationTypeCount.location_capacity.target
			) {
				showValueIfZero = true;
				colorClass = "trainingPeriodAccumulationTypeCountWarning";
			}

			var occupationAmountAddition = null;
			var isOccupationAmountImprovement = null;

			if (trainingPeriodAccumulationTypeCount.occupation_amount_other) {
				if (trainingPeriodAccumulationTypeCount.occupation_amount > trainingPeriodAccumulationTypeCount.occupation_amount_other) {
					isOccupationAmountImprovement = trainingPeriodAccumulationTypeCount.occupation_amount <= trainingPeriodAccumulationTypeCount.location_capacity.target;
					occupationAmountAddition = "+" + (trainingPeriodAccumulationTypeCount.occupation_amount - trainingPeriodAccumulationTypeCount.occupation_amount_other).toString()
					// colorClass += " trainingPeriodAccumulationTypeOccupationChanged";
				} else if (trainingPeriodAccumulationTypeCount.occupation_amount < trainingPeriodAccumulationTypeCount.occupation_amount_other) {
					isOccupationAmountImprovement = trainingPeriodAccumulationTypeCount.occupation_amount >= trainingPeriodAccumulationTypeCount.location_capacity.target;
					occupationAmountAddition = "-" + (trainingPeriodAccumulationTypeCount.occupation_amount_other - trainingPeriodAccumulationTypeCount.occupation_amount).toString()
					// colorClass += " trainingPeriodAccumulationTypeOccupationChanged";
				}

				if (trainingPeriodAccumulationTypeCount.occupation_amount > trainingPeriodAccumulationTypeCount.occupation_amount_other ||
						trainingPeriodAccumulationTypeCount.occupation_amount < trainingPeriodAccumulationTypeCount.occupation_amount_other) {
					if (isOccupationAmountImprovement) {
						colorClass += " trainingPeriodAccumulationTypeOccupationImproved";
					} else {
						colorClass += " trainingPeriodAccumulationTypeOccupationDeteriorated";
					}
				}
			}
		}

		var ganttTask = {
			name: (trainingPeriodAccumulationTypeCount.occupation_amount > 0 || showValueIfZero ? trainingPeriodAccumulationTypeCount.occupation_amount : ""),
			from: trainingPeriodAccumulationTypeCount.start_date,
			to: moment(trainingPeriodAccumulationTypeCount.start_date).add(1, 'week').format('YYYY-MM-DD'),
			classes: colorClass,
			color: "transparent",
			tooltipTitle: trainingPeriodAccumulationTypeCount.occupation_amount,
			tooltipSubtitle: addition,
			dateLabel: 'week ' + moment(trainingPeriodAccumulationTypeCount.start_date).week(),
			ganttTaskType: "locationViolationCount",
			occupationAmountAddition: occupationAmountAddition,
			isOccupationAmountImprovement: isOccupationAmountImprovement
		}

		this.addAsJson(ganttTask);
		
		return ganttTask;
	}
	
	createShiftAccumulationTypeCount(shiftAccumulationTypeCount) {
		var colorClass = "availableCandidateCount";
		var addition = "";

		if (
			(
				shiftAccumulationTypeCount.minimum_required_amount != null &&
				shiftAccumulationTypeCount.value < shiftAccumulationTypeCount.minimum_required_amount
			) ||
			(
				shiftAccumulationTypeCount.maximum_required_amount != null &&
				shiftAccumulationTypeCount.value > shiftAccumulationTypeCount.maximum_required_amount
			)
		) {
			colorClass = "shiftAccumulationTypeCountViolation";
			addition = " (vereist: " +
				(shiftAccumulationTypeCount.minimum_required_amount == null ? "0" : shiftAccumulationTypeCount.minimum_required_amount) +
				".." +
				(shiftAccumulationTypeCount.maximum_required_amount == null ? "" : shiftAccumulationTypeCount.maximum_required_amount) +
			")";
		}

		var ganttTask = { 
			ganttTaskType: "shiftAccumulationTypeCount",
			name: shiftAccumulationTypeCount.value == 0 ? "" : shiftAccumulationTypeCount.value.toString(),
			from: shiftAccumulationTypeCount.start_date,
			to: shiftAccumulationTypeCount.end_date,
			classes: colorClass,
			color: "transparent",
			tooltipTitle: addition,
			tooltipSubtitle: shiftAccumulationTypeCount.name,
			dateLabel: moment(shiftAccumulationTypeCount.start_date).format('D MMM'),
			candidateNames: shiftAccumulationTypeCount.candidate_names
		};
		
		this.addAsJson(ganttTask);
		
		return ganttTask;
	}

	// dataService.createCandidatesAvailableTrainingScheduleCount = function(unassignedCandidateCount) {
	// 	return {
	// 		ganttTaskType: "unassignedCandidateCount",
	// 		name: unassignedCandidateCount.value,
	// 		from: unassignedCandidateCount.start_date,
	// 		to: unassignedCandidateCount.end_date,
	// 		classes: "availableCandidateCount",
	// 		color: "transparent",
	// 		unassignedCandidateCount: unassignedCandidateCount.value,
	// 		candidateNames: unassignedCandidateCount.candidate_names,
	// 		dateLabel: moment(unassignedCandidateCount.start_date).format('D MMM')
	// 	}
	// }

	createUnassignedCandidateCount(unassignedCandidateCount) {
		var ganttTask = {
			ganttTaskType: "unassignedCandidateCount",
			name: unassignedCandidateCount.value,
			from: unassignedCandidateCount.start_date,
			to: unassignedCandidateCount.end_date,
			classes: "unassignedCandidateCount",
			color: "transparent",
			unassignedCandidateCount: unassignedCandidateCount.value,
			candidateNames: unassignedCandidateCount.candidate_names,
			dateLabel: moment(unassignedCandidateCount.start_date).format('D MMM')
		};
		
		this.addAsJson(ganttTask);
		
		return ganttTask;
	}
	
	createUnassignedShiftCount(unassignedShiftCount) {
		var ganttTask = {
			ganttTaskType: "unassignedShiftCount",
			name: unassignedShiftCount.value,
			from: unassignedShiftCount.start_date,
			to: unassignedShiftCount.end_date,
			classes: "unassignedShiftCount",
			color: "transparent",
			unassignedShiftCount: unassignedShiftCount.value,
			taskNames: unassignedShiftCount.task_names,
			dateLabel: moment(unassignedShiftCount.start_date).format('D MMM')
		};
		
		this.addAsJson(ganttTask);
		
		return ganttTask;
	}

	createLocationViolationCount(locationViolationCount) {
		var ganttTask = {
			ganttTaskType: "locationViolationCount",
			name: locationViolationCount.value == 0 ? "" : locationViolationCount.value,
			from: locationViolationCount.start_date,
			to: locationViolationCount.end_date,
			classes: "locationViolationCount",
			color: "transparent",
			unassignedCandidateCount: locationViolationCount.value,
			candidateNames: locationViolationCount.candidate_names,
			dateLabel: moment(locationViolationCount.start_date).format('D MMM') +
				(moment(locationViolationCount.end_date).subtract(1, 'day').isSame(moment(locationViolationCount.start_date)) ? "" : " t/m " + moment(locationViolationCount.end_date).subtract(1, 'day').format('D MMM')),
			tooltipTitle: locationViolationCount.value == 0 ? "" : locationViolationCount.value + " overtreding" + (locationViolationCount.value > 1 ? "en" : "")
		}

		this.addAsJson(ganttTask);
		
		return ganttTask;
	}
	
	createChangesCount(unassignedCandidateCount) {
		var ganttTask = {
			ganttTaskType: "changesCount",
			name: unassignedCandidateCount.value == 0 ? "" : unassignedCandidateCount.value,
			from: unassignedCandidateCount.start_date,
			to: unassignedCandidateCount.end_date,
			classes: "locationViolationCount",
			color: "transparent",
			unassignedCandidateCount: unassignedCandidateCount.value,
			candidateNames: unassignedCandidateCount.candidate_names,
			dateLabel: moment(unassignedCandidateCount.start_date).format('D MMM') +
				(moment(unassignedCandidateCount.end_date).subtract(1, 'day').isSame(moment(unassignedCandidateCount.start_date)) ? "" : " t/m " + moment(unassignedCandidateCount.end_date).subtract(1, 'day').format('D MMM')),
			tooltipTitle: unassignedCandidateCount.value == 0 ? "" : unassignedCandidateCount.value + " wijziging(en)"
		}

		this.addAsJson(ganttTask);
		
		return ganttTask;
	}
	
	createSplitsCount(unassignedCandidateCount) {
		var ganttTask = {
			ganttTaskType: "changesCount",
			name: unassignedCandidateCount.value == 0 ? "" : unassignedCandidateCount.value,
			from: unassignedCandidateCount.start_date,
			to: unassignedCandidateCount.end_date,
			classes: "locationViolationCount",
			color: "transparent",
			unassignedCandidateCount: unassignedCandidateCount.value,
			candidateNames: unassignedCandidateCount.candidate_names,
			dateLabel: moment(unassignedCandidateCount.start_date).format('D MMM') +
				(moment(unassignedCandidateCount.end_date).subtract(1, 'day').isSame(moment(unassignedCandidateCount.start_date)) ? "" : " t/m " + moment(unassignedCandidateCount.end_date).subtract(1, 'day').format('D MMM')),
			tooltipTitle: unassignedCandidateCount.value == 0 ? "" : unassignedCandidateCount.value + " opdeling(en)"
		}

		this.addAsJson(ganttTask);
		
		return ganttTask;
	}
	
	createTrainingPeriodTaskForCandidate(trainingPeriod, candidate, isRegionalCustomer) {
		trainingPeriod.candidate = candidate;

		var id = this.generateId();
		trainingPeriod.ganttTaskId = id;
	
		var ratioAddendum = trainingPeriod.ratio == 1 ? "" : trainingPeriod.ratio;
		var locationAddendum = isRegionalCustomer ? (trainingPeriod.location_id == null || trainingPeriod.location_id == "" ? "-" : trainingPeriod.location.alias) : "";
		var isLockedAddendum = trainingPeriod.is_locked ? " (DEFINITIEF)" : "";
		var preferenceAddendum = " [" + trainingPeriod.preferred_order + ", " + (trainingPeriod.preferred_location ? trainingPeriod.preferred_location.name : "-") + "]";
		var subTypeAddendum = trainingPeriod.sub_type_id ? " (" + (new TrainingPeriod(trainingPeriod)).getSubTypeName() + ")" : "";

		// prevent circular error json
		candidate.training_periods = null;

		var colorClass = "";
		if (isRegionalCustomer) {
			colorClass = this.getBackgroundColorClassRegional(trainingPeriod.training_period_accumulation_type.color_id);
		} else {
			colorClass = this.getBackgroundColorClass(trainingPeriod.training_period_accumulation_type.color_id);
		}
			
		var ganttTask = {
			id: id,
			name: trainingPeriod.training_period_accumulation_type.alias,
			locationAddendum: locationAddendum,
			ratioAddendum: ratioAddendum,
			subTypeAddendum: subTypeAddendum,
			color: "transparent",
			classes: colorClass + " assignModalRegularTask" + (trainingPeriod.is_changed ? " changedTrainingPeriod" : ""),
			from: trainingPeriod.start_date,
			to: trainingPeriod.end_date,
			trainingPeriod: trainingPeriod,
			candidate: candidate,
			tooltipTitle: trainingPeriod.training_period_accumulation_type.name  + (isRegionalCustomer ? " (" + (trainingPeriod.location_id == null || trainingPeriod.location_id == "" ? "<niet toegewezen aan locatie>" : trainingPeriod.location.alias) + ")" : "") + isLockedAddendum + preferenceAddendum + subTypeAddendum,
			dateLabel: moment(trainingPeriod.start_date).format('D MMM YYYY') +
				(moment(trainingPeriod.end_date).subtract(1, 'day').isSame(moment(trainingPeriod.start_date)) ? "" : " t/m " + moment(trainingPeriod.end_date).subtract(1, 'day').format('D MMM YYYY')),
			ganttTaskType: "trainingPeriod",
			movable: {
				enabled: !trainingPeriod.is_locked
			},
			unavailability_periods: trainingPeriod.unavailability_periods,
			isLocked: trainingPeriod.is_locked,
			isBindingLocationPreference: trainingPeriod.is_binding_location_preference
		};

		this.addAsJson(ganttTask);
		
		return ganttTask;
	}
	
	createCandidateUnavailabilityPeriod(candidateUnavailabilityPeriod) {
		var ganttTask = {
			name: "Afwezig",
			color: "transparent",
			classes: "assignModalRegularTask violationShiftOptional",
			from: candidateUnavailabilityPeriod.start_date,
			to: candidateUnavailabilityPeriod.end_date,
			tooltipTitle: candidateUnavailabilityPeriod.description,
			dateLabel: moment(candidateUnavailabilityPeriod.start_date).format('D MMM YYYY') +
				(moment(candidateUnavailabilityPeriod.end_date).subtract(1, 'day').isSame(moment(candidateUnavailabilityPeriod.start_date)) ? "" : " t/m " + moment(candidateUnavailabilityPeriod.end_date).subtract(1, 'day').format('D MMM YYYY')),
			ganttTaskType: "candidateUnavailabilityPeriod",
			movable: {
				enabled: false
			}
		};

		this.addAsJson(ganttTask);
		
		return ganttTask;
	}
	
	createTemplatePeriodTaskForPeriodicCandidate(templatePeriod, periodicCandidate) {
		templatePeriod.periodicCandidate = periodicCandidate;
	
		var id = this.generateId();
		templatePeriod.ganttTaskId = id;

		var ganttTask = {
			id: id,
			name: templatePeriod.task.name,
			color: "transparent",
			classes: this.getBackgroundColorClass(templatePeriod.task.task_type.color_id) + " assignModalRegularTask",
			from: templatePeriod.start_date,
			to: templatePeriod.end_date,
			templatePeriod: templatePeriod,
			periodicCandidate: periodicCandidate,
			tooltipTitle: templatePeriod.task.name,
			tooltipSubtitle: periodicCandidate.infixWithLastname,
			dateLabel: moment(templatePeriod.start_date).format('D MMM') +
				(moment(templatePeriod.end_date).subtract(1, 'day').isSame(moment(templatePeriod.start_date)) ? "" : " t/m " + moment(templatePeriod.end_date).subtract(1, 'day').format('D MMM'))
		};

		this.addAsJson(ganttTask);
		
		return ganttTask;
	}
	
	createTrainingPeriodTaskForPeriodicCandidate(trainingPeriod, periodicCandidate) {
		trainingPeriod.periodicCandidate = periodicCandidate;
	
		var ratioAddendum = trainingPeriod.ratio == 1 ? "" : " [" + trainingPeriod.ratio + "]"; // TODO: DRY
		var locationAddendum = trainingPeriod.location ? " (" + trainingPeriod.location.name + ")" : "";
	
		var ganttTask = {
			name: trainingPeriod.training_period_accumulation_type.name + ratioAddendum + locationAddendum,
			color: "transparent",
			classes: "trainingPeriodInTemplateSchedule",
			from: trainingPeriod.start_date,
			to: trainingPeriod.end_date,
			trainingPeriod: trainingPeriod,
			periodicCandidate: periodicCandidate,
			tooltipTitle: trainingPeriod.training_period_accumulation_type.name + ratioAddendum,
			tooltipSubtitle: periodicCandidate.infixWithLastname,
			dateLabel: moment(trainingPeriod.start_date).format('D MMM') +
				(moment(trainingPeriod.end_date).subtract(1, 'day').isSame(moment(trainingPeriod.start_date)) ? "" : " t/m " + moment(trainingPeriod.end_date).subtract(1, 'day').format('D MMM'))
		};

		this.addAsJson(ganttTask);
		
		return ganttTask;
	}

	createScheduleTaskFromShift(shift, task, viewTypeId, showMobileView, scheduleFromDate, scheduleToDate) {

		var startDate = moment(shift.start_date);
		var endDate = moment(shift.end_date);
		var isShort = endDate.diff(startDate, 'days') <= 2 && (viewTypeId == 3 || (showMobileView && viewTypeId == 2));

		var contentInBlock = "";
		var classes = [];
		var sectionItems = [];
		var tooltipTitle;
		
		if (shift.is_cloned_shift) {
			contentInBlock = isShort ? shift.task_alias_short : shift.task_name;
			classes.push("clonedShift");
		} else if (shift.task_periodic_candidate_id == null) {
			if (shift.periodic_candidate_id != null) {
				contentInBlock = isShort ? shift.periodic_candidate_alias : shift.candidate_name;
			}
		} else {
			if (shift.task_variant_name != null) {
				contentInBlock = isShort ? shift.task_variant_alias : shift.task_variant_name;
			}
		}

		var backgroundColorClass;
		var isAllocationRequest = shift.task_id == null;

		if (shift.task_periodic_candidate_id == null) {
			if (shift.periodic_candidate_id != null) {

				// if shift is a replaced allocation request part (task_id == null)
				if (isAllocationRequest) {
					backgroundColorClass = this.getBackgroundColorClass(4);
				} else if (shift.is_cloned_shift) {
					if (shift.task_color_id != null) {
						backgroundColorClass = this.getBackgroundColorClass(shift.task_color_id);
					} else {
						backgroundColorClass = this.getBackgroundColorClass(shift.task_type_color_id);
					}
				} else {
					backgroundColorClass = this.getBackgroundColorClass(shift.candidate_type_color_id);
				}

				if (shift.is_optional) {
					backgroundColorClass += "Optional";
				}
			}
		} else {
			if (shift.periodic_candidate_id != null) {

				if (shift.task_variant_color_id != null) {
					backgroundColorClass = this.getBackgroundColorClass(shift.task_variant_color_id);
				} else {
					backgroundColorClass = this.getBackgroundColorClass(shift.task_type_color_id);
				}

				if (shift.is_optional) {
					backgroundColorClass += "Optional";
				}
			}
		}

		if (isShort) {
			classes.push("shortShift");
		}

		if (shift.periodic_candidate_id == null) {
			classes.push("openShift");
		} else {
			classes.push("borderedShift");
		}

		if (shift.periodic_candidate_id == null) {
			if (shift.is_cancelled) {
				tooltipTitle = "Geannuleerd";
			} else {
				tooltipTitle = "Niet toegewezen";

				if (shift.original_periodic_candidate_id != null) {
					tooltipTitle += " (oorspronkelijk: " + shift.original_candidate_name + ")";
				}
			}
		} else {
			if (shift.is_cloned_shift) {
				tooltipTitle = shift.task_name;
			} else if (shift.task_periodic_candidate_id == null) {
				tooltipTitle = shift.candidate_name + (shift.candidate_phone_number ? " (" + shift.candidate_phone_number + ")" : "");
			} else {
				tooltipTitle = shift.task_variant_name;
			}

			if (shift.is_optional) {
				tooltipTitle += " [Optioneel]";
			}
		}

		if (shift.is_cancelled) {
			classes.push("cancelledShift");
		}

		if ((shift.violations == null || shift.violations.length == 0) && !shift.is_cancelled && shift.periodic_candidate_id != null) {
			classes.push(backgroundColorClass);
		}

		if (shift.violation_section_items != null) {

			var sectionItemClass;

			sectionItems = shift.violation_section_items.map(function(x) {
				if (x.is_violation) {
					if (x.violation_type_id == 2) {
						if (shift.is_optional) {
							sectionItemClass = "warningViolationShiftOptional";
						} else {
							if (task.task_type.customer_id != 25) {
								sectionItemClass = "warningViolationShift";
							}
						}
					} else {
						if (shift.is_optional) {
							sectionItemClass = "violationShiftOptional";
						} else {
							sectionItemClass = "violationShift";
						}
					}
				} else {
					sectionItemClass = backgroundColorClass;
				}

				return {
					from: moment(x.start_date),
					to: moment(x.end_date),
					classes: sectionItemClass
				}
			});

			// Does this work??? TODO doesn't work
			if (shift.violations != null && shift.task_is_filled_completely_by_violations) {
				classes.push(sectionItemClass + "ForHover");
			} else {
				classes.push(backgroundColorClass + "ForHover");
			}

			// for scrolling performance we would like to have as few tasks displayed as sections as possible
			if (sectionItems.length == 1) {
				classes.push(sectionItems[0].classes);
			}
		} else {
			if (shift.periodic_candidate_id == null && !shift.is_cancelled && shift.task_is_open_shift_highlighted) {
				classes.push("warningViolationShift");
			}
		}

		if (!isAllocationRequest && shift.comment_parts.length > 0) {
			classes.push("triangle-up-right");

			if (sectionItems.length > 0 && sectionItems[sectionItems.length-1].classes != backgroundColorClass) {
				classes.push("triangle-up-right-" + backgroundColorClass);
			} else if (shift.periodic_candidate_id == null && !shift.is_cancelled && shift.task_is_open_shift_highlighted) {
				classes.push("triangle-up-right-" + this.getBackgroundColorClass(1));
			} else {
				classes.push("triangle-up-right-normal");
			}
		}

		var className = "shift-" + shift.task_id + "-" + moment(startDate).format('YYYYMMDD') + "-" + moment(endDate).format('YYYYMMDD');

		classes.push(className);

		if (shift.violations != null && shift.violations.length > 0) {
			var violations = {};

			[
				[1, "conflict"],
				[2, "gelijktijdig"],
				[3, "overschrijding maximaal aantal uren per dag"]
			].forEach(function(violation_type_id_and_violation_description) {
				var violation_type_id = violation_type_id_and_violation_description[0];
				var violation_description = violation_type_id_and_violation_description[1];
				var violationsForViolationTypeId = shift.violations.filter(function(x) { return x.violation_type_id == violation_type_id; });
				if (violationsForViolationTypeId.length > 0) {
					violations[violation_type_id] = violation_description + ": " + violationsForViolationTypeId.map(function(x) { return x.description; }).join(", ");
				}
			});
		}

		shift.taskAccumulationPeriodIds = task.task_accumulation_memberships.map(function(x) { return x.task_accumulation_period_id; });

		var dateLabel = startDate.format('D MMM') +
				(moment(endDate).subtract(1, 'day').isSame(startDate) ? "" : " t/m " + moment(endDate).subtract(1, 'day').format('D MMM'));

		var ganttTask = {
			ganttTaskType: null,
			name: contentInBlock,
			tooltipTitle: tooltipTitle,
			color: "transparent",
			classes: classes.join(" "),
			from: scheduleFromDate && startDate.isBefore(scheduleFromDate) && endDate.isAfter(scheduleFromDate) ? scheduleFromDate : startDate,
			to: scheduleToDate && endDate.isAfter(moment(scheduleToDate).add(1, 'day')) && startDate.isBefore(moment(scheduleToDate).add(1, 'day')) ? moment(scheduleToDate).add(1, 'day') : endDate,
			violations: violations,
			dateLabel: dateLabel,
			task: task,
			shift: shift,
			openAllocationRequestCount: shift.open_allocation_request_part_count,
			sections: sectionItems.length <= 1 ? null : { items: sectionItems },
			tooltipValues: "{'ganttTaskType':null,'name':'" + contentInBlock + "','dateLabel':'" + dateLabel + "'}"
		};
		
		this.addAsJson(ganttTask);
		
		return ganttTask;
	}

	createAssignScheduleTaskFromShift(shift, periodicCandidate, localHorizonStartDate, localHorizonEndDate) {
		var classes = [];
		var taskName;
		var taskAlias;
		var taskLengthInDays = moment(shift.end_date).diff(moment(shift.start_date), 'days');
		var horizonLengthInDays = moment(localHorizonEndDate).diff(moment(localHorizonStartDate), 'days');

		if (shift.task == null) {
			// this is an allocation_request_part-shift
			if (taskLengthInDays > 1 || horizonLengthInDays <= 3) {
				taskName = "Afwezig (verzoek)";
			} else if (horizonLengthInDays <= 5) {
				taskName = "Afwezig (verz)";
			} else {
				taskName = "Afw (verz)";
			}
			taskAlias = taskName;
			classes = classes.concat(["coloredShift4"]);
			classes = classes.concat(["assignModalRegularTask"]);
		} else {
			if (shift.task_variant == null) {
				taskName = shift.task.name;
				taskAlias = shift.task.alias;
			} else {
				taskName = shift.task_variant.name;
				taskAlias = shift.task_variant.alias;
			}

			if (shift.show_as_template_in_assign_modal) {
				classes = classes.concat(["openShiftAssign"]);
			} else {
				classes = classes.concat(["assignModalRegularTask"]);

				var backgroundColorClass;

				if (shift.task.periodic_candidate_id == null) {
					backgroundColorClass = this.getBackgroundColorClass(shift.task.task_type.color_id);
				} else {
					if (shift.task_variant != null) {
						backgroundColorClass = this.getBackgroundColorClass(shift.task_variant.color_id);
					} else {
						backgroundColorClass = this.getBackgroundColorClass(shift.task_type.color_id);
					}
				}

				if (shift.is_optional) {
					classes.push(backgroundColorClass + "Optional");
				} else {
					classes.push(backgroundColorClass);
				}
			}
		}

		return {
			name: taskName,
			alias: taskAlias,
			classes: classes.join(" "),
			from: moment(shift.start_date),
			to: moment(shift.end_date),
			task: shift.task,
			dateLabel: moment(shift.start_date).format('D MMM') +
				(moment(shift.end_date).subtract(1, 'day').isSame(shift.start_date) ? "" : " t/m " + moment(shift.end_date).subtract(1, 'day').format('D MMM')),
			periodicCandidate: periodicCandidate,
			offsetDays: moment(shift.start_date).diff(moment(localHorizonStartDate), 'days'),
			lengthInDays: taskLengthInDays,
			shift: shift
		};
	}

	createTaskFromSpecialEvent(x) {
		var startDate = moment(x.start_date);
		var endDate = moment(x.end_date);

		var classes = ["shortShift", "coloredSpecialEvent"];

		if (x.is_public_holiday) {
			classes.push("coloredSpecialEventPublicHoliday");
		}

		var ganttTask = {
			name: x.name,
			from: startDate,
			to: endDate,
			dateLabel: startDate.format('D MMM') +
				(moment(endDate).subtract(1, 'day').isSame(startDate) ? "" : " t/m " + moment(endDate).subtract(1, 'day').format('D MMM')),
			classes: classes.join(" "),
			color: "transparent",
			isSpecialEvent: true,
			isPublicHoliday: x.is_public_holiday
		};
		
		this.addAsJson(ganttTask);
		
		return ganttTask;
	}

	getBackgroundColorClass(colorId) {
		return "coloredShift" + colorId;
	}

	getBackgroundColorClassRegional(colorId) {
		return "coloredShiftRegional" + colorId;
	}

	generateId() {
		return Math.round(Math.random() * 100000000000);
	}

	addAsJson(ganttTask) {
		ganttTask.asJson = JSON.stringify(ganttTask);
	}

	capitalize(word) {
		if (!word) return word;
		return word[0].toUpperCase() + word.substr(1).toLowerCase();
	}
}
