import { Component, OnInit } from '@angular/core';
import templateString from './taskTypes.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TaskTypeFormComponent } from '../taskTypeForm';
import { TaskTypeChangeOrderComponent } from '../taskTypeChangeOrder';
import { DataService } from 'site/app/data.service';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class TaskTypesComponent implements OnInit {
    
    public taskTypes: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private dataService: DataService,
        private environmentService: EnvironmentService
    ) { }

    refresh() {
        this.http.get("task_types").subscribe(x => {
            this.taskTypes = x;
        });
    }

    create() {
        var bsModalRef = this.bsModalService.show(TaskTypeFormComponent, {
            initialState: {
                taskTypeId: null
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(taskTypeId) {
        var bsModalRef = this.bsModalService.show(TaskTypeFormComponent, {
            initialState: {
                taskTypeId: taskTypeId
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(taskTypeId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("task_types/" + taskTypeId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(TaskTypeChangeOrderComponent, {
            initialState: {
                originalTaskTypes: this.taskTypes
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    getColor(taskType) {
        return this.dataService.colors[taskType.color_id];
    }

    initialize() {
		this.refresh();
	}
	
    ngOnInit() {
        this.initialize();
    }
}
