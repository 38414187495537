import { Component, OnInit } from '@angular/core';
import templateString from './shiftAccumulationTypes.component.html';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { EnvironmentService } from 'site/app/environment.service';
import { takeUntil } from 'rxjs/operators';
import { ShiftAccumulationTypeChangeOrderComponent } from '../shiftAccumulationTypeChangeOrder';
import { ShiftAccumulationTypeFormComponent } from '../shiftAccumulationTypeForm';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class ShiftAccumulationTypesComponent implements OnInit {
    
    public shiftAccumulationTypes: any;
    public selectedSchedulePeriod: any;
    public destroyed$: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private bsModalService: BsModalService,
        private environmentService: EnvironmentService
	) { 
        this.destroyed$ = new Subject<boolean>();
    }

    refresh() {
        this.http.get("shift_accumulation_types", { params: { schedule_period_id: this.selectedSchedulePeriod.id } }).subscribe(x => {
            this.shiftAccumulationTypes = x;

            this.shiftAccumulationTypes.forEach(function(shiftAccumulationType) {
                shiftAccumulationType.taskMemberships = shiftAccumulationType.shift_accumulation_type_memberships.filter(function(satm) { return satm.task_id; });
                shiftAccumulationType.taskVariantMemberships = shiftAccumulationType.shift_accumulation_type_memberships.filter(function(satm) { return satm.task_variant_id; });
                shiftAccumulationType.taskTypeMemberships = shiftAccumulationType.shift_accumulation_type_memberships.filter(function(satm) { return satm.task_type_id; });
            });
        });
    }
    
    changeSchedulePeriod() {
        this.refresh();
    }

    create() {
        var bsModalRef = this.bsModalService.show(ShiftAccumulationTypeFormComponent, {
            initialState: {
                shiftAccumulationTypeId: null,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    edit(shiftAccumulationTypeId) {
        var bsModalRef = this.bsModalService.show(ShiftAccumulationTypeFormComponent, {
            initialState: {
                shiftAccumulationTypeId: shiftAccumulationTypeId,
                schedulePeriodId: this.selectedSchedulePeriod.id
            },
            class: 'modal-lg'
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    delete(shiftAccumulationTypeId) {
        if (window.confirm('Weet je het zeker?')) {
            this.http.delete("shift_accumulation_types/" + shiftAccumulationTypeId).subscribe(() => {
				this.refresh();
			}, (response) => {
				this.toastr.error(response.data.error);
			});
        }
    };
    
    changeOrder() {
        var bsModalRef = this.bsModalService.show(ShiftAccumulationTypeChangeOrderComponent, {
            initialState: {
                originalShiftAccumulationTypes: this.shiftAccumulationTypes
            }
        });

        bsModalRef.content.action.subscribe(result => {	
			this.refresh();
        });
    }

    initialize() {
		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.refresh();
	}
	
    ngOnInit() {
        this.environmentService.selectedSchedulePeriodChanged$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
                this.initialize();
            });

        this.initialize();
    }

    ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
    }
}
