import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './allocationRequestTypeForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AllocationRequestType } from 'site/app/models/allocationRequestType.model';
import { EnvironmentService } from 'site/app/environment.service';
import { HttpClient } from '@angular/common/http';
import { AllocationRequestTypeExceptedTaskType } from 'site/app/models/allocationRequestTypeExceptedTaskType.model';

@Component({ template: templateString })
export class AllocationRequestTypeFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public allocationRequestTypeId: any;
	public schedulePeriodId: any;

	public allocationRequestType: any;
	public submitted: boolean;	
	public actionTitle: string;
	public tasks: any;
	public taskTypes: any;
	public isLoading: boolean;
	public allocationRequestTypeExceptedTaskTypeIndex;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService,
		private environmentService: EnvironmentService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteAllocationRequestTypeExceptedTaskType(index) {
		var matchIndex = -1;
		this.allocationRequestType.allocation_request_type_excepted_task_types.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.allocationRequestType.allocation_request_type_excepted_task_types.splice(matchIndex, 1);
	}
	
	addAllocationRequestTypeExceptedTaskType() {
		this.allocationRequestType.allocation_request_type_excepted_task_types.push(new AllocationRequestTypeExceptedTaskType({ index: this.allocationRequestTypeExceptedTaskTypeIndex++ }));
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.allocationRequestTypeId === null) {
				this.allocationRequestType.schedule_period_id = this.schedulePeriodId

				this.http.post("allocation_request_types", this.allocationRequestType).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			} else {
				this.http.put("allocation_request_types/" + this.allocationRequestType.id, this.allocationRequestType).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}

    ngOnInit() {
		if (this.allocationRequestTypeId === null) {
			this.actionTitle = "toevoegen";
			this.allocationRequestType = new AllocationRequestType({ 
				is_allocate_preference: false, 
				is_period_containing_tasks: false,
				allocation_request_type_excepted_task_types: [],
			});
		} else {
			this.actionTitle = "wijzigen";
			this.http.get<any>("allocation_request_types/" + this.allocationRequestTypeId).subscribe(x => {
				this.allocationRequestType = x;

				x.allocation_request_type_excepted_task_types.forEach(function(allocationRequestTypeExceptedTaskType, index) {
					allocationRequestTypeExceptedTaskType.index = index;
				});
				this.allocationRequestTypeExceptedTaskTypeIndex = x.allocation_request_type_excepted_task_types.length;
			});
		}
		
		this.http.get("tasks",
			{
				params: {
					schedule_period_id: this.schedulePeriodId,
					//is_unsplittable: 'true'
				}
			}
		).subscribe(tasks => {
			this.tasks = tasks;
		});

		this.http.get("task_types").subscribe(taskTypes => {
			this.taskTypes = taskTypes;
		});
    }
}
