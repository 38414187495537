import { Component, OnInit, OnDestroy } from "@angular/core";
import templateString from './agenda.component.html'
import * as moment from 'moment';
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { Candidate } from "site/app/models/candidate.model";
import { EnvironmentService } from "site/app/environment.service";
import { Router } from "@angular/router";
import { forkJoin } from "rxjs";
import { SelectRangeForPrintComponent } from "../selectRangeForPrint";
import { AgendaSyncLinkComponent } from "../agendaSyncLink";
import { HttpClient } from "@angular/common/http";

@Component({ 
	template: templateString
})
export class AgendaComponent implements OnInit {
	public user;
	public today;
	public isLoading;
	public schedulePeriods;
	public candidates;
	public customAgendas;
	public agendas;
	public selection = {};
	public goToPreviousWeeksButtonDisabled;
	public fromDate;
	public toDate;
	public customAgenda;
	public agendaIdentificationHash;
	public agendaPerWeek;
	public firstWeekNumber;
	public firstWeekYear;
	public weekYearAddendum;
	public lastWeekNumber;
	public candidate;
	public isCustomAgenda;
	
	constructor(
		private environmentService: EnvironmentService,
		private router: Router,
		private http: HttpClient,
		private bsModalService: BsModalService
	) { 
	}

	goToPreviousWeeks() {
		if (this.goToPreviousWeeksButtonDisabled) {
			return;
		}
		
		this.fromDate = moment(this.fromDate).subtract(4, 'weeks');
		this.refreshAgenda();
	}

	goToNextWeeks() {
		this.fromDate = moment(this.fromDate).add(4, 'weeks');
		this.refreshAgenda();
	}
	
	setStartDate() {
		this.fromDate = moment().startOf('isoWeek');
	}
	
	changeAgenda = function() {
		this.isLoading = true;
		
		if (this.selection.agendaId == null || this.selection.agendaId == 0) {
			this.isCustomAgenda = false;
			this.candidate = null;
		} else {
			this.isCustomAgenda = this.selection.agendaId.substring(0, 6) == "custom";
			
			if (this.isCustomAgenda) {
				// agendaId starts with "custom"
				var customAgendaId = parseInt(this.selection.agendaId.substring(6));
				this.customAgenda = this.customAgendas.filter(function(x) { return x.id == customAgendaId })[0];
			} else {
				// agendaId starts with "candidate"
				var candidateId = parseInt(this.selection.agendaId.substring(9));
				this.candidate = this.candidates.filter(function(x) { return x.id == candidateId })[0];
				if (this.candidate) {
					this.candidate.computedInfixWithLastname = (new Candidate(this.candidate)).infixWithLastname();
				}				
			}
		}
		
		this.setStartDate();
		this.refreshAgenda();
	}
	
	refreshAgenda() {
		this.toDate = moment(this.fromDate).add(4, 'weeks');
		
		this.goToPreviousWeeksButtonDisabled = this.fromDate <= moment([2018, 6, 1]).startOf('isoWeek');
		
		// TODO: DRY up
		if (this.isCustomAgenda) {
			forkJoin([
				this.http.get("shifts/for_agenda", 
					{ 
						params: {						
							custom_agenda_id: this.customAgenda.id, 
							start_date: this.fromDate, 
							end_date: this.toDate 
						}
					}
				),
				this.http.get("special_events"),
				this.http.get("agenda/get_identification_hash", { params: { custom_agenda_id: this.customAgenda.id } }),
				this.http.get("allocation_request_parts/for_agenda", 
					{ 
						params: {
							custom_agenda_id: this.customAgenda.id, 
							start_date: this.fromDate, 
							end_date: this.toDate
						}
					})
			]).subscribe(data => {
				var shifts = data[0];
				var specialEvents = data[1];
				this.agendaIdentificationHash = data[2]['agenda_identification_hash'];
				var allocationRequestParts = data[3];

				var currentDate = moment(this.fromDate);
				this.agendaPerWeek = [];
				var currentWeekNumber = null;
				this.firstWeekNumber = null;

				while (currentDate < this.toDate) {
					var weekNumber = currentDate.isoWeek();
					if (this.firstWeekNumber == null) {
						this.firstWeekNumber = weekNumber;
						this.firstWeekYear = currentDate.year();
						var lastWeekYear = moment(currentDate).add(27, 'days').year()
						this.weekYearAddendum = this.firstWeekYear == lastWeekYear ? "" : "/" + lastWeekYear;
					}
		
					if (currentWeekNumber == null || currentWeekNumber != weekNumber) {
						this.agendaPerWeek.push({ weekNumber: weekNumber, dayItems: [] });
						currentWeekNumber = weekNumber;
					}

					var shiftsForCurrentDate = (<any> shifts).filter(function(x) { return moment(x.start_date) <= currentDate && moment(x.end_date) > currentDate });

					shiftsForCurrentDate.forEach(function(shift) {
						if (shift.periodic_candidate != null && shift.periodic_candidate != undefined) {
							shift.periodic_candidate.infixWithLastname = (new Candidate(shift.periodic_candidate.candidate)).infixWithLastname();
						}

						var shiftDays = shift.shift_days.filter(function(x) { return x.date == currentDate.format("YYYY-MM-DD") });
						
						// shiftDay for date might not exist if the conditions "(shift_days.comment IS NOT NULL AND shift_days.comment != '')" at
						// fetching the shifts at shifts_controller.rb is only true for other shift_days (and periodic candidate = null and shift.comment is empty)
						if (shiftDays.length > 0) {
							shift.formattedStartEndTime = "";
						
							var shiftDay = shift.shift_days.filter(function(x) { return x.date == currentDate.format("YYYY-MM-DD") })[0];
						
							if (!shiftDay.is_all_day) {
								shift.startTime = shiftDay.start_time;
								shift.formattedStartEndTime = moment(shiftDay.start_time).format("HH:mm") + "-" + moment(shiftDay.end_time).format("HH:mm") + " ";
							}

							if (shift.shiftDayComments == undefined) {
								shift.shiftDayComments = {};
							}

							shift.shiftDayComments[currentDate.format("YYYY-MM-DD")] = shiftDay.comment;
						}
					});

					var specialEventsForCurrentDate = (<any> specialEvents).filter(function(x) { return moment(x.start_date) <= currentDate && moment(x.end_date) > currentDate });
					var allocationRequestPartsForCurrentDate = (<any> allocationRequestParts).filter(function(x) { return moment(x.start_date) <= currentDate && moment(x.end_date) > currentDate });

					allocationRequestPartsForCurrentDate.forEach(function(allocationRequestPart) {
						if (allocationRequestPart.allocation_request.periodic_candidate != null && allocationRequestPart.allocation_request.periodic_candidate != undefined) {
							allocationRequestPart.allocation_request.periodic_candidate.infixWithLastname = (new Candidate(allocationRequestPart.allocation_request.periodic_candidate.candidate)).infixWithLastname();
						}
					});

					this.agendaPerWeek[this.agendaPerWeek.length-1].dayItems.push(
						{ 
							date: currentDate,
							shifts: shiftsForCurrentDate,
							allocationRequestParts: allocationRequestPartsForCurrentDate,
							specialEvents: specialEventsForCurrentDate,
							isPublicHoliday: specialEventsForCurrentDate.filter(function(x) { return x.is_public_holiday }).length > 0
						}
					);
				
					currentDate = moment(currentDate).add(1, 'day');
				}
			
				this.lastWeekNumber = currentWeekNumber;

			 	this.isLoading = false;
			});
			
		} else {
			
			if (this.candidate == null) {
				this.isLoading = false;
			} else {
				forkJoin([
					this.http.get("shifts/for_agenda", 
						{
							params: {
								candidate_id: this.candidate.id, 
								start_date: this.fromDate, 
								end_date: this.toDate
							}
						}),
					this.http.get("special_events"),
					this.http.get("agenda/get_identification_hash", { params: { candidate_id: this.candidate.id } }),
					this.http.get("allocation_request_parts/for_agenda", 
						{
							params: {
								candidate_id: this.candidate.id, 
								start_date: this.fromDate, 
								end_date: this.toDate		
							}
						}
					)
				]).subscribe(data => {
					var shifts = data[0];
					var specialEvents = data[1];
					this.agendaIdentificationHash = data[2]['agenda_identification_hash'];
					var allocationRequestParts = data[3];

					var currentDate = moment(this.fromDate);
					this.agendaPerWeek = [];
					var currentWeekNumber = null;
					this.firstWeekNumber = null;

					while (currentDate < this.toDate) {
						var weekNumber = currentDate.isoWeek();
						if (this.firstWeekNumber == null) {
							this.firstWeekNumber = weekNumber;
							this.firstWeekYear = currentDate.year();
							var lastWeekYear = moment(currentDate).add(27, 'days').year()
							this.weekYearAddendum = this.firstWeekYear == lastWeekYear ? "" : "/" + lastWeekYear;
						}
			
						if (currentWeekNumber == null || currentWeekNumber != weekNumber) {
							this.agendaPerWeek.push({ weekNumber: weekNumber, dayItems: [] });
							currentWeekNumber = weekNumber;
						}

						var shiftsForCurrentDate = (<any> shifts).filter(function(x) { return moment(x.start_date) <= currentDate && moment(x.end_date) > currentDate });

						shiftsForCurrentDate.forEach(function(shift) {
							shift.formattedStartEndTime = "";
							
							var shiftDay = shift.shift_days.filter(function(x) { return x.date == currentDate.format("YYYY-MM-DD") })[0];
							
							if (!shiftDay.is_all_day) {
								shift.startTime = shiftDay.start_time;
								shift.formattedStartEndTime = moment(shiftDay.start_time).format("HH:mm") + "-" + moment(shiftDay.end_time).format("HH:mm") + " ";
							}

							if (shift.shiftDayComments == undefined) {
								shift.shiftDayComments = {};
							}

							shift.shiftDayComments[currentDate.format("YYYY-MM-DD")] = shiftDay.comment;
						});
						
						var allocationRequestPartsForCurrentDate = (<any> allocationRequestParts).filter(function(x) { return moment(x.start_date) <= currentDate && moment(x.end_date) > currentDate });

						var specialEventsForCurrentDate = (<any> specialEvents).filter(function(x) { return moment(x.start_date) <= currentDate && moment(x.end_date) > currentDate });

						this.agendaPerWeek[this.agendaPerWeek.length-1].dayItems.push(
							{ 
								date: currentDate,
								// if shifts for current date contains both in schedule and not in schedule, remove the ones not in schedule
								shifts: shiftsForCurrentDate.sort(function(a, b){ return (<any>moment(a.startTime)) - (<any> moment(b.startTime)) }),
								allocationRequestParts: allocationRequestPartsForCurrentDate,
								specialEvents: specialEventsForCurrentDate,
								isPublicHoliday: specialEventsForCurrentDate.filter(function(x) { return x.is_public_holiday }).length > 0
							}
						);
					
						currentDate = moment(currentDate).add(1, 'day');
					}

					this.lastWeekNumber = currentWeekNumber;

				 	this.isLoading = false;
				});
			}
		}
	}
	
	getLabelClass(colorId) {
		return "labelShift" + colorId;
	}
	
	getLabelForegroundClass(colorId) {
		return "labelShiftForeground" + colorId;
	}
	
  	selectRangeForPrint(withPrint) {
		var bsModalRef = this.bsModalService.show(SelectRangeForPrintComponent, {
			initialState: {
				startDate: this.fromDate,
				endDate: this.toDate
			},
			class: 'modal-lg'
		});

		bsModalRef.content.action.subscribe(result => {	
			var startDate = moment(result['startDate']);
			var endDate = moment(result['endDate']);
			
			this.export(startDate, endDate, withPrint);
		});
  	}
	
	export(startDate, endDate, withPrint) {
		forkJoin(
			this.isCustomAgenda ? 
			[
				this.http.get("shifts/for_agenda",
					{ 
						params: {
							custom_agenda_id: this.customAgenda.id, 
							start_date: startDate, 
							end_date: endDate 	
						}
					}
				),
				this.http.get("special_events"),
				this.http.get("allocation_request_parts/for_agenda", 
					{
						params: {
							custom_agenda_id: this.customAgenda.id, 
							start_date: this.fromDate, 
							end_date: this.toDate		
						}
					})
			] :
			[
				this.http.get("shifts/for_agenda", 
					{
						params: {
							candidate_id: this.candidate.id, 
							start_date: this.fromDate, 
							end_date: this.toDate		
						}
					}),
				this.http.get("special_events"),
				this.http.get("allocation_request_parts/for_agenda", 
					{
						params: {
							candidate_id: this.candidate.id, 
							start_date: this.fromDate, 
							end_date: this.toDate		
						}
					})
			]
		).subscribe(data => {
			var shifts = data[0];
			var specialEvents = data[1];
			var allocationRequestParts = data[2];

			var currentDate = moment(startDate);
			var shiftsPerDay = [];

			while (currentDate < endDate) {

				var shiftsForCurrentDate = (<any> shifts).filter(function(x) { return moment(x.start_date) <= currentDate && moment(x.end_date) > currentDate });
				
				shiftsForCurrentDate.forEach(function(shift) {
					var shiftDays = shift.shift_days.filter(function(x) { return x.date == currentDate.format("YYYY-MM-DD") });
				
					// shiftDay for date might not exist if the conditions "(shift_days.comment IS NOT NULL AND shift_days.comment != '')" at
					// fetching the shifts at shifts_controller.rb is only true for other shift_days (and periodic candidate = null and shift.comment is empty)
					if (shiftDays.length > 0) {
						var shiftDay = shift.shift_days.filter(function(x) { return x.date == currentDate.format("YYYY-MM-DD") })[0];
				
						if (shift.shiftDayComments == undefined) {
							shift.shiftDayComments = {};
						}

						shift.shiftDayComments[currentDate.format("YYYY-MM-DD")] = shiftDay.comment;
					}
				});

				var specialEventsForCurrentDate = (<any> specialEvents).filter(function(x) { return moment(x.start_date) <= currentDate && moment(x.end_date) > currentDate });
				var allocationRequestPartsForCurrentDate = (<any> allocationRequestParts).filter(function(x) { return moment(x.start_date) <= currentDate && moment(x.end_date) > currentDate });

				shiftsPerDay.push(
					{ 
						date: currentDate,
						shifts: shiftsForCurrentDate,
						allocationRequestParts: allocationRequestPartsForCurrentDate,
						specialEvents: specialEventsForCurrentDate,
						isPublicHoliday: specialEventsForCurrentDate.filter(function(x) { return x.is_public_holiday }).length > 0
					}
				);
		
				currentDate = moment(currentDate).add(1, 'day');
			}

			var printContents = '' +
'				<html>' +
'					<head>' +
'						<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css">' +
'						' +
'						<style>' +
'							body {' +
'						    font-family: "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;' +
'						    background-color: #ffffff;' +
'								font-size: 13px;' +
'							}' +
'							table {' +
'								margin: 0;' +
'								border-spacing: 0;' +
'								border-collapse: collapse;' +
'							}' +
'							th, td {' +
'								font-size: 13px;' +
'								margin: 0;' +
'								border-spacing: 0;' +
'								padding: 3px;' +
'							}' +
'							td {' +
'								border: 1px solid #eee;' +
'							}' +
'							td.with-nested-table {' +
'								border-top: 0;' +
'								border-right: 0;' +
'								border-bottom: 0;' +
'							}' +
'							td.first-with-nested-table {' +
'								border-top: 1px solid #eee;' +
'							}' +
'							.nested-table td {' +
'								border-top: 0;' +
'								border-left: 0;' +
'							}						' +
'						</style>' +
'					</head>' +
'					<body>' +
'						<table>' +
'							<thead>' +
'								<tr>' +
'									<th valign="top" style="text-align: left;">' +
'										<div style="font-weight: normal; background-color: transparent; font-size: 20px; margin-bottom: 15px; padding-left: 0px;">' +
'											<i class="fa fa-calendar-check-o features-icon" style="color: inherit; font-size: 20px;"></i> ' +
'											easyplanner' +
'										</span>' +
'									</th>' +
'									<th valign="top" style="font-weight: normal; text-align: left; font-size: 16px;">' +
'										Agenda ' + (this.isCustomAgenda ? '"' + this.customAgenda.name + '"' : "van " + (new Candidate(this.candidate)).infixWithLastname()) +
										' (' + startDate.format('D MMM YYYY') + ' t/m ' + (endDate).subtract(1, 'day').format('D MMM YYYY') + ')' +
'									</th>' +
'								</tr>' +
'								<tr>' +
'									<th style="text-align: left; width: 150px;">Datum</th>' +
'									<th class="with-nested-table" style="padding: 0;">' +
'										<table class="nested-table">' +
'											<tr>' +
'												<th style="text-align: left; width: 200px;">Activiteit</th>' +
'												<th style="text-align: left; width: 150px;">' + this.environmentService.translateForIndustry('Candidate') + '</th>' +
'												<th style="text-align: left; width: 200px;">Opmerkingen</th>' +
'											</tr>' +
'										</table>' +
'									</th>' +
'								</tr>' +
'							</thead>' +
'							<tbody>';
			
			shiftsPerDay.forEach(function(shiftsForDay, index) {
				printContents += '' +
'								<tr>' +
'									<td valign="top">' + shiftsForDay.date.format('dd D MMM YYYY') + '</td>' +
'									<td class="with-nested-table' + (index == 0 ? " first-with-nested-table" : "") + '" style="padding: 0;">' +
'										<table class="nested-table">';
				
				if (shiftsForDay.shifts.length > 0 || shiftsForDay.allocationRequestParts.length > 0) {
					shiftsForDay.shifts.forEach(function(shift) {
						printContents += '' +
'											<tr>' +
'												<td style="text-align: left; width: 200px;">' + (shift.task.periodic_candidate_id == null ? shift.task.name : shift.task_variant.name + ' (' + shift.task.name + ')') + '</td>' +
'												<td style="text-align: left; width: 150px;">' + (shift.periodic_candidate == null ? "" : (new Candidate(shift.periodic_candidate.candidate)).infixWithLastname()) + '</td>' +
'												<td style="text-align: left; width: 200px;">' + 
													(shift.comment == null ? "" : shift.comment) + 
													(shift.comment != null && shift.shiftDayComments[shiftsForDay.date.format('YYYY-MM-DD')] != null ? " | " : "") + 
													(shift.shiftDayComments[shiftsForDay.date.format('YYYY-MM-DD')] == null ? "" : shift.shiftDayComments[shiftsForDay.date.format('YYYY-MM-DD')]) +
'												</td>' +
'											</tr>';
				   });

				   shiftsForDay.allocationRequestParts.forEach(function(allocationRequestPart) {
						printContents += '' +
'											<tr>' +
'												<td style="text-align: left; width: 200px;">' + allocationRequestPart.allocation_request.allocation_request_type.name + (allocationRequestPart.time_slot ? ' (' + allocationRequestPart.time_slot.name + ')' : '') + '</td>' +
'												<td style="text-align: left; width: 150px;">' + (allocationRequestPart.allocation_request.periodic_candidate == null ? "" : (new Candidate(allocationRequestPart.allocation_request.periodic_candidate.candidate)).infixWithLastname()) + '</td>' +
'												<td style="text-align: left; width: 200px;">' + 
													(allocationRequestPart.allocation_request.reason == null ? "" : allocationRequestPart.allocation_request.reason) + 
'												</td>' +
'											</tr>';
			   		});

				} else {
					printContents += '' +
'											<tr>' +
'												<td style="text-align: left; width: 200px;">&nbsp;</td>' +
'												<td style="text-align: left; width: 150px;">&nbsp;</td>' +
'												<td style="text-align: left; width: 200px;">&nbsp;</td>' +
'											</tr>';
				}
				
				printContents += '' +
'										</table>' +
'									</td>' +
'								</tr>';
			});

			printContents += '' +
'							</tbody>' +
'						</table>' +
'					</body>' +
'				</html>';

			if (withPrint) {
				var printWindow=window.open('','','width=800,height=600,top=0,left=0,scrollbars=1,resizable=1');
				
				// printWindow is null when popup is blocked by browser's popup blocker
				if (printWindow != null) {
					printWindow.document.write(printContents);
			    printWindow.document.close();
					printWindow.focus();
				}
			
				// timeout otherwise cdn link font-awesome (probably loading it) causes print pages to be blank
				setTimeout(function() {
					printWindow.print();
					printWindow.close();
				}, 1000);
			} else {
				var printWindow=window.open('','','width=800,height=600,top=0,left=0,scrollbars=1,resizable=1');

				// printWindow is null when popup is blocked by browser's popup blocker
				if (printWindow != null) {
					printWindow.document.write(printContents);
			    printWindow.document.close();
					printWindow.focus();
				}
			}	
		});
	}

	openAgendaSyncLinkModal() {
		var bsModalRef = this.bsModalService.show(AgendaSyncLinkComponent, {
			initialState: {
				agendaIdentificationHash: this.agendaIdentificationHash,
				isCustomAgenda: this.isCustomAgenda,
				customAgenda: this.customAgenda,
				candidate: this.candidate
			},
			class: 'modal-lg'
		});

		bsModalRef.content.action.subscribe(result => {	
		});
	}

	ngOnInit(): void {
		var self = this;
		this.user = this.environmentService.getUser();
		if (this.user == undefined) {
			this.router.navigate(["/login"]);
		}
	
		this.today = moment().startOf('day');
	
		this.isLoading = true;

		this.http.get("schedule_periods").subscribe(schedulePeriods => {
			this.schedulePeriods = schedulePeriods;
			
			this.schedulePeriods.forEach(function(schedulePeriod) {
				if (schedulePeriod.is_default) {
					forkJoin([
						self.http.get("candidates/index_alphabetically_for_current_and_future_schedule_periods"),
						self.http.get("custom_agendas/index_alphabetically_for_current_and_future_schedule_periods")
					]).subscribe(data => {
						self.candidates = data[0];
						self.customAgendas = data[1];
						
						self.agendas = [];

						self.agendas = self.agendas.concat(self.customAgendas.map(function(x) { return { id: "custom" + x.id, name: x.name } }));
						
						if (!self.user.isDayScheduleNotAccessible) {
							if (self.agendas.length > 0) {
								self.agendas.push({ id: 0, name: "-------------------------" });
							}
							self.agendas = self.agendas.concat(self.candidates.map(function(x) { return { id: "candidate" + x.id, name: (new Candidate(x)).lastnameWithInfix() + (x.employment_termination_date == null ? "" : " (uit dienst: " + moment(x.employment_termination_date).format('DD-MM-YY') + ")") }}))
						}
		
						self.selection = { schedulePeriodId: schedulePeriod.id, agendaId: self.user.isAdmin || self.user.candidateId == null ? null : "candidate" + self.user.candidateId };
						
						self.changeAgenda();
					});
				}
			});
		});
	}	
}
