import { Component, OnInit, Renderer2, Output, EventEmitter } from '@angular/core';
import templateString from './customAgendaForm.component.html';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CustomAgenda } from 'site/app/models/customAgenda.model';
import { CustomAgendaTask } from 'site/app/models/customAgendaTask.model';
import { CustomAgendaAllocationRequestType } from 'site/app/models/customAgendaAllocationRequestType.model';
import { CustomAgendaTaskVariant } from 'site/app/models/customAgendaTaskVariant.model';
import { Task } from 'site/app/models/task.model';
import { HttpClient } from '@angular/common/http';

@Component({ template: templateString })
export class CustomAgendaFormComponent implements OnInit {
    @Output() action = new EventEmitter();
	
	public customAgendaId: any;
	public schedulePeriodId: any;

	public customAgenda: any;
	public submitted: boolean;	
	public actionTitle: string;
	public tasks: any;
	public allocationRequestTypes: any;
	public isLoading: boolean;
	public customAgendaTaskIndex: number;
	public customAgendaAllocationRequestTypeIndex: number;
	public taskVariantGroupsForTaskVariants: any;
	public taskVariantGroups: any;
	public customAgendaTaskVariantIndex: any;

    constructor(
        private http: HttpClient,
        private bsModalRef: BsModalRef,
        private renderer: Renderer2,
        private toastr: ToastrService
	) {
		this.renderer.addClass(document.body, 'md-skin');
		this.renderer.addClass(document.body, 'landing-page');
		this.renderer.addClass(document.body, 'fixed-nav');
	}

	deleteCustomAgendaTask(index) {
		var matchIndex = -1;
		this.customAgenda.custom_agenda_tasks_for_schedule_period.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		// hack because error lingers after it is deleted
		this.customAgenda.custom_agenda_tasks_for_schedule_period[matchIndex].task_id = this.tasks[0].id;
		this.customAgenda.custom_agenda_tasks_for_schedule_period[matchIndex].task_id = null;

		this.customAgenda.custom_agenda_tasks_for_schedule_period.splice(matchIndex, 1);;
	}
	
	addCustomAgendaTask() {
		this.customAgenda.custom_agenda_tasks_for_schedule_period.push(new CustomAgendaTask({ index: this.customAgendaTaskIndex++ }));
	}

	deleteCustomAgendaTaskVariant(index) {
		var matchIndex = -1;
		this.customAgenda.custom_agenda_task_variants.some(function(el, i) {
		    if (el.index == index) {
		        matchIndex = i;
		        return true;
		    }
		});
		
		this.customAgenda.custom_agenda_task_variants.splice(matchIndex, 1);
	}
	
	addCustomAgendaTaskVariant() {
		this.customAgenda.custom_agenda_task_variants.push(new CustomAgendaTaskVariant({ index: this.customAgendaTaskVariantIndex++ }));
	}

	deleteCustomAgendaAllocationRequestType(index) {
		var matchIndex = -1;
		this.customAgenda.custom_agenda_allocation_request_types_for_schedule_period.some(function(el, i) {
			if (el.index == index) {
				matchIndex = i;
				return true;
			}
		});
		
		// hack because error lingers after it is deleted
		this.customAgenda.custom_agenda_allocation_request_types_for_schedule_period[matchIndex].allocation_request_type_id = this.allocationRequestTypes[0].id;
		this.customAgenda.custom_agenda_allocation_request_types_for_schedule_period[matchIndex].allocation_request_type_id = null;

		this.customAgenda.custom_agenda_allocation_request_types_for_schedule_period.splice(matchIndex, 1);;
	}
	
	addCustomAgendaAllocationRequestType() {
		this.customAgenda.custom_agenda_allocation_request_types_for_schedule_period.push(new CustomAgendaAllocationRequestType({ index: this.customAgendaAllocationRequestTypeIndex++ }));
	}

  	ok(isValid) {
		this.submitted = true;
		
		if (isValid) {
			if (this.customAgendaId === null) {
				this.http.post("custom_agendas",
					{
						custom_agenda: this.customAgenda,
						schedule_period_id: this.schedulePeriodId
					}
				).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			} else {
				this.http.put("custom_agendas/" + this.customAgenda.id,
					{
						custom_agenda: this.customAgenda,
						schedule_period_id: this.schedulePeriodId
					}
				).subscribe(() => {
					this.action.emit(true);
					this.bsModalRef.hide();
				}, (response) => {
					this.toastr.error(response.data.error);
				});
			}
		}
  	}

  	cancel() {
		this.bsModalRef.hide();
  	}
	
    trackByFunction(index) {
		return index;
	}

    ngOnInit() {
		var self = this;

		this.http.get("task_variant_groups").subscribe(x => {
			this.taskVariantGroups = x;

			if (this.customAgendaId === null) {
				this.actionTitle = "toevoegen";
				this.customAgenda = new CustomAgenda();
				this.customAgenda.custom_agenda_tasks_for_schedule_period = [];
				this.customAgendaTaskIndex = 0;
				this.customAgenda.custom_agenda_task_variants = [];
				this.customAgendaTaskVariantIndex = 0;
				this.taskVariantGroupsForTaskVariants = [];
				this.customAgenda.custom_agenda_allocation_request_types_for_schedule_period = [];
				this.customAgendaAllocationRequestTypeIndex = 0;
			} else {
				this.actionTitle = "wijzigen";
				this.http.get<any>("custom_agendas/" + this.customAgendaId,
					{
						params: {
							schedule_period_id: this.schedulePeriodId
						}
					}
				).subscribe(x => {
					this.customAgenda = x;

					this.customAgenda.custom_agenda_tasks_for_schedule_period.forEach(function(customAgendaTask, index) {
						customAgendaTask.index = index;
					});

					this.customAgendaTaskIndex = x.custom_agenda_tasks_for_schedule_period.length;

					this.customAgenda.custom_agenda_task_variants.forEach(function(customAgendaTaskVariant, index) {
						customAgendaTaskVariant.index = index;
					});

					this.customAgendaTaskVariantIndex = x.custom_agenda_task_variants.length;

					this.taskVariantGroupsForTaskVariants = this.customAgenda.custom_agenda_task_variants.map(function(y) {
						return self.taskVariantGroups.filter(function(z) { return z.id == y.task_variant.task_variant_group_id })[0];
					});

					this.customAgenda.custom_agenda_allocation_request_types_for_schedule_period.forEach(function(customAgendaAllocationRequestType, index) {
						customAgendaAllocationRequestType.index = index;
					});

					this.customAgendaAllocationRequestTypeIndex = x.custom_agenda_allocation_request_types_for_schedule_period.length;
				});
			}
		});

		this.http.get<any>("tasks", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(tasks => {
			this.tasks = tasks;
	
			tasks.forEach(function(task) {
				task.computedNameWithEquivalencePosition = (new Task(task)).getComputedName() + (task.equivalence_position == null ? "" : " (" + task.equivalence_position + ")");
			});
	
			this.isLoading = false;
		});

		this.http.get("allocation_request_types", { params: { schedule_period_id: this.schedulePeriodId } }).subscribe(allocationRequestTypes => {
			this.allocationRequestTypes = allocationRequestTypes;	
		});
    }
}
